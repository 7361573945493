import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectDayOffCreateRoutes } from "./views/create/ProjectDayOffCreateRoutes";
import { ProjectCalendarCardDetailsRoutes } from "views/calendar/views/details/ProjectCalendarCardDetailsRoutes";
import ProjectDayOffMissingContainer from "./container/ProjectDayOffMissingContainer";

export const ProjectDayOffMissingRoutes: Array<AppRouteProps> = [
    {
        path: "missing",
        title: "Harea.Project.DayOff.Management.Vacation.Abscence.Label",
        element: <ProjectDayOffMissingContainer />,
        children: [
            ...ProjectCalendarCardDetailsRoutes,
            ...ProjectDayOffCreateRoutes
        ]
    },
];
