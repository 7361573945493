import {
    Avatar,
    Box,
    GenericSuspense,
    MainLogo,
    Menu,
    RightSidebar,
    useFileUrl,
    useTranslationRoute,
} from "@mzara/component";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useCuriculumVitaeDetailsQuery } from "../../../hooks/useCuriculumVitaeDetailsQuery";
import { useCurriculumVitaeViewerMenuItems } from "../hooks/useCurriculumVitaeViewerMenuItems";

const CurriculumVitaeViewerContainer = () => {
    const location = useLocation();
    const tab = location.pathname.split("/").at(-1);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true);
        }, 50);
    }, []);

    const { id } = useParams();
    const getFileUrl = useFileUrl();
    const tr = useTranslationRoute();

    const { data } = useCuriculumVitaeDetailsQuery(parseInt(id), true);
    const cvViewerMenuItems = useCurriculumVitaeViewerMenuItems();

    return (
        <RightSidebar
            className={`right-sidebar-absolute overflow-x-hidden ${
                open ? "" : "hide"
            }`}
        >
            <Box
                data-testid="recruiting-advert-cv-viewer"
                title={
                    <div className="flex gap-2">
                        <Avatar
                            src={
                                data?.profilePicture?.id?.toString() &&
                                getFileUrl(data?.profilePicture?.id)
                            }
                            fullName={data?.name}
                            size="md"
                        />

                        <h3>{data?.name}</h3>
                    </div>
                }
                tools={
                    <Link
                        data-testid={`recruiting-cv-viewer-details-link`}
                        to={tr(`admin/recruiting/cv/${id}/details`)}
                    >
                        <i className="fa-solid fa-eye"></i>
                    </Link>
                }
                className="h-full flex flex-col gap-3 overflow-y-auto"
            >
                <div>
                    <Box className="p-0 overflow-x-auto">
                        <Menu
                            items={cvViewerMenuItems}
                            type="HORIZONTAL"
                            activeFn={(menuItem) => menuItem.ke === tab}
                            className="menu-tabs"
                        />
                    </Box>
                </div>

                <GenericSuspense fallback={<MainLogo className="absolute" />}>
                    <Outlet />
                </GenericSuspense>
            </Box>
        </RightSidebar>
    );
};

export default CurriculumVitaeViewerContainer;
