import { Box, Metadata, RightSidebar } from "@mzara/component";
import { useFormationChapterListQuery } from "../hooks/useFormationChapterListQuery";

const ChaptersRightSideBar = ({ formationId }: ChaptersRightSideBarProps) => {
    const { data: chapters } = useFormationChapterListQuery(formationId);

    return (
        <RightSidebar className="right-sidebar-absolute w-80">
            <Box className=" h-full" title="Chapitres">
                <ul className="mt-5 flex flex-col gap-4">
                    {/* {chapters?.data?.map((chapter) => (
                        <li key={chapter.id}>
                            <span>{chapter.title}</span>
                            <i className="fa-solid fa-circle-check"></i>
                        </li>
                    ))} */}
                    <li className="flex flex-col gap-2">
                        <h4 className="font-semibold text-lg">Partie 1</h4>

                        <ul className="ml-5">
                            <li className="flex items-center justify-between">
                                <span>Chapitre 1</span>
                                <i className="fa-solid fa-circle-check"></i>
                            </li>
                            <li>
                                <span>Chapitre 2</span>
                            </li>
                            <li>
                                <span>Chapitre 3</span>
                            </li>
                        </ul>
                    </li>

                    <li className="flex flex-col gap-2">
                        <h4 className="font-semibold text-lg">Partie 2</h4>

                        <ul className="ml-5">
                            <li>
                                <span>Chapitre 1</span>
                            </li>
                            <li>
                                <span>Chapitre 2</span>
                            </li>
                            <li>
                                <span>Chapitre 3</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </Box>
        </RightSidebar>
    );
};

export default ChaptersRightSideBar;

type ChaptersRightSideBarProps = { formationId: number };
