import { AppRouteProps } from "@mzara/component";
import React from "react";

const AdvertSearchContainer = React.lazy(
    () => import("./container/AdvertSearchContainer")
);

export const AdvertSearchRoutes: Array<AppRouteProps> = [
    {
        path: "recrutement",
        title: "Harea.Client.Recruiting.Advert.Search.Title",
        element: <AdvertSearchContainer />,
    },
];
