import { GraphQLQueryClass } from "@mzara/component";
import { TFormationChaptersResponse } from "@mzara/graphql";

export class FormationChapterListQuery
    implements GraphQLQueryClass<TFormationChaptersResponse, TResponse>
{
    queryKey: string = "Harea.Formation.Chapter.List.Query";

    gql: string = `
        query formationChaptersListQuery($data: JSONObject) {
            formationChapters(filter: { data: $data }) {
                total
                data {
                    id
                    title
                    createdAt
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TFormationChaptersResponse {
        return data.formationChapters;
    }
}

type TResponse = {
    formationChapters: TFormationChaptersResponse;
};
