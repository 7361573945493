import { BaseControl, BaseControlProps, Chip, MenuItemProps, useTranslations } from "@mzara/component";
import { useCallback, useMemo } from "react";

export const RepetitionControl = (props: Props) => {
    
    const [
        ALL_DAYS,
        ALL_WEEKS,
        ALL_MONTHS,
        ALL_YEARS,
        DAYS_OF_THE_WEEK,
        MONDAY,
        TUESDAY,
        WEDNESDAY,
        THURSDAY,
        FRIDAY,
        SATURDAY,
        SUNDAY,
    ] = useTranslations(i18n)

    const options = useMemo((): Array<MenuItemProps> => {
        return [
            {
                label: ALL_DAYS,
                value: '{{minute}} {{hour}} * * *'
            },
            {
                label: ALL_WEEKS,
                value: '{{minute}} {{hour}} * * {{day}}'
            },
            {
                label: ALL_MONTHS,
                value: '{{minute}} {{hour}} {{date}} * *'
            },
            {
                label: ALL_YEARS,
                value: '{{minute}} {{hour}} {{date}} {{month}} *'
            },
            {
                label: DAYS_OF_THE_WEEK,
                value: '{{minute}} {{hour}} * * {{days}}'
            },
        ]
    }, [])

    const daysOptions = useMemo((): Array<MenuItemProps> => {
        return [
            {
                label: SUNDAY,
                value: 0
            },
            {
                label: MONDAY,
                value: 1
            },
            {
                label: TUESDAY,
                value: 2
            },
            {
                label: WEDNESDAY,
                value: 3
            },
            {
                label: THURSDAY,
                value: 4
            },
            {
                label: FRIDAY,
                value: 5
            },
            {
                label: SATURDAY,
                value: 6
            },
        ]
    }, [])

    const daysValue = useMemo(() => {
        const [, val] = props.value?.match(/\{\{minute\}\} \{\{hour\}\} \* \* ([0-9,]+)/) ?? []
        return val?.split(',').map((item) => parseInt(item)) ?? [0]
    }, [props.value])

    const handlePersonlizedClick = (value: number) => {
        const newValue = daysValue.includes(value) ? daysValue.filter((item) => item !== value) : [...daysValue, value]
        props.onChange?.('{{minute}} {{hour}} * * {{days}}'.replace('{{days}}', newValue.join(',')))
    }


    const showPersonalized = useMemo(() => {
        return props.value === '{{minute}} {{hour}} * * {{days}}'
            || /\{\{minute\}\} \{\{hour\}\} \* \* ([0-9,]+)/.test(props.value)
    }, [props.value])

    
    const optionIsSelected = useCallback((item: MenuItemProps) => {
        if (item.value === '{{minute}} {{hour}} * * {{days}}' && showPersonalized) {
            return true
        }
        return item.value === props.value
    }, [props.value])

    return (
        <BaseControl
            {...props}
        >
            {!props.readonly && (
                <div className="flex flex-col gap-2">
                    <div className="flex gap-2 flex-wrap">
                        {options.map((option, index) => (
                            <Chip
                                key={`rsvp-${index}`}
                                label={option.label}
                                className={`${optionIsSelected(option) ? "bg-primary" : "bg-default"}`}
                                onClick={() => props.onChange?.(option.value)}
                            />
                        ))}
                    </div>
                    {showPersonalized && (
                        <div className="flex gap-2 flex-wrap">
                            {daysOptions.map((option, index) => (
                                <Chip
                                    key={`rsvp-${index}`}
                                    label={option.label}
                                    className={`${daysValue.includes(option.value) ? "bg-primary" : "bg-default"}`}
                                    onClick={() =>
                                        handlePersonlizedClick(option.value)
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}
            {props.readonly && (
                <div className="flex flex-col gap-2">
                    {
                        !showPersonalized &&
                        <div className="flex gap-2 flex-wrap">
                            {options
                                .filter((option) => optionIsSelected(option))
                                .map((option, index) => (
                                    <Chip
                                        key={`rsvp-${index}`}
                                        label={option.label}
                                        className="bg-primary"
                                    />
                                ))}
                        </div>
                    }
                    {showPersonalized && (
                        <div className="flex gap-2 flex-wrap">
                            {daysOptions
                                .filter((option) =>
                                    daysValue.includes(option.value)
                                )
                                .map((option, index) => (
                                    <Chip
                                        key={`rsvp-${index}`}
                                        label={option.label}
                                        className="bg-primary"
                                    />
                                ))}
                        </div>
                    )}
                </div>
            )}
        </BaseControl>
    );
};

const i18n = [
    'Harea.Project.Calendar.RepetitionControl.Option.AllDays',
    'Harea.Project.Calendar.RepetitionControl.Option.AllWeeks',
    'Harea.Project.Calendar.RepetitionControl.Option.AllMonths',
    'Harea.Project.Calendar.RepetitionControl.Option.AllYears',
    'Harea.Project.Calendar.RepetitionControl.Option.DaysOfTheWeek',
    'Harea.Project.Calendar.RepetitionControl.Option.DaysOfTheWeek.Monday',
    'Harea.Project.Calendar.RepetitionControl.Option.DaysOfTheWeek.Tuesday',
    'Harea.Project.Calendar.RepetitionControl.Option.DaysOfTheWeek.Wednesday',
    'Harea.Project.Calendar.RepetitionControl.Option.DaysOfTheWeek.Thursday',
    'Harea.Project.Calendar.RepetitionControl.Option.DaysOfTheWeek.Friday',
    'Harea.Project.Calendar.RepetitionControl.Option.DaysOfTheWeek.Saturday',
    'Harea.Project.Calendar.RepetitionControl.Option.DaysOfTheWeek.Sunday',
]

type Props = BaseControlProps & {
    value?: string;
    onChange?: (value: Props["value"]) => void;
};
