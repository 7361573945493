import {
    ControlList,
    Dialog,
    GenericSuspense,
    useListGraphqlQuery,
    useTranslation,
    useTranslationRoute,
    useUrlParamsEncode,
    useUrlParamsValue,
} from "@mzara/component";
import { RECRUITING_EVALUATION_LIST_ID } from "../../../container/RecruitingContainer";
import { useNavigate } from "react-router-dom";
import { useRecruitingEvaluationForm } from "../hooks/useRecruitingEvaluationForm";
import { useCreateAndSendEvaluation } from "../hooks/useCreateAndSendEvaluation";

const CreateContainer = () => {
    const navigate = useNavigate();

    const t = useTranslation();
    const { invalidateQuery } = useListGraphqlQuery(
        RECRUITING_EVALUATION_LIST_ID
    );
    const urlParams = useUrlParamsValue();
    const encodeUrl = useUrlParamsEncode();
    const tr = useTranslationRoute();

    const addControl = useRecruitingEvaluationForm();
    const mutation = useCreateAndSendEvaluation();

    const closeDialog = () => {
        navigate(tr(`admin/evaluation/recruiting?${encodeUrl(urlParams)}`));
    };

    const handleSubmit = (value: Record<string, any>) => {
        // console.log(value);
        mutation.mutate(
            {
                wizardId: value.wizard?.id,
                recruitingAdvertId: value.advert?.id,
                name: value.name,
                email: value.email,
                subject: value.subject,
                message: value.message,
                curiculumVitaeId: value.curiculumVitae?.id
            },
            {
                onSuccess: () => {
                    invalidateQuery();
                    closeDialog();
                },
            }
        );
    };

    return (
        <Dialog
            open
            title={t("Harea.Evaluation.Recruiting.Add.Dialog.Title")}
            onClose={closeDialog}
        >
            <GenericSuspense>
                <ControlList
                    {...addControl}
                    isSubmit={mutation.isLoading}
                    onSubmit={handleSubmit}
                    onCancel={closeDialog}
                />
            </GenericSuspense>
        </Dialog>
    );
};

export default CreateContainer;
