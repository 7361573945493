import { AppRouteProps } from "@mzara/component";
import FormationAdminContainer from "./container/FormationAdminContainer";
import { FormationRoutes } from "./views/formation/FormationRoutes";
import { FormationDetailsRoutes } from "./views/formation-details/FormationDetailsRoutes";

export const FormationAdminRoutes: Array<AppRouteProps> = [
    {
        path: "formation",
        title: "Harea.Admin.Formation.Title",
        element: <FormationAdminContainer />,
        children: [...FormationRoutes, ...FormationDetailsRoutes],
    },
];
