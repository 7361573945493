import { ControlListProps, useTranslations } from "@mzara/component";

export const useRecruitingEvaluationFilterForm = (): ControlListProps => {
    const [
        NAME,
        EMAIL,
        CREATED_AT,
        ENDED_AT,
        NEW_EVALUATION,
        IN_PROGRESS_EVALUATION,
        FINISHED_EVALUATION,
        ADVERT,
        APPLICANT,
    ] = useTranslations(i18n);

    return {
        id: "recruiting-evaluation-list-filter-form",
        data: {
            forms: [
                {
                    type: "text",
                    name: "name_like",
                    label: NAME,
                },
                {
                    type: "text",
                    name: "email_like",
                    label: EMAIL,
                },
                { type: "date", name: "createdAt", label: CREATED_AT },
                { type: "date", name: "dateEnd", label: ENDED_AT },
                {
                    type: "checkbox",
                    name: "state",
                    multiple: true,
                    options: [
                        { label: NEW_EVALUATION, value: "NEW" },
                        { label: IN_PROGRESS_EVALUATION, value: "IN_PROGRESS" },
                        { label: FINISHED_EVALUATION, value: "FINISHED" },
                    ],
                },
                {
                    type: "select",
                    name: "recruitingAdvert",
                    label: ADVERT,
                    multiple: true,
                    GQLProps: {
                        gql: advertGQL,
                        labelProp: "title",
                        valueProp: "id",
                        rootProp: "recruitingAdverts.data",
                    },
                },
                {
                    type: "select",
                    name: "curiculumVitae",
                    label: APPLICANT,
                    multiple: true,
                    GQLProps: {
                        gql: cvGQL,
                        labelProp: "name",
                        valueProp: "id",
                        rootProp: "recruitingCuriculumVitaes.data",
                    },
                },
            ],
            hideFooter: true,
            graphQL: {
                entity: "evaluations",
            },
        },
    };
};

const advertGQL = `
    query recruitingAdvertsSelectQuery {
        recruitingAdverts {
            total 
            data {
                id
                title
            }
        }
    }
`;

const cvGQL = `
    query recruitingCVSelectQuery {
        recruitingCuriculumVitaes {
            total 
            data {
                id
                name
            }
        }
    }
`;

const i18n = [
    "std_name",
    "std_email_adress",
    "std_created_at",
    "std_end_at",
    "Generic.Evaluation.New.State.Text",
    "Generic.Evaluation.In_progress.State.Text",
    "Generic.Evaluation.Finished.State.Text",
    "Generic.de.RECRUTING_ADVERT",
    "std_applicant",
];
