import { AppRouteProps } from "@mzara/component";
import { MindMapInvitationContainer } from "../invitation/containers/MindMapInvitationContainer";
import { MindMapInvitationEditRoute } from "./views/edit/MindMapInvitationEditRoute";

export const MindMapDetailsInvitationRoutes: Array<AppRouteProps> = [
    {
        path: 'invitation',
        element: <MindMapInvitationContainer />,
        children: [
            ...MindMapInvitationEditRoute
        ]
    }
]