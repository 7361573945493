import { AppRouteProps } from "@mzara/component";
import React from "react";
import { CreateContractTemplateRoute } from "./views/create/CreateContractTemplateRoute";
import ContractTemplateDetails from "./views/details/container/ContractTemplateDetails";
import { ContractTemplateDetailsAboutRoute } from "./views/details/views/about/ContractTemplateDetailsAboutRoute";
import { ContractTemplateDetailsParameterRoute } from "./views/details/views/parameter/ContractTemplateDetailsParameterRoute";

const ContractTemplateContainer = React.lazy(() => import("./container/ContractTemplateContainer"));

export const ContractTemplateRoutes: Array<AppRouteProps> = [
    {
        path: "contract/template",
        element: <ContractTemplateContainer />,
        title: "Harea.Recruiting.Contract.Template.Title",
        children: [...CreateContractTemplateRoute],
        roles: ["CONTRACT_TEMPLATE"]
    },
    {
        path: "contract/template/details/:id",
        element: <ContractTemplateDetails />,
        children: [...ContractTemplateDetailsAboutRoute, ...ContractTemplateDetailsParameterRoute],
        roles: ["CONTRACT_TEMPLATE"]

    },
];
