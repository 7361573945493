import { AppRouteProps } from "@mzara/component";
import React from "react";
import { CreateEvaluationTemplateRoutes } from "./views/create/CreateEvaluationTemplateRoutes";
import { TemplateDetailsRoutes } from "./views/details/TemplateDetailsRoutes";

const TemplateContainer = React.lazy(() => import('./containers/TemplateContainer'))
export const TemplateRoutes: Array<AppRouteProps> = [
    {
        path: 'template',
        title: 'Harea.Evaluation.Template.List.Page.Title',
        element: <TemplateContainer />,
        children: [
            ...CreateEvaluationTemplateRoutes,
        ],
        roles: ["EVALUATION_TEMPLATE"],
    },
    ...TemplateDetailsRoutes
]