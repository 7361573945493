import { BreadCrumbItemProps, useTranslations } from "@mzara/component";

export const useBreadCrumbItems = (
    formationTitle: string
): Array<BreadCrumbItemProps> => {
    const [HOME, FORMATION] = useTranslations(i18n);

    return [{ label: HOME }, { label: FORMATION }, { label: formationTitle }];
};

const i18n = ["std_home", "std_formation"];
