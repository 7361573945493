import { AppRouteProps } from "@mzara/component";
import ParametersContainer from "./container/ParametersContainer";

export const ParametersRoutes: Array<AppRouteProps> = [
    {
        path: "parameters",
        title: "Harea.Formation.Details.Parameters.Title",
        element: <ParametersContainer />,
    },
];
