import { useTranslation } from "@mzara/component";
import { Thread } from "@mzara/thread";
import { useParams } from "react-router-dom";
import { useCuriculumVitaeDetailsQuery } from "views/admin/views/CV/hooks/useCuriculumVitaeDetailsQuery";
import { useThreadNotification } from "../../../hooks/useThreadNotification";

const DiscussionContainer = () => {
    const { id } = useParams();

    const t = useTranslation();

    const { data } = useCuriculumVitaeDetailsQuery(parseInt(id), true);
    const notify = useThreadNotification()

    return (
        <div data-testid="recruiting-advert-details-cv-tab-discussion">
            <Thread
                id={data?.thread?.id}
                title={t("std_discussion")}
                commentEnabled={!data?.thread?.disableComment}
                isEnabled={!data?.disableThread}
                onNotify={(userIds) => notify(userIds, data)}
            />
        </div>
    );
};

export default DiscussionContainer;

type DiscussionContainerProps = {};
