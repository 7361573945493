import { useMemo } from "react"
import { useAppointmentBoardQuery } from "../../../hooks/useAppointmentBoardQuery"

export const useKanbanColumnsOrder = (id: string) => {

    const { data: board } = useAppointmentBoardQuery(parseInt(id))
    return useMemo(() => {
        const columnsOrder = JSON.parse(board?.columnsOrder ?? '[]')
        return columnsOrder
    }, [board])
}
