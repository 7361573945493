import { useMemo } from "react"
import { useKanbanColumnCards } from "./useKanbanColumnCards"
import { KanbanColumnProps } from "views/kanban/views/details/components/KanbanColumn"
import { useAppointmentBoardQuery } from "../../../hooks/useAppointmentBoardQuery"

export const useKanbanColumns = (id: string) => {

    
    const { data: board } = useAppointmentBoardQuery(parseInt(id))
    const getColumnCards = useKanbanColumnCards(id)

    return useMemo((): Array<KanbanColumnProps> => {
        const columnsOrder = JSON.parse(board?.columnsOrder ?? '[]')
        return columnsOrder.map((item, index): KanbanColumnProps => {
            const column = board?.columns.find((item1) => item1.id === item.id)

            return {
                index,
                label: column,
                title: '',
                canCreate: false,
                isEditable: board?.isEditable as any,
                cards: getColumnCards(column.id)
            }
        })
    }, [board, getColumnCards])

}
