import { CurrentBreadCrumb, Step, useTranslation } from "@mzara/component";
import { useSaveRecruitingAdvertSteps } from "../hooks/useSaveRecruitingAdvertSteps";

const PublicationNewContainer = () => {
    const t = useTranslation();
    const steps = useSaveRecruitingAdvertSteps();

    return (
        <section
            data-testid="recruiting-advert-add-form"
            className="add-recruiting-advert-container main-content"
        >
            <h4 className="font-semibold">
                {t("Harea.Recruiting.Advert.New.Page.Title")}
            </h4>
            
            <CurrentBreadCrumb />

            <div className="add-recruiting-advert-form w-full">
                <Step steps={steps} layout="STEP" index={0} />
            </div>
        </section>
    );
};

export default PublicationNewContainer;
