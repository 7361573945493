import { AppRouteProps } from "@mzara/component";
import React from "react";

const PayslipThisMonthContainer = React.lazy(() => import("./container/PayslipThisMonthContainer"));
export const PayslipThisMonthRoute = [
    {
        path: "this-month",
        element: <PayslipThisMonthContainer />,
        roles: ["PAYING_SLIP"]

    },
] as Array<AppRouteProps>;
