import { AppRouteProps } from "@mzara/component";
import React from "react";
import { CreateContractRoute } from "views/create/CreateContractRoute";
import { ContractDepartureRoute } from "views/departure/ContractDepartureRoute";
import { SalaryContractRoutes } from "views/salary/SalaryContractRoutes";
import { ContractTemplateRoutes } from "views/template/ContractTemplateRoute";
import { DetailContractRoutes } from "views/detail/DetailContractRoutes";

const ContractContainer = React.lazy(() => import("./container/ContractContainer"));

export const ContractRoutes: Array<AppRouteProps> = [
    {
        path: "contract",
        title: "Harea.Contract.Heading.Title",
        element: <ContractContainer />,
        roles: ["CONTRACT"],
        children: [
            ...CreateContractRoute,
        ]
    },
    ...ContractTemplateRoutes,
    ...ContractDepartureRoute,
    ...SalaryContractRoutes,
    ...DetailContractRoutes,
];
