import { GraphQLQueryClass } from "@mzara/component";
import { TFormation } from "@mzara/graphql";

export class FormationDetailsQuery
    implements GraphQLQueryClass<TFormation, TResponse>
{
    queryKey: string = "Harea.Formation.Details.Query";

    gql: string = `
        query formationDetailsQuery($id: Float) {
            formation(filter: { id: $id }) {
                id
                title
                category {
                    id
                    value
                }
                isPublic
                description
                miniature {
                    id
                    name
                }
                createdAt
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TFormation {
        return data.formation;
    }
}

export type TResponse = { formation: TFormation };
