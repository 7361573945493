import { Box, GenericSuspense, MainLogo, Menu, useTranslationRoute } from "@mzara/component"
import { useAdvertViewerMenuItems } from "../hooks/useAdvertViewerMenuItems";
import { usePublicationViewerQuery } from "../hooks/usePublicationViewerQuery";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

export const PublicationViewer = () => {

    const tr = useTranslationRoute();
    const { id } = useParams();
    const { data } = usePublicationViewerQuery(+id);
    const advertViewerMenuItems = useAdvertViewerMenuItems();
    const location = useLocation();
    const tab = location.pathname.split("/").at(-1);

    return (
        <Box
            title={data?.title}
            titleTestId="recruiting-advert-details-about-title"
            tools={
                <Link data-testid={`recruiting-advert-viewer-details-link`} to={tr(`admin/recruiting/${id}/about`)}>
                    <i className="fa-solid fa-eye"></i>
                </Link>
            }
            className="h-full flex flex-col gap-3 overflow-y-auto"
        >
            <div>
                <Box className="p-0 overflow-x-auto">
                    <Menu
                        items={advertViewerMenuItems}
                        type="HORIZONTAL"
                        activeFn={(menuItem) => menuItem.ke === tab}
                        className="menu-tabs"
                    />
                </Box>
            </div>

            <GenericSuspense fallback={<MainLogo className="absolute" />}>
                <Outlet />
            </GenericSuspense>
        </Box>
    )
}
