import { Chip, useTranslations } from "@mzara/component"
import { useEffect, useMemo } from "react"

export const ProjectGanttViews = (props: ProjectGanttViewsProps) => {

    const [
        QUARTER_DAY,
        HALF_DAY,
        DAY,
        WEEK,
        MONTH
    ] = useTranslations(i18n)



    const options = useMemo(() => {
        return [
            { value: 'Half Day', label: HALF_DAY },
            { value: 'Quarter Day', label: QUARTER_DAY },
            { value: 'Day', label: DAY },
            { value: 'Week', label: WEEK },
            { value: 'Month', label: MONTH },
        ]
    }, [QUARTER_DAY])

    useEffect(() => {
        if (!props.value) {
            setTimeout(() => props.onChange?.(options[0].value), 700)
        }
    }, [props.value])

    return (
        <div className="flex gap-2 flex-nowrap">
            {
                options.map((item) => (
                    <Chip 
                        className={`${item.value === props.value ? 'bg-primary' : ''}`}
                        label={item.label} 
                        onClick={() => props.onChange?.(item.value)} 
                        />
                ))
            }
        </div>
    )
}

export type ProjectGanttViewsProps = {
    value: string
    onChange?: (value: string) => void
}

const i18n = [
    'Harea.Project.Kanban.Details.Gantt.Views.QUARTER_DAY.Label',
    'Harea.Project.Kanban.Details.Gantt.Views.HALF_DAY.Label',
    'Harea.Project.Kanban.Details.Gantt.Views.DAY.Label',
    'Harea.Project.Kanban.Details.Gantt.Views.WEEK.Label',
    'Harea.Project.Kanban.Details.Gantt.Views.MONTH.Label',
]
