import { useTranslationRoute, useTranslations } from "@mzara/component";
import { useMemo } from "react";

export const useLeftSideBarMenuPerformanceManagement = () => {

    const [
        PERFORMANCE_MANAGEMENT,
        EVALUATION_PERFORMANCE,
        FORMATION,
        PROMOTION,
        EVALUATION_MEETING,
    ] = useTranslations(i18n)
    const translationRoute = useTranslationRoute();
    return useMemo(() => ({
        ke: "performance",
        label: PERFORMANCE_MANAGEMENT,
        className: "menu-item-category",
        items: [
            {
                label: EVALUATION_PERFORMANCE,
                link: translationRoute("#"),
                startIcon: "fa-solid fa-user-gear",
                endIcon: 'fa-solid fa-person-digging',
            },
            {
                label: FORMATION,
                startIcon: "fa-solid fa-award",
                link: translationRoute("admin/formation"),
                roles: ["FORMATION"],
            },
            {
                label: PROMOTION,
                link: translationRoute("#"),
                startIcon: "fa-solid fa-person-arrow-up-from-line",
                endIcon: 'fa-solid fa-person-digging',
            },
            {
                label: EVALUATION_MEETING,
                link: translationRoute("#"),
                startIcon: "fa-solid fa-chalkboard-user",
                endIcon: 'fa-solid fa-person-digging',
            },
        ],
    }), [])
}

const i18n = [
    'Harea.Admin.LeftSideBar.Menu.PerformanceManagement.Label',
    'Harea.Admin.LeftSideBar.Menu.PerformanceManagement.EvaluationPerformance.Label',
    'Harea.Admin.LeftSideBar.Menu.PerformanceManagement.Formation.Label',
    'Harea.Admin.LeftSideBar.Menu.PerformanceManagement.Promotion.Label',
    'Harea.Admin.LeftSideBar.Menu.PerformanceManagement.EvaluationMeeting.Label',
]
