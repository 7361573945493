import { MenuItemProps, useTranslation, useTranslations } from "@mzara/component";
import { useMemo } from "react";

export const useMilestoneStateFilters = () => {
    const t = useTranslation();
    const [
        ALL,
        OPENED,
        CLOSED,
        ARCHIVED
    ] = useTranslations(i18n)

    return useMemo((): Array<MenuItemProps> => {
        return [
            {
                label: ALL,
                value: 'ALL',
            },
            {
                label: OPENED,
                value: 'OPENED',
            },
            {
                label: CLOSED,
                value: 'CLOSED',
            },
            {
                label: ARCHIVED,
                value: 'ARCHIVED',
            },
        ];
    }, [ALL]);
};

const i18n = [
    'std_all',
    'std_opened',
    'std_closed',
    'std_archived'
]
