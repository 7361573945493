import { AppRouteProps } from "@mzara/component";
import React from "react";

const ProjectKanbanCreateAppointmentContainer = React.lazy(() => import("./container/ProjectKanbanCreateAppointmentContainer"));

export const ProjectKanbanDetailsCreateRoutes: Array<AppRouteProps> = [
    {
        path: "-1",
        title: "Harea.Project.Kanban.Details",
        element: <ProjectKanbanCreateAppointmentContainer />,
        roles: ["APPOINTMENT"]
    },
];
