
import './css/navbar.css'

export const EvaluationNavBar = () => {

    return (
        <>
            <header
                className={`sticky top-0 flex z-50 justify-between items-center bg-white shadow-md px-4 py-2`}
            >
                <div>
                    <img src={"/assets/img/logo.png"} alt="Logo Harea" className={`h-10`
                    } />
                </div>
            </header>
        </>
    );
};
