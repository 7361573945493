import { ControlList, useControlFormList, useFormData, useListGraphqlQuery, useRuntimeEnv, useRuntimeEnvRolesIncludes, useUrlParamsValue } from "@mzara/component";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useAppointmentVacationControlForm } from "../hooks/useAppointmentVacationControlForm";
import { useContractManagerQuery } from "hooks/useContractManagerQuery";
import { useProjectAppointmentCreateDefaultValue } from "views/create/hooks/useProjectAppointmentCreateDefaultValue";
import { useProjectAppointmentNavigateBack } from "views/create/hooks/useProjectAppointmentNavigateBack";
import { useAppointmentsInvalidateQuery } from "hooks/useAppointmentsInvalidateQuery";
import { TAppointment } from "@mzara/graphql";

const ProjectAppointmentCreateVacationContainer = () => {
    const navigate = useNavigate();
    const { runtimeEnv } = useRuntimeEnv();
    const { invalidateQuery: invalidateDemandListQuery } = useListGraphqlQuery("dayoff-demand-list-id");
    const control = useAppointmentVacationControlForm();

    const { formData } = useFormData<Partial<TAppointment>>({ id: CONTRACT_CONTROL_ID, formData: {} })
    const { data, isLoading } = useContractManagerQuery(formData?.user?.id);
    const defaultValues = useProjectAppointmentCreateDefaultValue()
    const roleInclude = useRuntimeEnvRolesIncludes()
    const navigateBack = useProjectAppointmentNavigateBack()
    const invalidateAppointmentsQuery = useAppointmentsInvalidateQuery()

    const handleSubmited = () => {
        invalidateAppointmentsQuery()
        invalidateDemandListQuery();
        navigateBack();
    };

    return (
        <ControlList
            {...control}
            id={CONTRACT_CONTROL_ID}
            defaultValue={{
                ...defaultValues,
                type: "CONGE",
                user: {
                    id: !roleInclude('APPOINTMENT_ABSCENCE') ? runtimeEnv?.user?.id : null,
                },
            }}
            disableSubmit={isLoading}
            onSubmited={handleSubmited}
            onCancel={() => navigate(-1)}
            onBeforeSaving={(value) => {
                const hourBegin = value?.hourBegin?.split(":")
                const hourEnd = value?.hourEnd?.split(":")
                const dateBegin = value.dateBegin ? moment(value.dateBegin).set({ hour: hourBegin?.[0], minute: hourBegin?.[1] }).format() : null
                const dateEnd = moment(value.dateEnd ? value.dateEnd : value.dateBegin).set({ hour: hourEnd?.[0], minute: hourEnd?.[1] }).format()
                return ({
                    ...value,
                    dateBegin,
                    dateEnd,
                    invitations: [
                        data?.manager ? { id: null, user: { id: data?.manager?.id } } : null,
                    ].filter((item) => item !== null),
                })
            }}
        />
    );
};

const CONTRACT_CONTROL_ID = 'Contract.Control'

export default ProjectAppointmentCreateVacationContainer;

const i18n = [
    "Harea.Project.DayOff.Create.Notification.Message",
    "Harea.Project.DayOff.Create.Dialog.Title",
];
