import { AppRouteProps } from '@mzara/component'
import React from 'react'
import { MindMapDetailsViewerRoutes } from './designer/views/viewer/MindMapDetailsViewerRoutes'

const MindMapDetailsDesigner = React.lazy(() => import('./designer/containers/MindMapDetailsDesigner'))

export const MindMapDetailsViewRoutes: Array<AppRouteProps> = [
    {
        path: 'designer',
        title: 'Harea.MindMap.Designer.title',
        element: <MindMapDetailsDesigner />,
        children: [
            ...MindMapDetailsViewerRoutes
        ],
    },
]
