import {
    GenericSuspense,
    List,
    Metadata,
} from "@mzara/component";
import { TAppointment } from "@mzara/graphql";
import { AppointmentsQuery } from "queries/AppointmentsQuery";
import { Link, Outlet, useNavigate } from "react-router-dom";
import PlanningListItem from "../components/PlanningListItem";
import { usePlanningListSort } from "../hooks/usePlanningListSort";
import { PlanningListFilter } from "../components/PlanningListFilter";

export const PlanningListContainer = () => {
    const sortItems = usePlanningListSort()

    return (
        <div className="flex flex-col gap-4">
            <PlanningListFilter listId={PLANNING_LIST_ID} />
            <List
                id={PLANNING_LIST_ID}
                selection={false}
                GQLRequest={AppointmentsQuery}
                isUrlParamsState={false}
                sort={{ items: sortItems }}
                defaultFilter={{
                    data: {
                        invitations: {
                            user: {},
                        },
                        labels: {},
                        board: {},
                        type_among: ["CONGE", "PLANNING", "ABSENCE"],
                    },
                    pageSize: 50,
                    sort: [{ sort: 'DESC', value: 'dateBegin' }]
                }}
                BoxProps={{
                    className: "shadow-none p-0"
                }}
                onRenderRow={(row: TAppointment) => (
                    <PlanningListItem item={row} />
                )}
            />

            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </div>
    );
};

export const PLANNING_LIST_ID = 'Project.Planning.List.Id'
