import { useCallback } from "react"
import { useKanbanCards } from "../../../hooks/useKanbanCards"

export const useKanbanColumnCards = (id: string) => {
    const cards = useKanbanCards(id)

    return useCallback((columnId: number) => {
        return cards?.filter((item) => {

            return !item.isClosed && item.labels.some((item1) => item1.id === columnId)
        })
    }, [cards])
}
