import { Box, Chip, Dropdown, IconButton, Menu, MenuItemProps, useTranslations } from "@mzara/component"
import { TListOfValue } from "@mzara/graphql"
import { useCallback, useMemo, useState } from "react"
import _ from "lodash"
import { KanbanCardList, KanbanCardListProps } from "./KanbanCardList"


export const KanbanColumn = (props: KanbanColumnProps) => {

    const [
        ADD,
    ] = useTranslations(i18n)

    const [anchor, setAnchor] = useState<HTMLButtonElement>()

    return (
        <Box
            title={
                <span>
                    {
                        props.label
                            ? <Chip label={props.label.value} color={props.label.color} />
                            : props.title
                    }
                </span>
            }
            className="flex box-default flex-col relative min-w-[360px] max-w-[360px] h-full p-4 pr-0"
            tools={(
                <>
                    <div className="flex justify-end mr-2 gap-2 ml-auto">
                        <div>
                            <Chip startIcon="fa-ticket" label={props.cards?.length.toString()} />
                        </div>
                        {
                            props.canCreate &&
                            <IconButton className="btn-default" title={ADD} icon="fa-plus" onClick={() => props.onAddClick?.()} />
                        }
                    </div>
                    {
                        props.menuItems &&
                        <>
                            <IconButton
                                className="mr-2"
                                icon="fa-solid fa-ellipsis"
                                onClick={(e) => setAnchor(e.currentTarget)}
                            />
                            <Dropdown anchorEl={anchor} onClose={() => setAnchor(undefined)}>
                                <Menu
                                    onClick={props.onMenuClick}
                                    items={props.menuItems}
                                />
                            </Dropdown>
                        </>
                    }
                </>
            )}>

            {
                props.label &&
                <div className="h-1 absolute top-0 left-0 right-0" style={{ backgroundColor: props.label.color }}></div>
            }
            <KanbanCardList
                {...props}
                columnType="CARDS"
            />
        </Box>

    )
}

export type KanbanColumnDragItem = {
    id: number;
    labelId: number,
    isClosed?: boolean;
}

export interface Item {
    id: number
    text: string
}

export interface ContainerState {
    cards: Item[]
}

const style = {
    width: 400,
}

const i18n = [
    'std_add',
    'std_delete',
    'std_move_left',
    'std_move_right',
]

export type KanbanColumnProps = {
    boardId?: number
    title: string
    index: number
    label?: TListOfValue
    /**
     * Exclusively used for live drag and drop purpose
     */
    isOpened?: boolean
    isClosed?: boolean
    canCreate?: boolean
    isEditable?: boolean
    menuItems?: MenuItemProps[]
    onMenuClick?: (menu: MenuItemProps) => void
    onClick?: (e: KanbanColumnProps) => void
    onAddClick?: () => void
} & KanbanCardListProps
