import { useAjax, useMzMutation, useTranslation } from "@mzara/component"

export const useRejectAppointmentInvitationMutation = (ke: string) => {
    const t = useTranslation()
    const ajax = useAjax()
    return useMzMutation((payload: Record<string, any>) => ajax.send({
        type: "GET",
        url: `notification/ical/revoke/${ke}?message=${payload.message}`,
        content_type: "application/json",
    }), {
        transformErrorMessage: () => {
            return t("std_unknown_error")
        }
    })
}