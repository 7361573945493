import { SortBarItemProps, useTranslations } from "@mzara/component";

export const useFormationListSortBar = (): Array<SortBarItemProps> => {
    const [TITLE, UPDATED_AT] = useTranslations(i18n);

    return [
        { label: TITLE, value: "name" },
        { label: UPDATED_AT, value: "updatedAt", className: "ml-auto" },
    ];
};

const i18n = ["std_title", "std_updated_at"];
