import { Box, Chip, Dropdown, IconButton, useNotificationCount, useTranslations } from "@mzara/component"
import { NotificationConfigButton, NotificationList, NotificationListToobar } from "@mzara/notification";
import { useState } from "react";

export const NotificationMenu = () => {

    const [
        NOTIFICATIONS
    ] = useTranslations(i18n)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const notificationCount = useNotificationCount()

    return (
        <div>
            <div className="relative">
                <IconButton
                    icon={"fa-bell"}
                    className={"text-lg"}
                    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                        setAnchorEl(e.currentTarget)
                    }
                />
                {
                    notificationCount > 0 &&
                    <Chip className="absolute top-0 right-[-5px] bg-secondary" label={notificationCount?.toString()} />
                }
            </div>
            <Dropdown
                
                anchorEl={anchorEl}
                arrow
                placement="bottom-end"
                onClose={() => setAnchorEl(undefined)}
            >
                <Box 
                    className="w-[320px] box-transparent"
                    title={NOTIFICATIONS}
                    tools={
                        <NotificationConfigButton />
                    }>
                    <NotificationList isInNavbar />
                </Box>
            </Dropdown>
        </div>
    );
}

const i18n = [
    'Harea.Admin.LeftSideBar.Menu.General.Notifications.Label',
]