import { useGraphqlMutation, useTranslation } from "@mzara/component";
import { TAppointmentBoard } from "@mzara/graphql";

export const useAppointmentBoardGraphqlMutation = () => {
    const t = useTranslation()
    return useGraphqlMutation<TAppointmentBoard>(gql, { showLoadingMessage: true, loadingMessage: t('std_saving') });
};

const gql = `
    mutation AppointmentBoardGraphqlMutation($data: JSONObject) {
        saveAppointmentBoard(data: { data: $data }) {
            id
        }
    }
`;
