import { AppRouteProps } from "@mzara/component";
import EvaluationsContainer from "./containers/EvaluationContainer";

export const EvaluationsRoutes: Array<AppRouteProps> = [
    {
        path: "evaluations",
        title: "std_assessment",
        element: <EvaluationsContainer />,
    },
];
