import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ContractDepartureDetailsRoute } from "./view/details/ContractDepartureDetailsRoute";

const ContractDepartureContainer = React.lazy(() => import("./container/ContractDepartureContainer"));

export const ContractDepartureRoute: Array<AppRouteProps> = [
    {
        path: "contract/departure",
        element: <ContractDepartureContainer />,
        roles: ["CONTRACT"]

    },
    ...ContractDepartureDetailsRoute,
];
