import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "animate.css";
import "@mzara/component/dist/main.css";
import "@mzara/component/dist/index.css";
import "@mzara/login/dist/main.css";
import "@mzara/login/dist/index.css";
import "@harea/recruiting/dist/main.css";
import "@harea/recruiting/dist/index.css";
import "@harea/evaluation/dist/main.css";
import "@mzara/user-management/dist/main.css";
import "@mzara/thread/dist/index.css";
import "@mzara/thread/dist/main.css";
// import "@mzara/user-management/dist/index.css";
import "@mzara/project/dist/main.css";
import "@mzara/project/dist/index.css";
import "@mzara/contract/dist/main.css";
import "@mzara/payslip/dist/main.css";
import "@mzara/notification/dist/main.css"
import "@mzara/mind-map/dist/main.css"
import "./index.css";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
