import { ControlList } from "@mzara/component"
import { useParams } from "react-router-dom"
import { useMindMapDangerZoneForm } from "../hooks/useMindMapDangerZoneForm"

export const MindMapDangerZoneContainer = () => {

    const { id } = useParams()
    const control = useMindMapDangerZoneForm(id ? parseInt(id) : undefined)

    return (
        <ControlList
            {...control}
        />
    )
}
