import { AppRouteProps } from "@mzara/component";
import React from "react";
import { CurriculumVitaeCreateRoutes } from "./views/create/CurriculumVitaeCreateRoutes";
import { CurriculumVitaeViewerRoutes } from "./views/viewer/CurriculumVitaeViewerRoutes";

const CVListContainer = React.lazy(() => import("./container/CVListContainer"));

export const CurriculumVitaeRoutes: Array<AppRouteProps> = [
    {
        path: "cv",
        title: "Harea.CV.List.title",
        element: <CVListContainer />,
        children: [
            ...CurriculumVitaeCreateRoutes,
            ...CurriculumVitaeViewerRoutes,
        ],
        roles: ["RECRUITING_ADVERT_CV"]
    },
];
