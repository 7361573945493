import { GraphQLQueryClass } from "@mzara/component";
import { TDescriptorsResponse } from "@mzara/graphql";

export class FormationCategoryListQuery
    implements GraphQLQueryClass<TDescriptorsResponse, TResponse>
{
    queryKey: string = "Generic.Formation.Category.List.Query";

    gql: string = `
        query formationCategoryListQuery($data: JSONObject) {
            descriptors(filter: { data: $data }) {
                total
                data {
                    id
                    translationKey
                    tp
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TDescriptorsResponse {
        return {
            data: data.descriptors.data,
            total: data.descriptors.total,
        };
    }
}

type TResponse = {
    descriptors: TDescriptorsResponse;
};
