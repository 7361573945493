import { useMemo } from "react"
import { useKanbanCards } from "../../../hooks/useKanbanCards"

export const useProjectGanttOpenedCards = (id: string) => {
    const cards = useKanbanCards(id)

    return useMemo(() => {
        return cards?.filter((item) => {
            return !item.isClosed
        }) ?? []
    }, [cards])

}
