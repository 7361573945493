import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectCalendarRoutes } from "views/calendar/CalendarRoutes";
import { ProjectDayOffRoutes } from "views/dayoff/ProjectDayOffRoutes";
import { ProjectKanbanRoutes } from "views/kanban/ProjectKanbanRoutes";

const ProjectContainer = React.lazy(() => import("./containers/ProjectContainer"));

// const PresenceContainer = React.lazy(() => import("./containers/PresenceContainer"));

export const ProjectRoutes: Array<AppRouteProps> = [
    {
        path: "project",
        title: "Harea.Project",
        element: <ProjectContainer id="ProjectContainer" view="timeGridWeek" />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectCalendarRoutes,
            ...ProjectKanbanRoutes,
        ]
    },
    {
        path: "project",
        title: "Harea.Project",
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectDayOffRoutes,
        ]
    }
];
