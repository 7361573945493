import { Box, Button, Dialog, Metadata, Timer, useTranslation, useTranslations } from "@mzara/component";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useStartEvaluationMutation } from "../hooks/useStartEvaluationMutation";

const Welcome = ({ totalStep, totalTime, ke, onStartConfirmed }: WecomeProps) => {
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [
        TITLE,
        DESCRIPTION,
        ADVICE_TITLE,
        ADVICE_DESCRIPTION,
        QUESTION,
        TIME,
        BEGIN_BUTTON,
        BEGIN_MODAL_TITLE,
        BEGIN_MODAL_DESCRIPTION
    ] = useTranslations(i18n)


    const mutation = useStartEvaluationMutation();

    const timer = useMemo(() => {
        if (totalTime) {
            const dateValue = moment().add(totalTime, 'minutes').format('x')
            return dateValue
        }
        return undefined
    }, [totalTime])

    const handleConfirmClick = useCallback(() => {
        mutation.mutate(
            { ke },
            {
                onSuccess: () => onStartConfirmed?.(),
            }
        );
    }, [])

    return (
        <div className="flex flex-col gap-4 w-full max-w-xl m-auto">
            <Box
                className=""
                title={TITLE}
                tools={
                    <>
                        <h3 className="flex gap-4">
                            <Metadata label={`${QUESTION} :`} value={totalStep.toString()} />
                            <Metadata title={TIME} icon="fa-clock" value={<Timer value={timer} disabled />} />
                        </h3>
                    </>
                }>
                <p className="mt-4" dangerouslySetInnerHTML={{ __html: DESCRIPTION.replaceAll('\n', '<br/>') }} />
            </Box>

            <Button
                className="btn-primary"
                label={BEGIN_BUTTON}
                onClick={() => setOpenConfirmDialog(true)}
            />

            <Dialog
                open={openConfirmDialog}
                title={BEGIN_MODAL_TITLE}
                message={BEGIN_MODAL_DESCRIPTION}
                confirmation={true}
                btnCancel={{}}
                btnOk={{ label: BEGIN_BUTTON, className: "bg-primary", isSubmit: mutation.isLoading }}
                onCancel={() => setOpenConfirmDialog(false)}
                onClose={() => setOpenConfirmDialog(false)}
                onConfirm={handleConfirmClick}
            />
        </div>
    );
};

const i18n = [
    'Harea.Evaluation.Client.Welcome.Title',
    'Harea.Evaluation.Client.Welcome.Description',
    'Harea.Evaluation.Client.Welcome.Advice.Title',
    'Harea.Evaluation.Client.Welcome.Advice.Description',
    'Harea.Evaluation.Client.Welcome.Question.Title',
    'Harea.Evaluation.Client.Welcome.Time.Title',
    'Harea.Evaluation.Client.Welcome.Begin.Button.Label',
    'Harea.Evaluation.Client.Welcome.Begin.Modal.Title',
    'Harea.Evaluation.Client.Welcome.Begin.Modal.Description',
]

export default Welcome;

type WecomeProps = {
    // advertTitle: string;
    ke: string
    totalStep: number;
    totalTime: number;
    onStartConfirmed?: () => void
};
