import { useTranslations } from "@mzara/component";

export const useAppointmentMissingControlForm = () => {
    const [REASON, SAVE, CANCEL, DATE_BEGIN, DATE_END, HOUR_BEGIN, HOUR_END, USER] = useTranslations(i18n);

    return {
        data: {
            forms: [
                {
                    type: "select",
                    name: "user",
                    label: USER,
                    valueObject: true,
                    valueProperty: 'id',
                    required: true,
                    GQLProps: {
                        gql: `
                            query {
                                contracts (filter:{ sort: [{ sort: "ASC", value: "user.fullName" }] }) {
                                    data{
                                        user {
                                            id fullName 
                                            profilePicture { id }
                                        }
                                    }
                                }
                            }
                        `,
                        rootProp: "contracts.data",
                        labelProp: "user.fullName",
                        valueProp: "user.id",
                        userIdProp: "user.id",
                    },
                },
                {
                    type: "date-picker",
                    name: "dateBegin",
                    label: DATE_BEGIN,
                    required: true,
                },
                {
                    type: "hour",
                    name: "hourBegin",
                    label: HOUR_BEGIN,
                },
                {
                    type: "date-picker",
                    name: "dateEnd",
                    label: DATE_END,
                    required: true,
                },
                {
                    type: "hour",
                    name: "hourEnd",
                    label: HOUR_END,
                },
                {
                    type: "textarea",
                    name: "description",
                    label: REASON,
                    required: true
                },
                {
                    type: "hidden",
                    name: "type",
                },
            ],
            value: {},
            next: {
                label: SAVE,
                className: "btn-primary",
            },
            back: {
                label: CANCEL,
            },
            graphQL: {
                entity: "appointment",
            },
        },
        id: "vacation-add-absence-form",
    };
}

const i18n = [
    "Harea.Vacation.Calendar.Event.Create.Absence.Form.Label.Reason",
    "std_save",
    "std_cancel",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.DateAbsenceBegin",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.DateAbsenceEnd",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.HourAbsenceBegin",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.HourAbsenceEnd",
    "std_user"
];
