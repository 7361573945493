import { IconButton, useTranslationRoute, useUrlParamsValue, } from "@mzara/component";
import React from "react";
import { AvatarMenu } from "../menu/AvatarMenu";
import "./css/navbar.css";
import { atom, useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import { NotificationMenu } from "./NotificationMenu";
import { OrganisationMenu } from "components/menu/OrganisationMenu";

export const AdminNavBar = ({ onToggleClick }: NavBarProps) => {
    const [openCalendar, setOpenCalendar] = useRecoilState(calendarOpenState)
    const urlParams = useUrlParamsValue();
    const hideSidebar = urlParams.create || urlParams.eventId;

    return (
        <header
            className={`sticky top-0 flex z-50 justify-between items-center bg-navbar text-white py-2 px-4 shadow-md`}
        >
            <IconButton icon={"fa-bars"} className={"text-lg"} onClick={onToggleClick} />
            <nav>
                <ul className="flex items-center gap-4 sm:gap-6">
                    
                    <li>
                        <NotificationMenu />
                    </li>

                    <li className="hidden md:block">
                        <OrganisationMenu />
                    </li>
                    <li className="ml-2">
                        <AvatarMenu />
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export type NavBarProps = {
    onToggleClick?: () => void;
};

export const calendarOpenState = atom({
    key: "calendarOpenState",
    default: false,
});