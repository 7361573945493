import { useMemo } from "react"
import { useKanbanCards } from "../../../hooks/useKanbanCards"
import { useKanbanColumnsOrder } from "./useKanbanColumnsOrder"
import _ from "lodash"

export const useKanbanOpenedCards = (id: string) => {
    const cards = useKanbanCards(id)
    const columnsOrder = useKanbanColumnsOrder(id)

    return useMemo(() => {
        return cards?.filter((item) => {
            return !item.isClosed
                && !item.labels.some((item1) => columnsOrder.some((item2) => item1.id === item2.id))
        }) ?? []
    }, [cards, columnsOrder])
}
