import { ControlListProps, useTranslations } from "@mzara/component";

export const useFormationListFilterForm = (): ControlListProps => {
    const [TITLE, PUBLIC] = useTranslations(i18n);

    return {
        id: "formation-list-filter-form",
        data: {
            forms: [
                { type: "text", name: "title_like", label: TITLE },
                { type: "toggle", name: "isPublic", label: PUBLIC },
            ],
            hideFooter: true,
        },
    };
};

const i18n = ["std_title", "std_public"];
