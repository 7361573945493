import { List } from "@mzara/component";
import { TFormation } from "@mzara/graphql";
import { useSortBarItems } from "../hooks/useSortBarItems";
import { FormationListQuery } from "queries/FormationListQuery";
import FormationItem from "./FormationItem";

interface FormationListProps {}

const FormationList = () => {
    const sortBarItems = useSortBarItems();

    return (
        <List
            id={CLIENT_FORMATION_ID}
            GQLRequest={FormationListQuery}
            sort={{ items: sortBarItems }}
            BoxProps={{ className: "!bg-transparent" }}
            defaultFilter={{
                pageSize: 10,
                page: 0,
                sort: [{ sort: "DESC", value: "updatedAt" }],
            }}
            onRenderRow={(row: TFormation) => <FormationItem data={row} />}
            search={false}
            selection={false}
            isUrlParamsState={true}
        />
    );
};

export default FormationList;

const CLIENT_FORMATION_ID = "Client.Formation.ID";
