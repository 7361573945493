import { ControlListProps, useTranslation } from "@mzara/component";

export const useFormationDangerZoneForm = (
    formationId: number
): ControlListProps => {
    const t = useTranslation();

    return {
        data: {
            title: t("std_danger_zone"),
            forms: [
                { type: "hidden", name: "id" },
                {
                    type: "call-to-action",
                    name: "id",
                    label: t("std_danger_zone"),
                    description: t(
                        "Harea.Formation.Details.Parameters.DangerZone.Message"
                    ),
                    button: {
                        className: "btn btn-danger text-white",
                        label: t("std_remove"),
                    },
                    graphQL: {
                        deleteEntity: true,
                        entity: "formation",
                        variables: {
                            id: formationId,
                        },
                        navigateFallback: "admin/formation",
                    },
                },
            ],
            hideFooter: true,
        },
        id: "formation-details-parameters-dangerZone-form",
    };
};
