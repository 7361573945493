import {
    Box,
    ControlList,
    RightSidebar,
    useListFilterState,
    useTranslation,
} from "@mzara/component";
import { useFormationListFilterForm } from "../hooks/useFormationListFilterForm";
import _ from "lodash";

const FormationListFilter = ({ listId, open }: FormationListFilterProps) => {
    const { filter, setFilter } = useListFilterState(listId);
    const t = useTranslation();

    const filterForm = useFormationListFilterForm();

    const handleFilterChange = (value: Record<string, any>) => {
        setFilter((v: any) => ({
            ...v,
            data: {
                ...v.data,
                ...value,
            },
        }));
    };

    return (
        <RightSidebar
            className={`right-sidebar-absolute ${open ? "" : "hide"}`}
        >
            <Box
                title={t("std_filter")}
                icon="fa-solid fa-filter"
                className="h-full"
            >
                <ControlList
                    {...filterForm}
                    value={filter?.data}
                    onChange={handleFilterChange}
                />
            </Box>
        </RightSidebar>
    );
};

export default FormationListFilter;

type FormationListFilterProps = {
    listId: string;
    open: boolean;
};
