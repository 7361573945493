import { ControlListProps, useTranslations } from "@mzara/component";
import { InputGoogleMeet } from "../components/InputGoogleMeet";
import { RepetitionControl } from "../components/RepetitionControl";
import { useAppointmentOwnerQuery } from "./useAppointmentOwnerQuery";
import { NotificationBeforeControl } from "../components/NotificationBeforeControl";
import { useMemo } from "react";

export const useAppointmentEventControlForm = (id?: number): ControlListProps => {
    const [
        DATE_BEGIN,
        HOUR_BEGIN, 
        HOUR_END, 
        SAVE, 
        CANCEL, 
        GUEST,  
        TITLE, 
        ATTACHMENT, 
        DESCRIPTION, 
        BOARD, 
        LABELS,
        REPETITION,
        NOTIFY_BEFORE,
    ] = useTranslations(i18n);
    
    const { data } = useAppointmentOwnerQuery(id, true);
    const isMoved = useMemo(() => data?.overrideTargetDateBegin || data?.overrideTargetHourBegin, [data])

    return useMemo((): ControlListProps => { 
        return {
            data: {
                forms: [
                    {
                        type: "input",
                        name: "title",
                        label: TITLE,
                        required: true,
                    },
                    {
                        type: "markdown",
                        name: "description",
                        label: DESCRIPTION,
                    },
                    {
                        type: "select",
                        multiple: true,
                        name: "invitations",
                        label: GUEST,
                        GQLProps: {
                            gql: `
                                query {
                                    users (filter:{ sort: [{ sort: "ASC", value: "fullName" }] }) {
                                        data{
                                            id fullName profilePicture { id }
                                        }
                                    }
                                }
                            `,
                            rootProp: "users.data",
                            labelProp: "fullName",
                            valueProp: "id",
                            userIdProp: "id",
                            valuePath: 'user.id'
                        },
                        onSelectedChipOption: (option) => {
                            const invitation = data?.invitations.find((invitation) => invitation?.user?.id === option.value);
                            if (invitation?.isValidated) {
                                return {...option, endIcon: 'fa-circle-check text-success'}
                            }
                            if (invitation?.isRevocked) {
                                return {...option, endIcon: 'fa-circle-xmark text-danger'}
                            }
                        }
                    },
                    {
                        type: "select",
                        label: BOARD,
                        name: "board",
                        GQLProps: {
                            gql: `
                                query {
                                    appointmentBoards {
                                        data {
                                            id title color
                                        }
                                    }
                                }
                            `,
                            colorProp: "color",
                            labelProp: "title",
                            valueProp: "id",
                            rootProp: "appointmentBoards.data",
                        },
                        valueObject: true,
                        valueProperty: "id",
                    },
                    {
                        type: 'lov-select',
                        multiple: true,
                        name: 'labels',
                        label: LABELS,
                        tp: 'APPOINTMENT_LABELS'
                    },
                    {
                        type: "date-picker",
                        name: "dateBegin",
                        label: DATE_BEGIN,
                        required: true,
                    },
                    {
                        type: "hour",
                        name: isMoved ? "overrideTargetHourBegin" : "hourBegin",
                        label: HOUR_BEGIN,
                    },
                    {
                        type: "hour",
                        name: isMoved ? "overrideTargetHourEnd" : "hourEnd",
                        label: HOUR_END,
                    },
                    {
                        type: "toggle",
                        name: "isRSVP",
                        label: REPETITION,
                    },
                    {
                        type: "repetition-control",
                        name: "repetition",
                    },
                    {
                        type: "notification-before",
                        name: "notificationTime",
                        label: NOTIFY_BEFORE,
                    },
                    // {
                    //     type: "input-google-meet",
                    //     name: "meet",
                    //     label: MEETING_LINK,
                    // },
                    {
                        type: "file",
                        name: "files",
                        label: ATTACHMENT,
                        multiple: true,
                    },
                    {
                        type: "hidden",
                        name: "type",
                    },
                    {
                        type: "hidden",
                        name: "id",
                    },
                ],
                value: {},
                next: {
                    label: SAVE,
                    className: "btn-primary ",
                },
                back: {
                    label: CANCEL,
                },
                graphQL: {
                    entity: "appointment",
                }, 
                fields: {
                    "input-google-meet": InputGoogleMeet,
                    "repetition-control": RepetitionControl,
                    "notification-before": NotificationBeforeControl
                }, 
            },
            id: "vacation-add-appointment-form",
        };
    }, [data, isMoved, id]);
}

const i18n = [
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.DateAppointmentBegin",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.HourAppointmentBegin",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.HourAppointmentEnd",
    "std_save",
    "std_cancel",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.Guest",
    "std_title",
    "std_attachment",
    "std_description",
    "std_board",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.Labels",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.Repetition",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore",
];