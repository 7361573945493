import { AppRouteProps } from "@mzara/component";
import React from "react";

const DetailsContainer= React.lazy(() => import('./containers/DetailsContainer'))

export const DetailsRoutes : Array<AppRouteProps> = [
    { 
        path: 'details', 
        title: '{{Generic_Auth_Cv_details_title_Tab}}',
        element: <DetailsContainer />
    }
]