import { AppRouteProps } from "@mzara/component";
import React from "react";

const AdvertContainer = React.lazy(() => import("./container/AdvertContainer"));

export const AdvertRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <AdvertContainer />,
    },
];
