import { ControlListProps, useRuntimeEnvRolesIncludes, useTranslations } from "@mzara/component";
import moment from "moment";
import { AppointmentVacationBalance } from "../components/AppointmentVacationBalance";

export const useAppointmentVacationControlForm = (): ControlListProps => {
    const [
        SAVE,
        CANCEL,
        INSUFFICIENT_LEAVE_BALANCE,
        DATE_BEGIN, 
        DATE_END, 
        HOUR_BEGIN, 
        HOUR_END, 
        REASON,
        USER,
        BALANCE
    ] = useTranslations(i18n);
    const roleInclude = useRuntimeEnvRolesIncludes()

    return {
        data: {
            forms: [
                {
                    type: "hidden",
                    name: "type",
                },
                {
                    type: "solde",
                    name: "solde",
                    label: BALANCE
                },
                roleInclude('APPOINTMENT_ABSCENCE') ? {
                    type: "select",
                    name: "user",
                    label: USER,
                    valueObject: true,
                    valueProperty: 'id',
                    required: true,
                    GQLProps: {
                        gql: `
                            query {
                                contracts (filter:{ sort: [{ sort: "ASC", value: "user.fullName" }] }) {
                                    data{
                                        user {
                                            id fullName 
                                            profilePicture { id }
                                        }
                                    }
                                }
                            }
                        `,
                        rootProp: "contracts.data",
                        labelProp: "user.fullName",
                        valueProp: "user.id",
                        userIdProp: "user.id",
                    },
                } : undefined,
                {
                    type: "date-picker",
                    name: "dateBegin",
                    label: DATE_BEGIN,
                    required: true,
                    minDate: moment().toDate(),
                },
                {
                    type: "hour",
                    name: "hourBegin",
                    interval: 60,
                    label: HOUR_BEGIN,
                },
                {
                    type: "date-picker",
                    name: "dateEnd",
                    label: DATE_END,
                    required: true,
                    minDate: moment().toDate(),
                },
                {
                    type: "hour",
                    name: "hourEnd",
                    interval: 60,
                    label: HOUR_END,
                },
                {
                    type: "textarea",
                    name: "description",
                    label: REASON,
                    required: true,
                },
            ],
            value: {},
            next: {
                label: SAVE,
                className: "btn-primary",
            },
            back: {
                label: CANCEL,
            },
            graphQL: {
                entity: "appointment",
                mutationConfig: {
                    transformErrorMessage: (e) => {
                        if (e.response?.errors[0].message?.includes("CONGE_NOT_ENOUGH_SOLDE")) {
                            return INSUFFICIENT_LEAVE_BALANCE;
                        }
                        return undefined;
                    },
                },
            },
            fields: {
                "solde": AppointmentVacationBalance
            }
        },
        id: "vacation-dayoff-demand-form",
    };
};

const i18n = [
    "std_add",
    "std_cancel",
    "Harea.Project.DayOff.InsuficientLeaveBalance.Error.Message",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.DateBegin",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.DateEnd",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.HourBegin",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.HourEnd",
    "std_reason",
    'std_user',
    "Harea.project.DayOff.LeaveBalance.Label"
];
