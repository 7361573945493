import { ControlList, useListGraphqlQuery } from "@mzara/component";
import { useAppointmentPlanningControlForm } from "../hooks/useAppointmentPlanningControlForm";
import { useProjectAppointmentCreateDefaultValue } from "views/create/hooks/useProjectAppointmentCreateDefaultValue";
import { useProjectAppointmentNavigateBack } from "views/create/hooks/useProjectAppointmentNavigateBack";
import { useProjectAppointmentEventBeforeSave } from "views/create/hooks/useProjectAppointmentEventBeforeSave";
import { useAppointmentsInvalidateQuery } from "hooks/useAppointmentsInvalidateQuery";

const ProjectAppointmentCreatePlanningContainer = () => {
    const control = useAppointmentPlanningControlForm();
    const defaultValues = useProjectAppointmentCreateDefaultValue()
    const navigateBack = useProjectAppointmentNavigateBack()
    const { invalidateQuery } = useListGraphqlQuery('Project.Planning.List.Id')
    const handleBeforeSave = useProjectAppointmentEventBeforeSave()
    const invalidateAppointmentsQuery = useAppointmentsInvalidateQuery()

    const handleSubmited = () => {
        invalidateAppointmentsQuery()
        invalidateQuery()
        navigateBack()
    };

    return (
        <ControlList
                {...control}
                defaultValue={{
                    ...defaultValues,
                    type: "PLANNING",
                }}
                onCancel={handleSubmited}
                onBeforeSaving={handleBeforeSave}
                onSubmited={handleSubmited}
            />
    );
};

export default ProjectAppointmentCreatePlanningContainer;

