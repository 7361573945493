import { GraphQLQueryClass } from "@mzara/component";
import { TMindMap } from "@mzara/graphql";

export class MindMapInvitationsQuery implements GraphQLQueryClass<TMindMap, TResponse> {
    queryKey: string = "Harea.MindMapInvitationsQuery.Query";

    gql: string = `
        query MindMapInvitationsQuery($id: Float) {
            mindMap(filter: { id: $id }) {
                isEditable
                createdAt
                userCreator {
                    id fullName
                }
                invitations {
                    id ke email createdAt isRevocked isValidated actions
                    user { id fullName}
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TMindMap {
        return data.mindMap;
    }
}

type TResponse = {
    mindMap: TMindMap;
};
