import { Avatar, DateSpan, Metadata, useTranslationRoute, useTranslations, useUrlParamsValueToString, useUserProfilePicture } from "@mzara/component";
import { TAppointment } from "@mzara/graphql";
import { VacationDates } from "components/VacationDates";
import { Link } from "react-router-dom";

export const MissingListItem = (props: VacationUserDetailsItemsProps) => {

    const [
        DAYS,
        NEW,
        CREATED_AT
    ] = useTranslations(i18n);
    const userProfilePicture = useUserProfilePicture();
    const tr = useTranslationRoute();
    const urlParamsToString = useUrlParamsValueToString()

    return (
        <>
            <div className="w-full flex flex-col gap-2 border-b border-default py-5">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-5">
                        <Avatar
                            size="md"
                            src={userProfilePicture(props.user?.id)}
                            fullName={props.user?.fullName}
                        />
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-2">
                                <Link to={tr(`admin/project/dayoff/missing/${props.id}?${urlParamsToString}`)}>
                                    <h3 className="text-base font-semibold">
                                        {props.user?.fullName}
                                    </h3>
                                </Link>
                                <VacationDates {...props} />
                            </div>
                            <div className="flex gap-4">
                                <Metadata label={CREATED_AT} value={<DateSpan value={props.createdAt} />} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};
export type VacationUserDetailsItemsProps = Partial<TAppointment>;

const i18n = [
    'std_days',
    'std_new',
    'std_created_at'
];
