import { GenericSuspense, MainLogo, RightSidebar } from "@mzara/component";
import { useEffect, useState } from "react";
import RecruitingEvaluationDetails from "../components/RecruitingEvaluationDetails";

const ViewerContainer = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true);
        }, 50);
    }, []);

    return (
        <RightSidebar
            className={`right-sidebar-absolute overflow-x-hidden ${
                open ? "" : "hide"
            }`}
        >
            <GenericSuspense fallback={<MainLogo className="absolute" />}>
                <RecruitingEvaluationDetails />
            </GenericSuspense>
        </RightSidebar>
    );
};

export default ViewerContainer;
