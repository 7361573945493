import { MenuItemProps, useTranslations, useUrlParamsValueToString } from "@mzara/component"
import { useMemo } from "react"
import { useKanbanDetailsTab } from "../../../hooks/useKanbanDetailsTab"

export const useAppointmentBoardViewerMenu = (id: number) => {

    const [
        EDIT,
        INVITATIONS,
        DANGER,
        ARCHIVED
    ] = useTranslations(i18n)

    const tab = useKanbanDetailsTab()
    const urlParamsToString = useUrlParamsValueToString()

    return useMemo((): Array<MenuItemProps> => ([
        {
            ke: 'edit',
            label: EDIT,
            link: `edit?${urlParamsToString}`,
        },
        {
            ke: 'invitation',
            label: INVITATIONS,
            link: `invitation?${urlParamsToString}`,
        },
        {
            ke: 'archived',
            label: ARCHIVED,
            link: `archived?${urlParamsToString}`,
        },
        {
            ke: 'danger',
            label: DANGER,
            link: `danger?${urlParamsToString}`,
        },
    ]), [INVITATIONS, tab, urlParamsToString])
}

const i18n = [
    "Harea.Project.Kanban.Viewer.Menu.Edition.Title",
    "Harea.Project.Kanban.Viewer.Menu.Invitation.Title",
    "Harea.Project.Kanban.Viewer.Menu.Danger.Title",
    "Harea.Project.Kanban.Viewer.Menu.Archived.Title",
];
