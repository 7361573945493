import { AppointmentDetails } from "components/AppointmentDetails"
import { useAppointmentQuery } from "hooks/useAppointmentQuery";
import { useParams } from "react-router-dom";
import { AppointmentDetailsMenu } from "./AppointmentDetailsMenu";
import { useCallback } from "react";
import { useAppointmentsInvalidateQuery } from "hooks/useAppointmentsInvalidateQuery";
import { AppointmentDetailsToolbox } from "./AppointmentDetailsToolbox";

export const AppointmentDetailsLoader = (props: Props) => {

    const { id } = useParams()
    const { data } = useAppointmentQuery(parseInt(id), true);
    const invalidateAppointmentsQuery = useAppointmentsInvalidateQuery()

    const handleDeleted = useCallback(() => {
        invalidateAppointmentsQuery()
        props.onDeleted?.()
    }, [props])

    return (
        <AppointmentDetails
            {...data}
            toolbox={(
                <AppointmentDetailsToolbox
                    {...data}
                    onDeleted={handleDeleted}
                />
            )}
        />
    )
}

type Props = {
    onDeleted?: () => void
}
