import { AppRouteProps } from '@mzara/component'
import MindMapDetails from 'containers/MindMapDetails'
import React from 'react'
import { MindMapDetailsViewRoutes } from 'view/MindMapDetailsViewRoutes'

const MindMap = React.lazy(() => import('./containers/MindMap'))
const MindMapEdit = React.lazy(() => import('./containers/MindMapEdit'))

export const MindMapRoutes: Array<AppRouteProps> = [
    {
        path: 'mind-map',
        title: 'Harea.MindMap.title',
        element: <MindMap />,
        children: [
            {
                path: 'create',
                title: 'Harea.MindMap.Create.title',
                element: <MindMapEdit />,

            }
        ]
    },
    {
        path: 'mind-map/:id',
        title: 'Harea.MindMap.title',
        element: <MindMapDetails />,
        children: [
            ...MindMapDetailsViewRoutes
        ],

    },
]
