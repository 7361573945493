import { GraphQLQueryClass } from "@mzara/component";
import { TProductLicense } from "@mzara/graphql";

export class LicenseRolesQuery
    implements GraphQLQueryClass<TProductLicense, TResponse>
{
    queryKey: string = "Premium.Roles.Query";

    gql: string = `
        query($data: JSONObject) {
            productLicense(filter: { data: $data }) {
                roles {
                    id
                    value
                    tp
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TProductLicense {
        return data.productLicense;
    }
}

type TResponse = {
    productLicense: TProductLicense;
};
