import { GraphQLQueryClass } from "@mzara/component";
import { TAppointment, TAppointmentMilestone, TAppointmentMilestonesResponse } from "@mzara/graphql";

export class KanbanMilestonesQuery implements GraphQLQueryClass<TAppointmentMilestonesResponse, TResponse>{
    public queryKey: string = 'App.harea.KanbanMilestonesQuery';
    public gql: string = `
        query KanbanMilestonesQuery($data: JSONObject){
            appointmentMilestones(filter:{ data: $data }){
                total 
                data {
                    id
                    updatedAt
                    title
                    description
                    labels { id value color }
                    dateBegin
                    dateEnd
                    color
                    isEditable
                    isDeletable
                    ticketsCount
                    openTicketsCount
                    closedTicketsCount
                    progress
                }
            }
        }
    `;
    variables?: any;
    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TAppointmentMilestonesResponse {
        return data.appointmentMilestones
    };
}

export type TResponse = {
    appointmentMilestones: TAppointmentMilestonesResponse
}