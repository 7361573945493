import { GenericSuspense, useRuntimeEnv, useTranslationRoute } from "@mzara/component";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const OrganisationAccessLayer = () => {
    const { runtimeEnv } = useRuntimeEnv();
    const isAuthenticated = runtimeEnv?.authenticated;
    const routeIsAdmin = /(admin)/.test(location.pathname);
    const routeIsClient = !routeIsAdmin
    const organisation = runtimeEnv?.organisation;
    const navigate = useNavigate();
    const t = useTranslationRoute();

    useEffect(() => {
        isAuthenticated && organisation !== null && !routeIsAdmin && navigate(t("admin"));
        isAuthenticated && organisation === null && !routeIsClient && navigate(t(""));
    }, []);

    return (
        <>
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </>
    );
};
export default OrganisationAccessLayer;
