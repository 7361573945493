import { AppRouteProps } from "@mzara/component";
import { AboutPublicationDetailsRoutes } from "views/admin/views/publication-details/views/about/AboutPublicationDetailsRoutes";
import { CvListPublicationRoutes } from "views/admin/views/publication-details/views/cv/CvListPublicationRoutes";
import { DiscussionRoutes } from "views/admin/views/publication-details/views/discussion/DiscussionRoutes";
import { EvaluationsRoutes } from "views/admin/views/publication-details/views/evaluations/EvaluationsRoutes";
import { AdvertParametersRoutes } from "views/admin/views/publication-details/views/parameters/AdvertParametersRoutes";
import PublicationViewerContainer from "./containers/PublicationViewerContainer";

export const PublicationViewerRoutes: Array<AppRouteProps> = [
    {
        path: "viewer/:id",
        title: "Harea.Recruiting.Publication.Viewer.title",
        element: <PublicationViewerContainer />,
        children: [
            ...AboutPublicationDetailsRoutes,
            ...CvListPublicationRoutes,
            ...EvaluationsRoutes,
            ...AdvertParametersRoutes,
            ...DiscussionRoutes,
        ],
    },
];
