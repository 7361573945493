import { GraphQLQueryClass } from "@mzara/component";
import { TRecruitingAdvert } from "@mzara/graphql";

export class PublicationViewerQuery
    implements GraphQLQueryClass<TRecruitingAdvert, TResponse>
{
    public queryKey: string = "Harea.Recruiting.Advert.Viewer.Query";
    public gql: string = `
        query publicationViewerQuery($id: Float){
            recruitingAdvert(filter: {id: $id}){
                id
                title
            }
        }
    `;
    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TRecruitingAdvert {
        return data.recruitingAdvert;
    }
}

export type TResponse = {
    recruitingAdvert: TRecruitingAdvert;
};
