import { AppRouteProps } from "@mzara/component";
import EvaluationContainer from "./container/EvaluationContainer";

export const EvaluationRoutes: Array<AppRouteProps> = [
    {
        path: ":ke",
        title: "Client.Evaluation.Step",
        element: <EvaluationContainer />,
    },
];
