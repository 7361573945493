import { Box, Button, Metadata, useFileUrl } from "@mzara/component";
import { TFormation } from "@mzara/graphql";
import moment from "moment";

const FormationDetails = ({ data }: FormationDetailsProps) => {
    const getMiniatureUrlById = useFileUrl();

    return (
        <Box className="max-w-2xl flex flex-col gap-5">
            <div>
                <h2 className="text-2xl mb-3">{data?.title}</h2>

                <div className="flex items-center gap-20">
                    <Metadata
                        icon="fa-clock"
                        value={moment(+data?.createdAt).format("DD/MM/YYYY")}
                    />

                    <Metadata icon="fa-eye" value={`${12} vues`} />
                </div>
            </div>

            <p>{data?.description}</p>

            {data?.videoLink && (
                <iframe
                    className="w-full rounded-xl"
                    src={data?.videoLink}
                ></iframe>
            )}

            <div className="flex flex-col gap-2">
                <h3>Les bases de Microsoft Word</h3>

                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Reprehenderit sapiente, ullam pariatur necessitatibus modi
                    quaerat quod minus, delectus laudantium odit quidem omnis
                    consectetur, inventore laboriosam obcaecati quasi nostrum
                    sit beatae?
                </p>

                {data?.miniature && (
                    <figure className="flex flex-col">
                        <img
                            className="rounded-xl"
                            src={getMiniatureUrlById(data?.miniature?.id)}
                            alt={data?.miniature?.name}
                        />

                        <figcaption className="text-right">
                            {data?.miniature?.name}
                        </figcaption>
                    </figure>
                )}
            </div>

            <div className="mx-auto">
                <Button
                    className="btn-primary"
                    startIcon="fa-circle-check"
                    label="J'ai terminé"
                />
            </div>
        </Box>
    );
};

export default FormationDetails;

interface FormationDetailsProps {
    data?: TFormation;
}
