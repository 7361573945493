import { useAjax, useMzMutation, useTranslation } from "@mzara/component";
import { AjaxResponseType } from "@mzara/lib";

export const useSaveStepResponseMutation = () => {
    const t = useTranslation();
    const ajax = useAjax();

    return useMzMutation<AjaxResponseType>(
        (payload: Record<string, any>) =>
            ajax.send({
                type: "PUT",
                content_type: "application/json",
                url: "evaluation/step",
                data: payload,
            }),
        {
            transformErrorMessage: (error) => {
                if (error.xhr === 403) {
                    return t(
                        "Harea.Evaluation.Forbidden.Resubmit.Form.Error.Message"
                    );
                }

                return undefined;
            },
        }
    );
};
