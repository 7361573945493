import { AppRouteProps } from "@mzara/component";
import React from "react";
import { PayslipDetailsRoute } from "view/details/PayslipDetailsRoute";
import { PayslipPastMonthRoute } from "view/past-month/PayslipPastMonthRoute";
import { SocialStatementRoute } from "view/social-statement/SocialStatementRoute";
import { PayslipThisMonthRoute } from "view/this-month/PayslipThisMonthRoute";

const PayslipContainer = React.lazy(() => import("container/PayslipContainer"));
export const PayslipRoutes = [
    {
        path: "payslips",
        element: <PayslipContainer />,
        title: "Harea.Payslip.Title",
        children: [...PayslipThisMonthRoute, ...PayslipPastMonthRoute, ...SocialStatementRoute],
    },
    ...PayslipDetailsRoute,
] as Array<AppRouteProps>;
