import { ControlListProps, useTranslations } from "@mzara/component";

export const useAddChapterForm = (): ControlListProps => {
    const [TITLE, CANCEL, SAVE] = useTranslations(i18n);

    return {
        id: "formation-details-chapter-add-form",
        data: {
            forms: [{ type: "text", name: "title", label: TITLE }],
            back: { label: CANCEL },
            next: { label: SAVE },
        },
    };
};

const i18n = ["std_title", "std_cancel", "std_save"];
