import {
    MenuItemProps,
    useTranslation,
    useTranslationRoute,
    useUrlParamsValueToString,
} from "@mzara/component";
import { useParams } from "react-router-dom";

export const useAdvertViewerMenuItems = (): Array<MenuItemProps> => {
    const { id } = useParams();

    const tr = useTranslationRoute();
    const t = useTranslation();
    const urlParamsToString = useUrlParamsValueToString()
    return [
        {
            ke: "about",
            label: t("std_about"),
            link: tr(`admin/recruiting/viewer/${id}/about?${urlParamsToString}`),
            "data-testid": `recruiting-advert-details-menu-about`,
            className: "text-clip whitespace-nowrap",
        },
        {
            ke: "cv",
            label: t("std_applications"),
            link: tr(`admin/recruiting/viewer/${id}/cv?${urlParamsToString}`),
            "data-testid": `recruiting-advert-details-menu-cv`,
            className: "text-clip",
        },
        {
            ke: "evaluations",
            label: t("std_assessment"),
            link: tr(`admin/recruiting/viewer/${id}/evaluations?${urlParamsToString}`),
            "data-testid": `recruiting-advert-details-menu-evaluations`,
            className: "text-clip",
        },
        {
            ke: "discussion",
            label: t("std_discussion"),
            link: tr(`admin/recruiting/viewer/${id}/discussion?${urlParamsToString}`),
            "data-testid": `recruiting-advert-details-menu-discussion`,
            className: "text-clip",
        },
    ];
};
