import { AppRouteProps } from "@mzara/component";
import ChaptersContainer from "./container/ChaptersContainer";

export const ChaptersRoutes: Array<AppRouteProps> = [
    {
        path: "chapters",
        title: "Harea.Formation.Details.Chapters.Title",
        element: <ChaptersContainer />,
    },
];
