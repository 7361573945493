import { CurrentBreadCrumb, GenericSuspense, Menu } from "@mzara/component";
import { useContractTemplateDetailsQuery } from "../hooks/useContractTemplateDetailsQuery";
import { Outlet, useParams } from "react-router-dom";
import { useContractTemplateDetailsMenuItems } from "../hooks/useContractTemplateDetailsMenuItems";

const ContractTemplateDetails = () => {
    const items = useContractTemplateDetailsMenuItems();
    const { id } = useParams();
    const { data } = useContractTemplateDetailsQuery(parseInt(id), true);
    const [, , , , , , , tab] = location.pathname.split("/");

    return (
        <section className="flex flex-col gap-4 py-1 list flex-1 max-w-2xl">
            <h4 className="font-semibold">{data?.designation}</h4>
            <CurrentBreadCrumb />
            <Menu items={items} type="HORIZONTAL" className="menuAppointment" activeFn={(menu) => menu.ke === tab} />
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </section>
    );
};
export default ContractTemplateDetails;
