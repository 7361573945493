import { Box, Step, useTranslation } from "@mzara/component";
import { useParams } from "react-router-dom";
import { useFormationDetailsQuery } from "../../../../../../../hooks/useFormationDetailsQuery";
import { useFormationDetailsSteps } from "../hooks/useFormationDetailsSteps";

const ParametersContainer = () => {
    const { id } = useParams();

    const t = useTranslation();

    const { invalidateQuery } = useFormationDetailsQuery(+id, true);
    const steps = useFormationDetailsSteps(parseInt(id));

    return (
        <Box title={t("std_params")}>
            <Step
                defaultValue={[{ id: parseInt(id) }]}
                steps={steps}
                layout="MENU"
                onStepSubmited={() => invalidateQuery()}
            />
        </Box>
    );
};

export default ParametersContainer;
