import { AppRouteProps } from "@mzara/component";
import React from "react";

const CreatePortfolioContainer = React.lazy(() => import("./container/CreatePortfolioContainer"));

export const CreatePortfolioRoute: Array<AppRouteProps> = [
    {
        path: "create",
        element: <CreatePortfolioContainer />,
    },
    {
        path: 'update/:id',
        element: <CreatePortfolioContainer />
    }
];
