import { useEffect } from "react"
import { useAppointmentBoardQuery } from "./useAppointmentBoardQuery"
import { useKanbanColumnsRowsSort } from "../views/board/hooks/useKanbanColumnsRowsSort"
import _ from "lodash"
import { KanbanCards } from "../state/KanbanCards"
import { useRecoilState } from "recoil"

export const useKanbanCards = (id: string) => {

    const { data: board } = useAppointmentBoardQuery(parseInt(id))
    const sortCards = useKanbanColumnsRowsSort()
    const [cards, setCards] = useRecoilState(KanbanCards(id))

    useEffect(() => {
        if (board) {
            const cards = _.cloneDeep(
                sortCards( board?.appointments ?? [])
            )
            setCards(cards)
        }
        
    }, [board])

    return cards
}
