import { Box, DateSpan, useFileUrl } from "@mzara/component";
import { TFile } from "@mzara/graphql";
import { useMemo } from "react";

export const FileItem = (props: Partial<TFile>) => {

    const extension = useMemo(() => props.name.split('.').pop(), [props])
    const fileUrl = useFileUrl()
    const iconUrl = useMemo(() => {
        if (['jpg', 'jpeg', 'png', 'svg', 'gif'].includes(extension)) {
            return fileUrl(props.id)
        }

        // Todo : handle over extensions
        return null
    }, [extension])

    return (
        <a href={fileUrl(props.id)} target="_blank">
            <Box className="flex gap-2 !bg-default w-60 overflow-hidden">
                {
                    iconUrl &&
                    <img className="w-16 h-10 object-cover" src={iconUrl} />
                }
                <div className="flex flex-col overflow-hidden">
                    <h6 className="whitespace-nowrap text-ellipsis overflow-hidden font-bold" title={props.name}>{props.name}</h6>

                    <DateSpan value={props.createdAt} />
                </div>
            </Box>
        </a>
    )
}
