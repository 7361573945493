import { Dialog, GenericSuspense, Menu, useTranslations, useUrlParamsValueToString } from "@mzara/component";
import { useCallback, useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useProjectAppointementCreateTabs } from "../hooks/useProjectAppointementCreateTabs";

const ProjectAppointmentCreateContainer = () => {

    const [TITLE] = useTranslations(i18n);
    const menus = useProjectAppointementCreateTabs();
    const tab = location.pathname.split("/").at(-1);

    const navigate = useNavigate()
    const urlParamsToString = useUrlParamsValueToString()

    useEffect(() => {
        if (!menus?.some((item) => item.ke === tab)) {
            navigate(menus[0].link, { replace: true });
        }
    }, []);

    
    const handleClose = useCallback(() => {
        navigate(`..?${urlParamsToString}`)
    }, [urlParamsToString])

    return (
        <Dialog open title={TITLE} onClose={handleClose} className="">
            <Menu
                items={menus}
                type="HORIZONTAL"
                activeFn={(menu) => menu.ke === tab}
                className="menu-tabs"
            />
            
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </Dialog>
    );
};
export default ProjectAppointmentCreateContainer;

const i18n = ["Harea.Vacation.Calendar.Event.Create.Dialog.Title"];

const MENU_MAP = {
    NORMAL: 0,
    VACATION: 1,
    PERMISSION: 2,
    ABSENCE: 3,
    HOUR_SUPP: 4,
};
