import {
    BreadCrumbItemProps,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";

export const useFormationBreadcrumb = (): Array<BreadCrumbItemProps> => {
    const [HOME, FORMATIONS] = useTranslations(i18n);
    const tr = useTranslationRoute();

    return [
        { label: HOME, link: tr("admin") },
        { label: FORMATIONS, link: tr("admin/formation") },
    ];
};

const i18n = ["std_home", "Harea.Formation.List.Page.Title"];
