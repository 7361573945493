import {
    MenuItemProps,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";
import { useMemo } from "react";

export const useEvaluationDetailsMenu = (id: string) => {
    const translateRoute = useTranslationRoute();
    const [DETAILS, PARAMETERS] = useTranslations(i18n);

    return useMemo(
        (): Array<MenuItemProps> => [
            {
                ke: "details",
                label: DETAILS,
                "data-testid": "evaluation-details-menu-details",
                link: translateRoute(`admin/evaluation/${id}/details`),
            },
            {
                ke: "parameters",
                label: PARAMETERS,
                "data-testid": "evaluation-details-menu-parameters",
                link: translateRoute(`admin/evaluation/${id}/parameters`),
            },
        ],
        []
    );

};

const i18n = ["std_details", "std_setting"];
