import { AppRouteProps } from "@mzara/component";
import DiscussionContainer from "./container/DiscussionContainer";

export const DiscussionRoutes: Array<AppRouteProps> = [
    {
        path: "discussion",
        title: "Harea.Recruiting.Publicattion.Discussion.Title",
        element: <DiscussionContainer />,
    },
];
