import { SortBarItemProps, useTranslations } from "@mzara/component"

export const useMissingListSort = (): Array<SortBarItemProps> => {
    const [
        NAME,
        DATE,
    ] = useTranslations(i18n)
    return [
        { label: NAME, value: 'user.fullName' },
        { label: DATE, className:"ml-auto", value: 'dateBegin' },
    ]
}

const i18n = [
    'std_name',
    'std_date'
]
