import { ControlListProps, useTranslations } from "@mzara/component";
import moment from "moment";
import { useState } from "react";
import { RepetitionControl } from "views/create/views/event/components/RepetitionControl";
import { useAppointmentOwnerQuery } from "views/create/views/event/hooks/useAppointmentOwnerQuery";
import { v4 as uuid } from "uuid";

export const useAppointmentPlanningControlForm = (
    id?: number,
    notEditing?: boolean
): ControlListProps => {
    const [isRSVP, setIsRSVP] = useState(false);
    const { data } = useAppointmentOwnerQuery(id, true);
    const [
        WORK_EXTENSION_LABEL,
        WORK_EXTENSION_TOOLTIP,
        TITLE,
        DESCRIPTION,
        INVITATIONS,
        HOUR_BEGIN,
        HOUR_END,
        REPETITION,
        END_PLANNING,
        ADD,
        SAVE
    ] = useTranslations(i18n);

    return {
        data: {
            forms: [
                {
                    type: "hidden",
                    name: "id"
                },
                {
                    type: "hidden",
                    name: "type",
                },
                {
                    type: "toggle",
                    label: WORK_EXTENSION_LABEL,
                    name: "isWorkExtension",
                    tooltip: WORK_EXTENSION_TOOLTIP,
                },
                {
                    type: "text",
                    name: "title",
                    label: TITLE,
                    required: true,
                },
                {
                    type: "markdown",
                    name: "description",
                    label: DESCRIPTION,
                },
                {
                    type: "select",
                    multiple: true,
                    name: "invitations",
                    label: INVITATIONS,
                    GQLProps: {
                        gql: `
                            query {
                                users (filter:{ sort: [{ sort: "ASC", value: "fullName" }] }) {
                                    data{
                                        id fullName profilePicture { id }
                                    }
                                }
                            }
                        `,
                        rootProp: "users.data",
                        labelProp: "fullName",
                        valueProp: "id",
                        userIdProp: "id",
                        valuePath: 'user.id'
                    },
                    onSelectedChipOption: (option) => {
                        const invitation = data?.invitations.find((invitation) => invitation?.user?.id === option.value);
                        if (invitation?.isValidated) {
                            return {...option, endIcon: 'fa-circle-check text-success'}
                        }
                        if (invitation?.isRevocked) {
                            return {...option, endIcon: 'fa-circle-xmark text-danger'}
                        }
                    }
                },
                {
                    type: "date-picker",
                    name: "dateBegin",
                    label: "Date",
                    required: true,
                    minDate: moment().toDate(),
                },
                {
                    type: "hour",
                    name: "hourBegin",
                    label: HOUR_BEGIN,
                },
                {
                    type: "hour",
                    name: "hourEnd",
                    label: HOUR_END,
                },
                {
                    type: "toggle",
                    name: "isRSVP",
                    label: REPETITION,
                },
                ...(isRSVP
                    ? [
                          {
                              type: "repetition-control",
                              name: "repetition",
                              required: true,
                          },
                      ]
                    : []),
                ...(id && notEditing
                    ? [
                          {
                              type: "date-picker",
                              name: "repetitionDateEnd",
                              label: END_PLANNING,
                              minDate: moment().toDate(),
                          },
                      ]
                    : []),
            ],
            fields: {
                "repetition-control": RepetitionControl,
            },
            next: {
                label: id ? SAVE : ADD,
            },
            graphQL: {
                entity: "appointment",
            },
        },
        onChange: (value) => {
            setIsRSVP(!!value["isRSVP"]);
        }
    };
};

const i18n = [
    "Harea.Planning.Edit.Create.Form.IsWorkExtension.Control.Label",
    "Harea.Planning.Edit.Create.Form.IsWorkExtension.Control.Tooltip",
    "std_title",
    "std_description",
    "Harea.Planning.Edit.Create.Form.Invitation.Control.Label",
    "std_hour_begin",
    "std_hour_end",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.Repetition",
    "Harea.Planning.Edit.Create.Form.DateEnd.Control.Label",
    "std_add",
    "std_save"
];
