export const useEvaluationStateColor = () => {
    const color = {
        NEW: "bg-primary",
        IN_PROGRESS: "bg-primary",
        FINISHED: "bg-success",
    };

    return (key: string) => {
        return color[key];
    };
};
