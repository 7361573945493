import {
    MenuItemProps,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";

export const useFormationDetailsMenuItems = (
    formationId: number
): Array<MenuItemProps> => {
    const tr = useTranslationRoute();
    const [ABOUT, MAIN_PAGE, CHAPTER, PREVIEW, PARAMETERS] =
        useTranslations(i18n);

    return [
        {
            ke: "about",
            label: ABOUT,
            link: tr(`admin/formation/${formationId}/about`),
        },
        {
            ke: "main-page",
            label: MAIN_PAGE,
            link: tr(`admin/formation/${formationId}/main-page`),
        },
        {
            ke: "chapters",
            label: CHAPTER,
            link: tr(`admin/formation/${formationId}/chapters`),
        },
        {
            ke: "preview",
            label: PREVIEW,
            link: tr(`admin/formation/${formationId}/preview`),
        },
        {
            ke: "parameters",
            label: PARAMETERS,
            link: tr(`admin/formation/${formationId}/parameters`),
        },
    ];
};

const i18n = [
    "std_about",
    "Harea.Formation.Details.TabMenu.Item.MainPage.Label",
    "Harea.Formation.Details.TabMenu.Item.Chapters.Label",
    "std_overview",
    "std_params",
];
