import { useRecruitingAdvertContentForm } from "./useRecruitingAdvertContentForm";
import { useRecruitingAdvertMetadataForm } from "./useRecruitingAdvertMetadataForm";
import { useRecruitingAdvertPreviewForm } from "./useRecruitingAdvertPreviewForm";
import { useRecruitingAdvertShareForm } from "./useRecruitingAdvertShareForm";

export const useSaveRecruitingAdvertSteps = () => {
    const metadataForm = useRecruitingAdvertMetadataForm();
    const contentForm = useRecruitingAdvertContentForm();
    const shareForm = useRecruitingAdvertShareForm();
    const previewForm = useRecruitingAdvertPreviewForm();

    return [metadataForm, contentForm, shareForm, previewForm];
};
