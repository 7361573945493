import { AppRouteProps } from "@mzara/component";
import PublicationNewContainer from "./containers/PublicationNewContainer";

export const PublicationNewRoutes: Array<AppRouteProps> = [
    {
        path: "create",
        title: "Harea.Recruiting.Advert.New.Page.Title",
        element: <PublicationNewContainer />,
        roles: ["RECRUTING_ADVERT"]
    },
];
