import { Alert, Button, GenericSuspense, List, useTranslations, useUrlParamsValueToString } from "@mzara/component";
import { TAppointment } from "@mzara/graphql";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useMissingListSort } from "../hooks/useMissingListSort";
import { MissingListFilter } from "../components/MissingListFilter";
import _ from "lodash";
import { MissingListQuery } from "views/dayoff/queries/MissingListQuery";
import { MissingListItem } from "../components/MissingListItem";

const ProjectDayOffMissingContainer = () => {
    const [
        ADD,
    ] = useTranslations(i18n);
    const sortItems = useMissingListSort()
    const urlParamsToString = useUrlParamsValueToString()


    return (
        <>
            <div className="space-y-5">
                <div className="flex items-center justify-end">

                    <Link to={`create/missing?${urlParamsToString}`}>
                        <Button
                            className="btn btn-primary"
                            startIcon="fa-solid fa-plus"
                            label={ADD}
                        />
                    </Link>
                </div>
            </div>
            <MissingListFilter listId={DEMAND_DAYOFF_MISSING_LIST_ID} />

            <List
                id={DEMAND_DAYOFF_MISSING_LIST_ID}
                selection={false}
                sort={{ items: sortItems }}
                GQLRequest={MissingListQuery}
                defaultFilter={{
                    pageSize: 50,
                    page: 0,
                    data: { type: 'ABSENCE' },
                    sort: [{ sort: 'DESC', value: 'dateBegin' }]
                }}
                onRenderRow={(row: TAppointment) => (
                    <MissingListItem {...row} />
                )}
                onEnableRequest={(params) => params?.instance?.variables?.sort !== undefined}
            />
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </>
    );
};

export default ProjectDayOffMissingContainer;

export const DEMAND_DAYOFF_MISSING_LIST_ID = "dayoff-demand-missing-list-id";
const i18n = [
    "std_add",
];
