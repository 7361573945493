import {
    Box,
    ControlList,
    Dialog,
    GenericSuspense,
    Menu,
    Timer,
    useDebounce,
    useTranslation,
    useTranslations,
} from "@mzara/component";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetStepFormControlQuery } from "../hooks/useGetStepFormControlQuery";
import { useSaveStepResponseMutation } from "../hooks/useSaveStepResponseMutation";
import _ from "lodash";
import { useStepTimeHandler } from "../hooks/useStepTimeHandler";

const EvaluationSteps = ({
    stepMenu,
    evaluationKey,
    index,
    onStepFinish,
}: EvaluationStepsProps) => {
    const [stepIndex, setStepIndex] = useState(index);

    const [
        TITLE,
        DESCRIPTION,
        OK,
        CANCEL,
        EMPTY_VALUE_TITLE,
        EMPTY_VALUE_DESCRIPTION
    ] = useTranslations(i18n)

    const t = useTranslation();
    const debounce = useDebounce();
    const [showTimeoutDialog, setShowTimeoutDialog] = useState(false)
    const [showEmptyValueDialog, setShowEmptyValueDialog] = useState(false)

    const { data } = useGetStepFormControlQuery(
        evaluationKey,
        stepIndex,
        stepMenu.length
    );
    const mutation = useSaveStepResponseMutation();

    const form = useMemo(() => {
        return data?.form
    }, [data])

    const defaultValue = useMemo(() => {
        return data?.value
    }, [data])

    const timeStamp = useMemo(() => {
        return data?.timeStamps?.[stepIndex]
    }, [data, stepIndex])

    const timeStampTimer = useMemo(() => {
        if (!form?.data?.timer) {
            return undefined
        }

        return moment(timeStamp?.[0], timeStamp?.[0] ? 'x' : undefined).add(form?.data?.timer ?? 0, 'minutes').format('x')
    }, [timeStamp, form])

    const [value, setValue] = useState<Record<string, any>>(defaultValue);
    const saveTimeout = useRef<NodeJS.Timeout>()

    const handleSubmit = (v: Record<string, any>) => {
        if (_.isEmpty(v)) {
            setShowEmptyValueDialog(true)
            return;
        }
        save(v)
    }

    const handleEmptyValueConfirmed = () => {
        save(value)
    }

    const handleWindowEvent = (e) => {
        save({}, false, moment().format('x'));
    };

    useEffect(() => {
        window.addEventListener('focus', handleWindowEvent);
        window.addEventListener('blur', handleWindowEvent);

        return () => {
            window.removeEventListener('focus', handleWindowEvent);
            window.removeEventListener('blur', handleWindowEvent);
        };
    }, [stepIndex]);

    const save = (v: Record<string, any>, isStepForward?: boolean, outTime?: string) => {
        mutation.mutate({
            ke: evaluationKey,
            stepIndex,
            value: v ?? {},
            timeStamp: moment().format('x'),
            outTime: outTime
        }, {
            onSuccess: () => {
                if (isStepForward !== false) {
                    setStepIndex((prevIndex) => prevIndex + 1);
                    setShowTimeoutDialog(false)
                }

                mutation.reset()
            }
        });
    };

    useEffect(() => {
        clearTimeout(saveTimeout.current)
        saveTimeout.current = setTimeout(() => {
            save(value, false)
        }, TIME_INTERVAL)
    }, [value]);

    const _stepMenu = useMemo(() => {
        return stepMenu.map((menu, i) =>
            i < stepIndex
                ? { ...menu, endIcon: "fa-solid fa-check" }
                : menu
        )
    }, [stepMenu, stepIndex])

    const handleTimeReached = useCallback(() => {
        setShowTimeoutDialog(true)
    }, [])

    const handleTimeoutConfirmed = useCallback(() => {
        save(value)
    }, [value, stepIndex])

    useEffect(() => {
        if (stepIndex === stepMenu.length) {
            clearTimeout(saveTimeout.current)
            onStepFinish();
            return;
        }
        save(value, false)
    }, [stepIndex])

    useStepTimeHandler({
        dateBegin: timeStamp?.[0]?.toString(),
        timer: form?.data?.timer,
        onTimeReached: handleTimeReached
    })

    return (
        <Box
            className="rounded-md w-full mb-8">
            <div className="flex gap-5">
                <Menu
                    className="flex flex-1 w-full max-w-[240px]"
                    items={_stepMenu}
                    activeFn={(_, index) => index === stepIndex}
                    showIndex
                />
                <div className="flex flex-1 max-w-full overflow-hidden">
                    <GenericSuspense>
                        <ControlList
                            {...{
                                ...form,
                                data: {
                                    ...form?.data,
                                    hideBack: true,
                                    timer: timeStampTimer,
                                    timerDisabled: showTimeoutDialog
                                },
                            }}
                            value={{
                                ...defaultValue,
                                ...value
                            }}
                            onChange={(val) => setValue(val)}
                            onSubmit={handleSubmit}
                            readonly={showTimeoutDialog}
                        />
                    </GenericSuspense>
                </div>
            </div>

            <Dialog
                open={showTimeoutDialog}
                title={TITLE}
                message={DESCRIPTION}
                confirmation={true}
                dismissible={false}
                btnOk={{ label: OK, className: "bg-primary", isSubmit: mutation.isLoading }}
                onConfirm={handleTimeoutConfirmed}
            />

            <Dialog
                open={showEmptyValueDialog}
                title={EMPTY_VALUE_TITLE}
                message={EMPTY_VALUE_DESCRIPTION}
                confirmation={true}
                btnCancel={{ label: CANCEL }}
                btnOk={{ label: OK, className: "bg-primary", isSubmit: mutation.isLoading }}
                onConfirm={handleEmptyValueConfirmed}
                onClose={() => setShowEmptyValueDialog(false)}
                onCancel={() => setShowEmptyValueDialog(false)}
            />
        </Box>
    );
};

const i18n = [
    'Harea.Evaluation.Client.Step.Timeout.Dialog.Title',
    'Harea.Evaluation.Client.Step.Timeout.Dialog.Description',
    'std_ok',
    'std_cancel',
    'Harea.Evaluation.Client.Step.EmptyValue.Dialog.Title',
    'Harea.Evaluation.Client.Step.EmptyValue.Dialog.Description',
]

export default EvaluationSteps;

type EvaluationStepsProps = {
    index: number
    stepMenu?: Array<Record<string, any>>;
    evaluationKey: string;
    onStepFinish: () => void;
};

const TIME_INTERVAL = 5000;

const toKeyString = (text?: string) => {
    return text?.replaceAll(" ", "_").toLowerCase();
};
