import { useAjax, useMzMutation, useTranslation } from "@mzara/component"

export const useAcceptAppointmentInvitationMutation = (ke: string) => {
    const t = useTranslation()
    const ajax = useAjax()
    return useMzMutation(() => ajax.send({
        type: "GET",
        url: `notification/ical/approve/${ke}`,
        content_type: "application/json"
    }), {
        transformErrorMessage: () => {
            return t("std_unknown_error")
        }
    })
}