import { AppRouteProps } from "@mzara/component";
import React from "react";

const EvaluationContainer = React.lazy(() => import('./container/EvaluationContainer'))

export const EvaluationsRoutes : Array<AppRouteProps> = [
    { 
        path: 'evaluations', 
        title: '{{Generic_Auth_Cv_details_title_Tab}}',
        element: <EvaluationContainer/>,
    }
]