import { useCallback } from "react"
import { useProjectGanttOpenedCards } from "./useProjectGanttOpenedCards"

export const useProjectGanttOpenCardsGetByIndex = (id: string) => {

    const openedCards = useProjectGanttOpenedCards(id)

    return useCallback((index: number) => {
        return openedCards[index]
    }, [openedCards])
}
