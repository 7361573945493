import { useGraphqlMutation } from "@mzara/component";

export const useSaveFormationMutation = () => {
    return useGraphqlMutation(gql);
};

const gql = `
    mutation saveFormationMutation($data: JSONObject) {
        saveFormation(data: { data: $data }) {
            id
        }
    }
`;
