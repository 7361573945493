import { Button, Dropdown, Menu, MenuItemProps } from "@mzara/component";
import { useState } from "react";

const DropdownButton = ({
    label,
    menuItems,
    onMenuItemClick,
}: DropdownButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement>();

    return (
        <>
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    setAnchorEl(e.currentTarget);
                }}
            >
                <Button
                    label={label}
                    endIcon={`fa-solid ${
                        anchorEl ? "fa-caret-up" : "fa-caret-down"
                    }`}
                    className="bg-default button-rounded-full text-[#3E3A3A] [&>i]:text-white"
                />
            </a>

            <Dropdown
                anchorEl={anchorEl}
                arrow
                placement="bottom-end"
                onClose={() => setAnchorEl(undefined)}
            >
                <Menu
                    items={menuItems}
                    onClick={(item) => {
                        onMenuItemClick?.(item);
                        setAnchorEl(undefined);
                    }}
                />
            </Dropdown>
        </>
    );
};

export default DropdownButton;

type DropdownButtonProps = {
    label?: string;
    menuItems?: MenuItemProps[];
    onMenuItemClick?: (item: MenuItemProps) => void;
};
