import { useListFilterState } from "@mzara/component";
import { useMemo } from "react";
import DropdownButton from "./DropdownButton";
import { useStateFilterMenuItems } from "../hooks/useStateFilterMenuItems";
import { useAdvertFilterMenuItems } from "../hooks/useAdvertFilterMenuItems";
import { useCVFilterMenuItems } from "../hooks/useCVFilterMenuItems";

const ListFilterDropdownButtonGroups = ({
    listId,
}: ListFilterDropdownButtonGroupsProps) => {
    const { filter, setFilter } = useListFilterState(listId);

    const stateFilterMenuItems = useStateFilterMenuItems();
    const advertFilterMenuItems = useAdvertFilterMenuItems();
    const cvFilterMenuItems = useCVFilterMenuItems();

    const activeMenus = useMemo(() => {
        return {
            state: filter.data?.state
                ? stateFilterMenuItems.find(
                    (item) => item.ke.toUpperCase() === filter.data?.state
                )
                : stateFilterMenuItems[0],
            advert: filter.data?.recruitingAdvert?.id
                ? advertFilterMenuItems.find(
                    (item) => item.id === filter.data?.recruitingAdvert?.id
                )
                : advertFilterMenuItems[0],
            cv: filter.data?.curiculumVitae?.id
                ? cvFilterMenuItems.find(
                    (item) => item.id === filter.data?.curiculumVitae?.id
                )
                : cvFilterMenuItems[0],
        };
    }, [
        filter,
        stateFilterMenuItems,
        advertFilterMenuItems,
        cvFilterMenuItems,
    ]);

    return (
        <div className="flex items-center gap-2">
            <DropdownButton
                label={activeMenus?.cv?.label}
                menuItems={cvFilterMenuItems}
                onMenuItemClick={(menu) => {
                    setFilter((v: any) => ({
                        ...v,
                        data: {
                            ...v.data,
                            curiculumVitae:
                                menu.id !== 0
                                    ? {
                                        id: menu.id,
                                    }
                                    : {},
                        },
                    }));
                }}
            />

            <DropdownButton
                label={activeMenus?.advert?.label}
                menuItems={advertFilterMenuItems}
                onMenuItemClick={(menu) => {
                    setFilter((v: any) => ({
                        ...v,
                        data: {
                            ...v.data,
                            recruitingAdvert:
                                menu.id !== 0
                                    ? {
                                        id: menu.id,
                                    }
                                    : {},
                        },
                    }));
                }}
            />

            <DropdownButton
                label={activeMenus?.state?.label}
                menuItems={stateFilterMenuItems}
                onMenuItemClick={(menu) => {
                    setFilter((v: any) => ({
                        ...v,
                        data: {
                            ...v.data,
                            state:
                                menu.id !== 0
                                    ? menu.ke?.toUpperCase()
                                    : undefined,
                        },
                    }));
                }}
            />
        </div>
    );
};

export default ListFilterDropdownButtonGroups;

type ListFilterDropdownButtonGroupsProps = {
    listId: string;
};
