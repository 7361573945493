import { AppRouteProps } from '@mzara/component'
import React from 'react'

const Home = React.lazy(() => import('./Home'))

export const HomeRoutes: Array<AppRouteProps> = [
    { 
        path: '', 
        element: <Home />,
    }
]
