import { AppRouteProps } from "@mzara/component";
import React from "react";

const ParameterContainer= React.lazy(() => import('./containers/ParameterContainer'))

export const ParametersRoutes : Array<AppRouteProps> = [
    { 
        path: 'parameters', 
        title: '{{EvaluationRecruitingDetailsTab}}',
        element: <ParameterContainer />,
    }
]