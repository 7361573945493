import { useAjax, useMzQuery } from "@mzara/component";
import { TRecruitingAdvert } from "@mzara/graphql";
import { AjaxResponseType } from "@mzara/lib";

export const useGetEvaluationQuery = (ke: string) => {
    const ajax = useAjax();

    const { data, invalidateQuery } = useMzQuery<AjaxResponseType>(
        ["evaluation", ke],
        () => ajax.send({ type: "GET", url: `evaluation/${ke}` })
    );

    return {
        data: data?.response ? JSON.parse(data?.response as string) as EvaluationQueryResponse : undefined,
        invalidateQuery,
    };
};

type EvaluationQueryResponse = {
    id: number,
    name: string,
    email: string,
    state: "NEW" | "IN_PROGRESS" | "FINISHED",
    menu: Array<{
        label: string;
        timer: string;
    }>,
    stepIndex: number,
    totalStep: number,
    timeTotal: number,
    recruitingAdvert: TRecruitingAdvert
}
