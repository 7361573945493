import { Dialog, GenericSuspense, Menu, useTranslations, useUrlParamsValueToString } from "@mzara/component";
import { useCallback, useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ProjectDayOffCreateContainer = () => {

    const [TITLE] = useTranslations(i18n);
    const navigate = useNavigate()
    const urlParamsToString = useUrlParamsValueToString()

    const handleClose = useCallback(() => {
        navigate(`..?${urlParamsToString}`)
    }, [urlParamsToString])

    return (
        <Dialog open title={TITLE} onClose={handleClose} className="">
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </Dialog>
    );
};

export default ProjectDayOffCreateContainer;

const i18n = ["Harea.Project.DayOff.Management.Vacation.Abscence.Label"];
