
import { MenuItemProps, useTranslations, useUrlParamsEncode, useUrlParamsValue, useUrlParamsValueToString } from "@mzara/component"
import { useMemo } from "react"
import { useKanbanMilestoneTab } from "./useKanbanMilestoneTab"

export const useKanbanMilestoneMenu = ({ milestoneId }: Props) => {

    const [
        ABOUT,
        BOARD,
        STATS,
        PARAMS,
        GANTT
    ] = useTranslations(i18n)
    const tab = useKanbanMilestoneTab()
    const filter = useUrlParamsValue()
    const encode = useUrlParamsEncode()
    const urlParamsToString = useUrlParamsValueToString()

    return useMemo((): Array<MenuItemProps> => ([
        {
            ke: 'about',
            label: ABOUT,
            link: `about?${urlParamsToString}`,
        },
        {
            ke: 'board',
            label: BOARD,
            link: `board?${encode({ 
                ...filter, 
                filter: { 
                    ...(filter?.filter ?? {}), 
                    milestone: { id: milestoneId } } 
                })
            }`,
        },
        {
            ke: 'gantt',
            label: GANTT,
            link: `gantt?${encode({ 
                ...filter, 
                filter: { 
                    ...(filter?.filter ?? {}), 
                    milestone: { id: milestoneId } } 
                })
            }`,
        },
        {
            ke: 'stats',
            label: STATS,
            link: `stats?${urlParamsToString}`,
        },
        {
            ke: 'params',
            label: PARAMS,
            link: `params?${urlParamsToString}`,
        },
    ]), [ABOUT, tab, urlParamsToString, milestoneId])
}

type Props = {
    milestoneId?: number
}

const i18n = [
    "Harea.Project.Kanban.Details.Milestone.About.Label",
    "Harea.Project.Kanban.Details.Milestone.Board.Label",
    "Harea.Project.Kanban.Details.Milestone.Stats.Label",
    "Harea.Project.Kanban.Details.Milestone.Params.Label",
    "Harea.Project.Kanban.Details.Milestone.Gantt.Label",
]

