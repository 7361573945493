import { AppRouteProps } from "@mzara/component";
import ProjectKanbanInvitationEditContainer from "./container/ProjectKanbanInvitationEditContainer";

export const ProjectKanbanInvitationEditRoute: Array<AppRouteProps> = [
    {
        path: 'create',
        element: <ProjectKanbanInvitationEditContainer />
    },{
        path: 'edit/:invitationId',
        element: <ProjectKanbanInvitationEditContainer />
    },
]
