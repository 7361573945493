import { AppRouteProps } from "@mzara/component";
import React from "react";

const KanbanCardHistory = React.lazy(() => import("./container/KanbanCardHistory"))

export const ProjectKanbanDetailsHistoryRoutes: Array<AppRouteProps> = [
    {
        path: 'history',
        title: "Harea.Project.Kanban.Details.Menu.History.Title",
        element: <KanbanCardHistory/>,
        roles: ["APPOINTMENT"]
    }
] 