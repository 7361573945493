import { MenuItemProps, useCdnAssets, useTranslations } from "@mzara/component";

export const useLangMenuItems = (): Array<MenuItemProps> => {
    const cdn = useCdnAssets();
    const [FRENCH, ENGLISH] = useTranslations(i18n);
    return [
        {
            ke: "fr",
            label: FRENCH,
            startIcon: <img src={cdn(`/img/flag/24x24/FR.png`)} className="h-4" alt="FR" />
        },
        {
            ke: "en",
            label: ENGLISH,
            startIcon: <img src={cdn(`/img/flag/24x24/GB.png`)} className="h-4" alt="EN" />
        },
    ];
};

const i18n = ["std_french", "std_english"];
