import { BaseControl, Button, IconButton, Input, InputProps, useTranslation } from "@mzara/component";

export const InputGoogleMeet = (props: Props) => {

    return (
        <div className="relative w-full">
            <Input
                {...props}
            />
            {
                !props.readonly &&
                <div className="absolute right-0 top-0 bottom-0 flex items-end ">
                    <IconButton
                        icon={<img src="/assets/icon/Google-Meet.svg" alt="google-meet-icon" height={"18"} width={"18"} />}
                    />
                </div>
            }
        </div>
    );
};
type Props = InputProps;
