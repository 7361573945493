import { ControlListProps, useTranslation } from "@mzara/component";
import { useFormationAddForm } from "../../../../formation/views/create/hooks/useFormationAddForm";

export const useFormationEditMetadataForm = (): ControlListProps => {
    const t = useTranslation();

    const form = useFormationAddForm();

    return {
        ...form,
        id: "formation-details-parameters-metadata-form",
        data: {
            ...form.data,
            forms: [...form.data.forms, { type: "hidden", name: "id" }],
            title: t("std_metadata"),
            graphQL: { entity: "formation" },
            hideBack: true,
            next: { label: t("std_save"), endIcon: "fa-save" },
        },
    };
};
