import { AppRouteProps } from "@mzara/component";
import FormationDetailsContainer from "./container/FormationDetailsContainer";
import { AboutRoutes } from "./views/about/AboutRoutes";
import { ChaptersRoutes } from "./views/chapters/ChaptersRoutes";
import { MainPageRoutes } from "./views/main-page/MainPageRoutes";
import { ParametersRoutes } from "./views/parameters/ParametersRoutes";
import { PreviewRoutes } from "./views/preview/PreviewRoutes";

export const FormationDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "Harea.Formation.List.Title",
        element: <FormationDetailsContainer />,
        children: [
            ...AboutRoutes,
            ...MainPageRoutes,
            ...ChaptersRoutes,
            ...PreviewRoutes,
            ...ParametersRoutes,
        ],
        roles: ["FORMATION"]
    },
];
