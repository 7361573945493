import { useCallback, useEffect } from "react"
import { useAppointmentBoardQuery } from "../../../hooks/useAppointmentBoardQuery"
import { useAppointmentSortMutation } from "../../board/hooks/useAppointmentSortMutation"
import { useKanbanCardsMove } from "../../board/hooks/useKanbanCardsMove"
import { DraggableLocation, DropResult } from "react-beautiful-dnd"
import { useProjectGanttOpenCardsGetByIndex } from "./useProjectGanttOpenCardsGetByIndex"

export const useProjectGanttOpenCardsDragEnd = (id: string) => {

    const moveCard = useKanbanCardsMove(id)
    const mutationSort = useAppointmentSortMutation()
    const getCardByIndex = useProjectGanttOpenCardsGetByIndex(id)
    const { invalidateQuery } = useAppointmentBoardQuery(parseInt(id))

    
    useEffect(() => {
        if (mutationSort.isSuccess) {
            invalidateQuery()
            mutationSort.reset()
        }
    }, [mutationSort])
    
    return useCallback((result: DropResult) => {

        // dropped nowhere
        if (!result.destination) {
            return;
        }

        const source: DraggableLocation = result.source;
        const destination: DraggableLocation = result.destination;


        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        const draggedItem = getCardByIndex(source.index)
        const hoverItem = getCardByIndex(destination.index)

        moveCard({
            dragId: draggedItem?.id ?? 0,
            dragDroppableId: source.droppableId,
            hoverId: hoverItem?.id ?? 0,
            hoverDroppableId: destination.droppableId,
            callback: (result) => {
                mutationSort.mutate({
                    id: result.dragId,
                    to: result.hoverId,
                    labels: result.labels,
                    isClosed: result.isClosed
                })
            }
        })


    }, [moveCard, getCardByIndex])
}
