import { GenericSuspense, Menu, useTranslations } from "@mzara/component"
import { useDataMenu } from "../hooks/useDataMenu"
import { Outlet } from "react-router-dom"

export const DataContainer = () => {

    const [
        TITLE
    ] = useTranslations(i18n)
    const menus = useDataMenu()
    const tab = location.pathname.split("/").at(-1);

    return (
        <section className=" main-content">
            <div className="flex items-center justify-between px-3">
                <h4 className="font-semibold">
                    <b>{TITLE}</b>
                </h4>
            </div>

            <Menu
                items={menus}
                type="HORIZONTAL"
                activeFn={(menu) => menu.ke === tab}
                className="menu-tabs"
            />

            <div>
                <GenericSuspense>
                    <Outlet />
                </GenericSuspense>
            </div>
        </section>
    )
}

const i18n = [
    'Harea.Data.Title'
]
