import { BaseControl, BaseControlProps, Chip, MenuItemProps, useTranslations } from "@mzara/component";
import { useMemo } from "react";

export const NotificationBeforeControl = (props: Props) => {
    const [
        TEN_MINUTES,
        FIFTHTEEN_MINUTES,
        THIRTY_MINUTES,
        ONE_HOUR,
        TWO_HOUR,
        SIX_HOUR,
        ONE_DAY,
        TWO_DAY,
    ] = useTranslations(i18n)

    const options = useMemo((): Array<MenuItemProps> => {
        return [
            {
                label: TEN_MINUTES,
                value: 10,
            },
            {
                label: FIFTHTEEN_MINUTES,
                value: 15,
            },
            {
                label: THIRTY_MINUTES,
                value: 30,
            },
            {
                label: ONE_HOUR,
                value: 60,
            },
            {
                label: TWO_HOUR,
                value: 60 * 2,
            },
            {
                label: SIX_HOUR,
                value: 60 * 6,
            },
            {
                label: ONE_DAY,
                value: 60 * 24,
            },
            {
                label: TWO_DAY,
                value: 60 * 24 * 2,
            },
        ]
    }, [])

    return (
        <BaseControl {...props}>
            <div className="flex gap-2 flex-wrap">
                {!props.readonly &&
                    options.map((option, index) => (
                        <Chip
                            key={`rsvp-${index}`}
                            label={option.label}
                            className={`${props.value?.includes(option.value) ? "bg-primary" : "bg-default"}`}
                            onClick={() =>
                                props.onChange(
                                    props.value?.includes(option.value)
                                        ? props.value?.filter(
                                              (item) => item !== option.value
                                          )
                                        : [...props.value, option.value]
                                )
                            }
                        />
                    ))}
                {props.readonly &&
                    options
                        .filter((option) => props.value?.includes(option.value))
                        .map((option, index) => (
                            <Chip
                                key={`rsvp-${index}`}
                                label={option.label}
                                className="bg-primary"
                            />
                        ))}
            </div>
        </BaseControl>
    );
};

const i18n = [
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore.Ten.Minutes",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore.FifthTeen.Minutes",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore.Thirty.Minutes",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore.One.Hour",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore.Two.Hour",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore.Six.Hour",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore.One.Day",
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.NotifyBefore.Two.Day",
]

type Props = BaseControlProps & {
    value?: Array<number>;
    onChange: (value: Props["value"]) => void;
};
