import { AppRouteProps } from "@mzara/component";
import CreateContainer from "./container/CreateContainer";

export const CreateRoutes: Array<AppRouteProps> = [
    {
        path: "create",
        title: "Harea.Evaluation.Recruiting.Create.Title",
        element: <CreateContainer />,
    },
];
