import {
    Box,
    Chip,
    IconButton,
    Metadata,
    useFileUrl,
    useTranslation,
    useTranslationRoute,
} from "@mzara/component";
import { TFormation } from "@mzara/graphql";
import moment from "moment";
import { Link } from "react-router-dom";

const FormationItem = ({ data }: FormationItemProps) => {
    const getFileUrlById = useFileUrl();
    const tr = useTranslationRoute();
    const t = useTranslation();

    return (
        <Box className="w-full my-3 py-5 flex gap-8">
            {data.miniature ? (
                <img
                    src={getFileUrlById(data.miniature?.id)}
                    className="max-w-[125px] rounded-md shadow-xl h-[176px] bg-no-repeat bg-center"
                    alt={`formation-image-${data.miniature?.name}`}
                />
            ) : (
                <div
                    className="rounded-md shadow-xl"
                    style={{ backgroundColor: "#D9D9D9" }}
                >
                    <i className="fa-regular fa-circle-xmark"></i>
                    <span>
                        {t("Harea.Client.Formation.List.NoImage.Item.Message")}
                    </span>
                </div>
            )}

            <div className="flex flex-col gap-8">
                <div className="flex gap-2">
                    <Link to={tr(`formation/${data.id}`)}>
                        <h5 className="font-semibold">{data.title}</h5>
                    </Link>

                    {data.isPublic && (
                        <Chip
                            label="Publique"
                            color="#0007bb"
                            className="text-white font-semibold"
                        />
                    )}
                </div>

                {data.value && <p className="text-sm">{data.value}</p>}

                <div className="flex gap-20">
                    <Metadata
                        icon="fa-clock"
                        value={
                            <span className="text-xs">
                                {moment(+data.createdAt).format("DD/MM/YYYY")}
                            </span>
                        }
                    />

                    <Metadata
                        icon="fa-eye"
                        value={<span className="text-xs">120 vues</span>}
                    />
                </div>
            </div>

            <div className="flex items-center h-full ml-auto">
                <Link to={tr(`formation/${data.id}`)}>
                    <IconButton
                        icon={"fa-solid fa-chevron-right"}
                        className="text-[#6D6D6D]"
                    />
                </Link>
            </div>
        </Box>
    );
};

export default FormationItem;

type FormationItemProps = {
    data: TFormation;
};
