import { AppRouteProps } from "@mzara/component";
import React from "react";
import { AboutUserDetailsRoutes } from "./views/about/AboutUserDetailsRoutes";
import { DevicesUserDetailsRoutes } from "./views/devices/DevicesUserDetailsRoutes";
import { ParamsUserDetailsRoutes } from "./views/params/ParamsUserDetailsRoutes";
import { UserAgendaRoute } from "./views/agenda/UserAgendaRoute";
import { ContractUserDetailsRoutes } from "./views/contract/ContractUserDetailsRoutes";

const UserDetailsContainer = React.lazy(() => import('./containers/UserDetailsContainer'))
export const UserDetailsRoutes: Array<AppRouteProps> = [
    {
        path: 'user/:id',
        title: '{{UserNameFull}}',
        element: <UserDetailsContainer />,
        children: [
            ...AboutUserDetailsRoutes,
            ...UserAgendaRoute,
            ...ParamsUserDetailsRoutes,
            ...DevicesUserDetailsRoutes,
            ...ContractUserDetailsRoutes
        ]
    }
]