import { RecruitingAdminRoutes, RecruitingClientRoutes } from "@harea/recruiting";
import { EvaluationAdminRoutes, EvaluationClientRoutes } from "@harea/evaluation";
import { FormationAdminRoutes, FormationClientRoutes } from "@harea/formation";
import { AppRouteProps } from "@mzara/component";
import { AuthRoutes } from "@mzara/login";
import { UserDetailsRoutes, UserManagementRoutes } from "@mzara/user-management";
import { ProjectRoutes } from "@mzara/project";
import AdminLayout from "components/layout/AdminLayout";
import AuthLayout from "components/layout/AuthLayout";
import ClientLayout from "components/layout/ClientLayout";
import OrganisationAccessLayer from "container/OrganisationAccessLayer";
import HareaContainer from "container/HareaContainer";
import { UserAuthRoutesTest } from "./auth/UserAuthRoutes";
import { HomeRoutes } from "./home/HomeRoutes";
import { ContractRoutes } from "@mzara/contract";
import { PortfolioRoutes } from "@mzara/portfolio";
import { PayslipRoutes } from "@mzara/payslip";
import { NotificationRoutes } from "@mzara/notification";
import { MindMapRoutes } from "@mzara/mind-map";
import { StaticPageRoutes } from "@mzara/static-page";
import NotFound from "../components/NotFound";
import EvaluationLayout from "components/layout/EvaluationLayout";
import { DataExports } from "./data/DataRoutes";
import {PublicStaticPageContainer} from "@mzara/static-page";

export const HareaRoutes: Array<AppRouteProps> = [
    {
        path: ":lang",
        title: `std_home`,
        element: <HareaContainer />,
        children: [
            {
                path: "",
                element: <OrganisationAccessLayer />,
                children: [
                    {
                        path: "admin",
                        element: <AdminLayout />,
                        children: [
                            ...HomeRoutes,
                            ...RecruitingAdminRoutes,
                            ...ProjectRoutes,
                            ...ContractRoutes,
                            ...EvaluationAdminRoutes,
                            ...PayslipRoutes,
                            ...NotificationRoutes,
                            ...FormationAdminRoutes,
                            ...UserManagementRoutes,
                            ...MindMapRoutes,
                            ...PortfolioRoutes,
                            ...StaticPageRoutes,
                            ...DataExports
                        ],
                    },
                    {
                        path: "",
                        element: <ClientLayout />,
                        children: [
                            ...RecruitingClientRoutes,
                            ...FormationClientRoutes,
                            ...UserDetailsRoutes,
                        ],
                    },
                ],
            },
            {
                path: "",
                element: <EvaluationLayout />,
                children: [
                    ...EvaluationClientRoutes,
                ]
            },
            {
                path: "",
                element: <AuthLayout />,
                children: [
                    ...UserAuthRoutesTest,
                    ...AuthRoutes.filter((item) => item.path === "logout"),
                ],
            },
            {
                path: "",
                element: <ClientLayout />,
                children: [
                    {
                        path: ":permalink",
                        public: true,
                        element: <PublicStaticPageContainer errorComponent={<NotFound />} />
                    }
                ]
            }
        ],
    },
];
