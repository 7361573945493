import { SortBarItemProps, useTranslations } from "@mzara/component"

export const usePlanningListSort = (): Array<SortBarItemProps> => {
    const [
        TITLE,
        DATE,
    ] = useTranslations(i18n)
    return [
        { label: TITLE, value: 'title' },
        { label: DATE, className:"ml-auto", value: 'dateBegin' },
    ]
}

const i18n = [
    'std_title',
    'std_date'
]
