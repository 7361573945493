import { useHubRemoteEvent, useTranslation } from "@mzara/component";
import { WebSocketEvent } from "@mzara/lib";
import { htmlToText } from "html-to-text";
import Mustache from "mustache";

export const useNotificationWebsocket = () => {
    const t = useTranslation();

    const handleHubMessage = (e: WebSocketEvent) => {
        if (e.message && isValidJSON(e.message)) {
            const notification = JSON.parse(e.message);

            if (Notification.permission === "granted") {
                console.log('showing notification')
                const variables = JSON.parse(notification?.variables);
                new Notification(
                    htmlToText(
                        Mustache.render(t(notification?.title), variables)
                    ),
                    {
                        body: htmlToText(
                            Mustache.render(t(notification?.message), variables)
                        ),
                        icon: notification?.icon,
                        badge: notification?.imageUrl,
                    }
                );
            }
        }
    };
    
    useHubRemoteEvent({ entity: "TNotification" }, handleHubMessage);
};

function isValidJSON(str: string) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}
