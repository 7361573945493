import { AppRouteProps } from "@mzara/component";
import React from "react";

const ProjectKanbanMilestoneParamsContainer = React.lazy(() => import('./containers/ProjectKanbanMilestoneParamsContainer'))
export const ProjectKanbanMilestoneParamsRoutes: Array<AppRouteProps> = [
    {
        path: 'params',
        title: "std_params",
        element: <ProjectKanbanMilestoneParamsContainer />
    }
]