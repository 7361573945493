import { AppRouteProps } from "@mzara/component";
import React from "react";

const DetailContractAboutContainer = React.lazy(() => import("./containers/DetailContractAboutContainer"));
export const DetailContractAboutRoute = [
    {
        path: "about",
        element: React.createElement(DetailContractAboutContainer),
        roles: ["PAYING_SLIP"]

    },
] as Array<AppRouteProps>;
