import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ContractSalaryViewsRoutes } from "./views/ContractSalaryViewsRoutes";

const SalaryContractContainer = React.lazy(() => import('./container/SalaryContractContainer'));

export const SalaryContractRoutes: Array<AppRouteProps> = [
    {
        path: 'contract/salary-charts',
        title: "Harea.Contract.SalaryChart.Heading.title",
        element: <SalaryContractContainer />,
        roles: ['CONTRACT'],
        children: [
            ...ContractSalaryViewsRoutes
        ]
    }
]