import { SortBarItemProps, useTranslations } from "@mzara/component";

export const useRecruitingEvaluationListSortBar =
    (): Array<SortBarItemProps> => {
        const [TITLE, STATUS, UPDATED_AT] = useTranslations(i18n);

        return [
            { label: TITLE, value: "name" },
            { label: STATUS, value: "state" },
            { label: UPDATED_AT, value: "updatedAt", className: "ml-auto" },
        ];
    };

const i18n = ["std_title", "std_status", "std_updated_at"];
