import {
    Box,
    ControlList,
    RightSidebar,
    useListFilterState,
    useTranslation,
} from "@mzara/component";
import { useRecruitingEvaluationFilterForm } from "../hooks/useRecruitingEvaluationFilterForm";
import _ from "lodash";

const RecruitingEvaluationListFilter = ({
    listId,
    open,
}: RecruitingEvaluationListFilterProps) => {
    const { filter, setFilter } = useListFilterState(listId);
    const t = useTranslation();

    const filterForm = useRecruitingEvaluationFilterForm();

    const handleFilterChange = (value: Record<string, any>) => {
        setFilter((v: any) => ({
            ...v,
            data: {
                ...v.data,
                ...value,
                curiculumVitae: {
                    id_among: value.curiculumVitae,
                },
                recruitingAdvert: {
                    id_among: value.recruitingAdvert,
                },
            },
        }));
    };

    return (
        <RightSidebar
            className={`right-sidebar-absolute ${open ? "" : "hide"}`}
        >
            <Box
                title={t("std_filter")}
                icon="fa-solid fa-filter"
                className="h-full"
            >
                <ControlList
                    {...filterForm}
                    value={{
                        ...filter?.data,
                        curiculumVitae: !_.isEmpty(
                            filter?.data?.curiculumVitae.id_among
                        )
                            ? filter?.data?.curiculumVitae?.id_among
                            : undefined,
                        recruitingAdvert: !_.isEmpty(
                            filter?.data?.recruitingAdvert.id_among
                        )
                            ? filter?.data?.recruitingAdvert?.id_among
                            : undefined,
                    }}
                    onChange={handleFilterChange}
                />
            </Box>
        </RightSidebar>
    );
};

export default RecruitingEvaluationListFilter;

type RecruitingEvaluationListFilterProps = {
    listId: string;
    open: boolean;
};
