import { useTranslations, useUrlParamsEncode, useUrlParamsState, useUrlParamsValueToString } from "@mzara/component"
import { KanbanColumn } from "../../../components/KanbanColumn"
import { useNavigate, useParams } from "react-router-dom"
import { useAppointmentBoardQuery } from "../../../hooks/useAppointmentBoardQuery"
import { useProjectGanttOpenedCards } from "../hooks/useProjectGanttOpenedCards"
import { useCallback } from "react"
import { TAppointment } from "@mzara/graphql"
import { useKanbanDragEnd } from "../../board/hooks/useKanbanDragEnd"

export const ProjectGanttTickets = () => {

    const [
        TICKETS
    ] = useTranslations(i18n)
    const { id } = useParams()
    const openedCards = useProjectGanttOpenedCards(id)
    const navigate = useNavigate()
    const encode = useUrlParamsEncode()
    const { urlParams, setUrlParams } = useUrlParamsState()
    const urlParamsToString = useUrlParamsValueToString()
    const { data: board } = useAppointmentBoardQuery(parseInt(id), false)

    const handleAddClick = useCallback(() => {
        navigate(`-1?${encode({
            ...urlParams,
            data: {
                board: { id: board?.id },
                noChoice: true,
                date: null,
                hourBegin: null,
                hourEnd: null,
            }
        })}`)
    }, [board, id, openedCards, urlParams])

    const handleCardClick = useCallback((e: TAppointment) => {
        navigate(`${e.num}?${urlParamsToString}`)
    }, [board, setUrlParams, id])


    return (

        <KanbanColumn
            columnId="OPENED"
            index={0}
            boardId={0}
            title={TICKETS}
            cards={openedCards}
            isOpened
            canCreate={board?.isEditable as any}
            onAddClick={handleAddClick}
            onCardClick={handleCardClick}
        />

    )
}

const i18n = [
    'Harea.Project.Kanban.Details.Gantt.Tickets.Title'
]
