import moment from "moment"
import { useCallback, useEffect, useRef } from "react"

export const useStepTimeHandler = (props: StepTimeHandleParams) => {

    const _timeout = useRef<NodeJS.Timeout>()

    useEffect(() => {
        return () => {
            clearTimeout(_timeout.current!)
        }
    }, [])

    useEffect(() => {
        if (props.timer) {
            initTimeHandler()
        }
    }, [props.dateBegin, props.timer])

    const getDateBegin = useCallback(() => {
        if (!props.dateBegin) {
            return moment()
        }
        return moment(props.dateBegin, 'x')
    }, [props])

    const initTimeHandler = useCallback(() => {
        const dateEnd = getDateBegin().add(props.timer, 'minutes')
        const timeout = dateEnd.diff(moment(), 'milliseconds')
        clearTimeout(_timeout.current!)
        if (timeout <= 0) {
            props.onTimeReached?.();
            return;
        }
        _timeout.current = setTimeout(() => props.onTimeReached?.(), timeout)
    }, [props])

    return 
}

type StepTimeHandleParams = {
    dateBegin?: string
    timer?: string
    onTimeReached?: () => void

}
