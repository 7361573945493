import { AppRouteProps } from "@mzara/component";
import React from "react";

const AdvertDetailContainer = React.lazy(
    () => import("./container/AdvertDetailContainer")
);

export const AdvertDetailRoutes: Array<AppRouteProps> = [
    {
        path: "recrutement/:id",
        title: "{{AdvertDetailsTitle}}",
        element: <AdvertDetailContainer />,
    },
];
