import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectKanbanDetailsViewerRoutes } from "../viewer/ProjectKanbanDetailsViewerRoutes";
import ProjectKanbanDetailsGanttContainer from "./container/ProjectKanbanDetailsGanttContainer";
import { ProjectKanbanDetailsCreateRoutes } from "../create/ProjectKanbanDetailsCreateRoutes";
import { ProjectKanbanCardDetailsRoutes } from "../details/ProjectKanbanCardDetailsRoutes";


export const ProjectKanbanDetailsGanttRoutes: Array<AppRouteProps> = [
    {
        path: "gantt",
        title: "Harea.Project.Kanban.Details.Gantt",
        element: <ProjectKanbanDetailsGanttContainer />,
        children: [
            ...ProjectKanbanDetailsViewerRoutes,
            ...ProjectKanbanDetailsCreateRoutes,
            ...ProjectKanbanCardDetailsRoutes
        ]
    },
];
