import { ControlListProps, useTranslations } from "@mzara/component";

export const useRecruitingEvaluationForm = (): ControlListProps => {
    const [NAME, EMAIL, SUBJECT, MESSAGE, ADVERT, EVALUATION, CANCEL, SEND] =
        useTranslations(i18n);

    return {
        id: "evaluation-recruiting-form-id",
        data: {
            forms: [
                { type: "input", name: "name", label: NAME, required: true },
                { type: "input", name: "email", label: EMAIL, required: true },
                { type: "input", name: "subject", label: SUBJECT, required: true },
                {
                    type: "textarea",
                    name: "message",
                    label: MESSAGE,
                    autoSize: true,
                    required: true
                },
                {
                    type: "select",
                    name: "wizard",
                    label: EVALUATION,
                    GQLProps: {
                        gql: wizardGQL,
                        variables: { data: { type: "EVALUATION" } },
                        labelProp: "title",
                        valueProp: "id",
                        rootProp: "wizards.data",
                    },
                    valueObject: true,
                    valueProperty: "id",
                    required: true
                },
                {
                    type: "select",
                    name: "advert",
                    label: ADVERT,
                    GQLProps: {
                        gql: advertGQL,
                        labelProp: "title",
                        valueProp: "id",
                        rootProp: "recruitingAdverts.data",
                    },
                    valueObject: true,
                    valueProperty: "id"
                },
                {
                    type: "select",
                    name: "curiculumVitae",
                    label: "CV",
                    GQLProps: {
                        gql: `
                        query {
                            recruitingCuriculumVitaes {
                              data {
                                id
                                name
                              }
                            }
                          }                          
                        `,
                        labelProp: "name",
                        valueProp: "id",
                        rootProp: "recruitingCuriculumVitaes.data"
                    }
                }
            ],
            back: {
                label: CANCEL,
            },
            next: {
                label: SEND,
            },
        },
    };
};

const advertGQL = `
    query recruitingAdvertsSelectQuery($data: JSONObject) {
        recruitingAdverts(filter: { data: $data }) {
            total 
            data {
                id
                title
            }
        }
    }
`;

const wizardGQL = `
    query recruitingEvaluationTemplateSelectQuery($data: JSONObject) {
        wizards(filter: { data: $data }) {
            total 
            data {
                id
                title
                type
            }
        }
    }
`;

const i18n = [
    "std_name",
    "std_mail",
    "std_object",
    "std_message",
    "Generic.de.RECRUTING_ADVERT",
    "std_assessment",
    "std_cancel",
    "std_send",
];
