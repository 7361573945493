import { useAjax, useMzMutation } from "@mzara/component";
import { AjaxResponseType } from "@mzara/lib";

export const useEndEvaluationMutation = () => {
    const ajax = useAjax();

    return useMzMutation<AjaxResponseType>(
        (payload: Record<string, any>) =>
            ajax.send({
                type: "PUT",
                content_type: "application/json",
                url: "evaluation/end",
                data: payload,
            })
    );
};
