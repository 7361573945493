import { ControlList } from "@mzara/component"
import { useMindMapEditForm } from "hooks/useMindMapEditForm"
import { useParams } from "react-router-dom"

export const MindMapEditContainer = () => {

    const { id } = useParams()
    const control = useMindMapEditForm(id ? parseInt(id) : undefined)

    return (
        <ControlList
            {...control}
            data={{
                ...control.data,
                forms: control.data.forms.filter((item) => item.type !== 'mind-map-models')
            }}
        />
    )
}
