import { AppRouteProps } from "@mzara/component";
import React from "react";

const ContractUserDetailsContainer = React.lazy(() => import('./containers/ContractUserDetailsContainer'))
export const ContractUserDetailsRoutes: Array<AppRouteProps> = [
    {
        path: 'contracts',
        title: 'Harea.User.Contracts.title',
        element: <ContractUserDetailsContainer />
    }
]