import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectDayOffViewsSummaryRoutes } from "./views/summary/ProjectDayOffViewsSummaryRoutes";
import { ProjectDayOffViewsDemandRoutes } from "./views/demand/ProjectDayOffViewsDemandRoutes";
import { ProjectDayOffViewsCalendarRoutes } from "./views/calendar/ProjectDayOffViewsCalendarRoutes";
import { ProjectDayOffMissingRoutes } from "./views/missing/ProjectDayOffMissingRoutes";
const ProjectDayOffContainer = React.lazy(
    () => import("./container/ProjectDayOffContainer")
);

export const ProjectDayOffRoutes: Array<AppRouteProps> = [
    {
        path: "dayoff",
        title: "Harea.Project.DayOff.Management.Label",
        element: <ProjectDayOffContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectDayOffViewsDemandRoutes,
            ...ProjectDayOffViewsSummaryRoutes,
            ...ProjectDayOffViewsCalendarRoutes,
            ...ProjectDayOffMissingRoutes
        ],
    },
];
