import { GraphQLQueryClass } from "@mzara/component";
import { TFormationChapter } from "@mzara/graphql";

export class FormationChapterDetailsQuery
    implements GraphQLQueryClass<TFormationChapter, TResponse>
{
    queryKey: string = "Harea.Formation.Details.Chapter.Query";

    gql: string = `
        query formationChapterDetailsQuery($data: JSONObject) {
            formationChapter(filter: { data: $data }) {
                id
                title
                value
                formation {
                    id
                }
                thread {
                    id

                }
                createdAt
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TFormationChapter {
        return data.formationChapter;
    }
}

export type TResponse = {
    formationChapter: TFormationChapter;
};
