import { ControlListProps, useTranslation } from "@mzara/component";

export const useAppointmentBoardDangerZoneForm = (
    id: number
): ControlListProps => {
    const t = useTranslation();
    return {
        data: {
            title: t("std_danger_zone"),
            forms: [
                { type: "hidden", name: "id" },
                {
                    type: "call-to-action",
                    name: "id",
                    label: t("std_danger_zone"),
                    button: {
                        className: "btn-danger",
                        label: t("std_remove"),
                    },
                    graphQL: {
                        deleteEntity: true,
                        entity: "appointmentBoard",
                        variables: {
                            id,
                        },
                        navigateFallback: "admin/project/kanban",
                    },
                },
            ],
            hideBack: true,
            hideNext: true,
        },
        id: "appointment-board-parameters-dangerZone-form",
    };
};
