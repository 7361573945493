import { CurrentBreadCrumb, GenericSuspense, Menu, useSetCurrentDocumentTitle, useTranslation } from "@mzara/component";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useRecruitingEvaluationDetailsQuery } from "../../viewer/hooks/useRecruitingEvaluationDetailsQuery";
import { useEvaluationDetailsMenu } from "../hooks/useEvaluationDetailsMenu";
import { useEffect } from "react";

const RecruitingDetailsContainer = () => {
    const { id } = useParams();
    const t = useTranslation();
    const { data } = useRecruitingEvaluationDetailsQuery(+id);
    const isEditable = data?.isEditable == undefined || Boolean(data?.isEditable) == true;
    const menuItems = useEvaluationDetailsMenu(id);
    const tab = location.pathname.split("/").at(-1);
    const navigate = useNavigate()

    useEffect(() => {
        if (!menuItems?.some((item) => item.ke === tab)) {
            navigate(menuItems[0].link);
        }
    }, []);

    useSetCurrentDocumentTitle('EvaluationRecruitingDetailsTitle', data?.name)
    useSetCurrentDocumentTitle('EvaluationRecruitingDetailsTab', menuItems?.find((item) => item.ke === tab)?.label)

    return (
        <div className="flex gap-4 w-full">
            <section className="main-content">
                <div className="flex items-center justify-between">
                    <h4 className="font-semibold">
                        {t("Harea.Evaluation.Recruiting.Details.Page.Title")}
                    </h4>
                </div>
                
                <CurrentBreadCrumb />

                <Menu
                    items={menuItems}
                    type="HORIZONTAL"
                    activeFn={(menu) => menu.ke === tab}
                    showFn={(menu) =>
                        menu.ke === "parameters" ? isEditable : true
                    }
                    className="menu-tabs"
                />

                <GenericSuspense>
                    <Outlet />
                </GenericSuspense>

            </section>
        </div>
    );
};

export default RecruitingDetailsContainer;
