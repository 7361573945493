import { GraphQLQueryClass } from "@mzara/component";
import { TAppointment } from "@mzara/graphql";

export class AppointmentOwnerQuery implements GraphQLQueryClass<TAppointment, TResponse>{
    public queryKey: string = 'App.bo.appointment';
    public gql: string = `
        query AppointmentOwnerQuery($id:Float){
            appointment(filter:{id:$id}){
                id
                dateBegin
                dateEnd
                hourBegin
                hourEnd
                title
                reason
                description
                solde
                files {
                    id
                    name
                }
                user {
                    id
                    fullName
                }
                userCreator{
                    id
                    fullName
                    email
                    profilePicture{
                        id name
                    }
                }
                invitations{
                    id
                    ke
                    user{
                        id fullName
                    }
                    isValidated isRevocked
                }
                cover { id }
                type
                meet
                notificationTime
                isRSVP
                repetition
                isValidated
                isRevocked
                isClosed
                overrideTargetDateBegin
                overrideTargetDateEnd
                overrideTargetHourBegin
                overrideTargetHourEnd
                isDeletable
                isEditable
                data
                board {
                    id
                    title
                    color
                }
                labels { id value color }
                thread { id }
            }
        }
    `;
    variables?: any;
    constructor(variables?: any) {
        this.variables = variables;
    }
    mapFn(data: TResponse): TAppointment {
        return data.appointment
    };
}

export type TResponse = {
    appointment: TAppointment
}