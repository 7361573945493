import { AppRouteProps } from "@mzara/component";
import React from "react";

const CurriculumVitaeCreateContainer = React.lazy(
    () => import("./containers/CurriculumVitaeCreateContainer")
);

export const CurriculumVitaeCreateRoutes: Array<AppRouteProps> = [
    {
        path: "create",
        title: "Harea.CV.List.Edit.title",
        element: <CurriculumVitaeCreateContainer />,
    },
];
