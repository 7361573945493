import {
    MenuItemProps,
    useRuntimeEnvRolesIncludes,
    useTranslationRoute,
    useTranslations,
    useUrlParamsValueToString,
} from "@mzara/component";
import { useMemo } from "react";

export const useProjectAppointementCreateTabs = () => {
    const [APPOINTMENT, VACATION, PERMISSION, MISSING, PLANNING] = useTranslations(i18n);
    const roleInclude = useRuntimeEnvRolesIncludes()
    const urlParamsToString = useUrlParamsValueToString()

    return useMemo(
        (): Array<MenuItemProps> => [
            {
                ke: "event",
                label: APPOINTMENT,
                link: `event?${urlParamsToString}`,
            },
            roleInclude('APPOINTMENT_ABSCENCE') 
            ? {
                ke: "planning",
                label: PLANNING,
                link: `planning?${urlParamsToString}`,
            }
            : undefined,
            {
                ke: "vacation",
                label: VACATION,
                link: `vacation?${urlParamsToString}`,
            },
            // {
            //     ke: "permission",
            //     label: PERMISSION,
            //     link: 'permission',
            // },
            roleInclude('APPOINTMENT_ABSCENCE') 
            ? {
                ke: "missing",
                label: MISSING,
                link: `missing?${urlParamsToString}`,
            }
            : undefined,
        ],
        [urlParamsToString]
    );
};

const i18n = [
    "Harea.Project.Appointment.Create.Event.Label", 
    "Harea.Project.Appointment.Create.Vacation.Label", 
    "Harea.Project.Appointment.Create.Permission.Label",
    "Harea.Project.Appointment.Create.Missing.Label",
    "Harea.Project.Appointment.Create.Planning.Label",
];
