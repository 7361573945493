import { ControlListProps, useTranslation, useTranslationRoute } from "@mzara/component";

export const useMindMapDangerZoneForm = (
    id: number
): ControlListProps => {
    const t = useTranslation();
    const tr = useTranslationRoute()
    return {
        data: {
            title: t("std_danger_zone"),
            forms: [
                { type: "hidden", name: "id" },
                {
                    type: "call-to-action",
                    name: "id",
                    label: t("std_danger_zone"),
                    description: t(
                        'Harea.MindMap.Details.Designer.Edit.Viewer.Danger.Message',
                    ),
                    button: {
                        className: "btn btn-danger text-white",
                        label: t("std_remove"),
                    },
                    graphQL: {
                        deleteEntity: true,
                        entity: "mindMap",
                        variables: {
                            id: id,
                        },
                        navigateFallback: tr("admin/mind-map"),
                    },
                },
            ],
            hideFooter: true,
        },
        id: "mind-map-details-parameters-zoneDanger-form",
    };
};
