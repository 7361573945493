import { AppRouteProps } from "@mzara/component";
import React from "react";

const ContractSalaryCreateContainer = React.lazy(() => import('./container/ContractSalaryCreateContainer'))

export const ContractSalaryCreateRoutes: Array<AppRouteProps> = [
    {
        path: 'create',
        element: <ContractSalaryCreateContainer /> 
    },
    {
        path: 'update/:id',
        element: <ContractSalaryCreateContainer action="edition" />
    }
]