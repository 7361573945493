import { useEffect } from 'react'
import { AvatarGroup, Box, GenericSuspense, IconButton, RightSidebar, useTranslationRoute, useTranslations } from "@mzara/component"
import { Link, Outlet, useNavigate, useParams } from "react-router-dom"
import { useMindMapDetailsQuery } from "hooks/useMindMapDetailsQuery"
import _ from 'lodash'
import moment from 'moment'

const MindMapDetails = () => {

    const [
        SAVE,
        LAST_UPDATE,
        INVITATIONS
    ] = useTranslations(i18n)
    const { id } = useParams()
    const [, , , , , tab] = location.pathname.split("/");
    const { data } = useMindMapDetailsQuery(parseInt(id), true)
    const navigate = useNavigate()
    const tr = useTranslationRoute()

    useEffect(() => {
        if (_.isEmpty(tab)) {
            navigate(`designer`, { replace: true })
        }
    }, [])

    return (
        <div className="flex flex-row w-full">
            <div className="flex flex-col flex-1">
                <Box className='flex justify-between py-2'>
                    <div className='flex gap-4 items-center'>
                        <h3>{data.title}</h3>

                        <p>
                            {LAST_UPDATE} : {moment(data.updatedAt, 'x').format('DD-MM-YYYY HH:mm')}
                        </p>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <div className='flex items-center'>
                            <AvatarGroup
                                size="sm"
                                avatarItems={[
                                    {

                                        pictureId: data?.userCreator.id,
                                        title: data?.userCreator.fullName,
                                        itemLink: tr(`admin/user/${data?.userCreator.id}`),
                                    },
                                    ...data.invitations.map((item) => ({
                                        pictureId: item.user.id,
                                        title: item.user.fullName,
                                        itemLink: tr(`admin/user/${item?.user.id}`),
                                    }))
                                ]}
                            />
                            <div className='ml-[-5px]'>
                                <Link to={tr(`admin/mind-map/${id}/designer/viewer/invitation`)}>
                                    <IconButton
                                        className="flex items-center"
                                        icon="fa-circle-plus text-lg"
                                        title={INVITATIONS}
                                    />
                                </Link>
                            </div>


                        </div>
                        <div>
                            <Link to={`${tab}/viewer`}>
                                <IconButton icon="fa fa-cog" />
                            </Link>
                        </div>
                    </div>
                </Box>

                <GenericSuspense>
                    <Outlet />
                </GenericSuspense>
            </div>
        </div>
    )
}

const i18n = [
    'std_save',
    'std_last_update',
    'Harea.MindMap.Details.Designer.Edit.Viewer.Menu.Invitations.title'
]

export default MindMapDetails
