import {
    useTranslationRoute,
    useUrlParamsValueToString,
} from "@mzara/component";
import { TAppointment } from "@mzara/graphql";
import { Link } from "react-router-dom";
import EventCard from "views/calendar/components/EventCard";

const PlanningListItem = ({ item }: PlanningListItemProps) => {
    const urlParamsValue = useUrlParamsValueToString();
    const tr = useTranslationRoute();

    return (
        <Link
            className="w-full"
            to={tr(
                `admin/project/${item?.type === "CONGE" ? "dayoff/demand/" + item.id : "calendar/planning/" + item.id + "?" + urlParamsValue}`
            )}
        >
            <EventCard item={item} />
        </Link>
    );
};

export default PlanningListItem;

const i18n = ["Harea.Vacation.Section.Calendar.EventType.Vacation"];

type PlanningListItemProps = {
    item: TAppointment;
};
