import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectAppointmentCreateMissingContainer from "./container/ProjectAppointmentCreateMissingContainer";

export const ProjectAppointmentCreateMissingRoutes: Array<AppRouteProps> = [
    {
        path: "missing",
        title: "Harea.Project.Appointment.Create.Missing.Label",
        element: <ProjectAppointmentCreateMissingContainer />,
        roles: ["APPOINTMENT_ABSCENCE"]
    },
];
