import { AppRouteProps } from "@mzara/component";
import React from "react";
import { PlanningListContainer } from "./container/PlanningListContainer";
import { ProjectAppointmentCreateRoutes } from "views/create/ProjectAppointmentCreateEventRoutes";
import { ProjectCalendarCardDetailsRoutes } from "views/calendar/views/details/ProjectCalendarCardDetailsRoutes";


export const CalendarPlanningListRoutes: Array<AppRouteProps> = [
    {
        path: "",
        title: "Harea.Vacation.Planning.Edit",
        element: (
            <PlanningListContainer />
        ),
        children: [
            ...ProjectAppointmentCreateRoutes,
            ...ProjectCalendarCardDetailsRoutes,
        ]
    },
];
