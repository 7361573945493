import { AppRouteProps } from "@mzara/component";
import { CreateRoutes } from "./views/create/CreateRoutes";
import FormationContainer from "./container/FormationContainer";

export const FormationRoutes: Array<AppRouteProps> = [
    {
        path: "",
        title: "Harea.Formation.List.Title",
        element: <FormationContainer />,
        children: [...CreateRoutes],
        roles: ["FORMATION"]
    },
];
