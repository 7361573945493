import { AppRouteProps } from "@mzara/component";
import React from "react";
import { AppointmentViewerContainer } from "./container/AppointmentViewerContainer";
import { ProjectKanbanDetailsDangerRoutes } from "../danger/ProjectKanbanDetailsInformationsRoutes";
import { ProjectKanbanDetailsInformationsRoutes } from "../informations/ProjectKanbanDetailsInformationsRoutes";
import { ProjectKanbanDetailsInvitationRoute } from "../invitation/ProjectKanbanDetailsInvitationRoute";
import { ProjectKanbanDetailsArchivedRoutes } from "../archived/ProjectKanbanDetailsArchivedRoutes";

export const ProjectKanbanDetailsViewerRoutes: Array<AppRouteProps> = [
    {
        path: 'viewer',
        element: <AppointmentViewerContainer />,
        children: [
            ...ProjectKanbanDetailsInformationsRoutes,
            ...ProjectKanbanDetailsDangerRoutes,
            ...ProjectKanbanDetailsInvitationRoute,
            ...ProjectKanbanDetailsArchivedRoutes
        ]
    }
]