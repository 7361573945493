import { AppRouteProps } from "@mzara/component";
import FormationDetailsContainer from "./container/FormationDetailsContainer";

export const FormationDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "Harea.Client.Formation.Details.Title",
        element: <FormationDetailsContainer />,
    },
];
