import { AppRouteProps } from '@mzara/component'
import React from 'react'
import { UserManagementContainer } from './container/UserManagementContainer'
import { UserOrganisationRoutes } from 'views/organisation/UserOrganisationRoutes'
import { UserListRoutes } from 'views/list/UserListRoutes'
import { UserDetailsRoutes } from 'views/user-details/UserDetailsRoutes'

export const UserManagementRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <UserManagementContainer />,
        children: [
            ...UserOrganisationRoutes, ...UserListRoutes, ...UserDetailsRoutes
        ]
    }
]
