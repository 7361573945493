import { Outlet } from "react-router-dom";
import { useNotificationWebsocket } from "@mzara/notification";

const HareaContainer = () => {
    useNotificationWebsocket();

    return <Outlet />;
};

export default HareaContainer;
