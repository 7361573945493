import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectKanbanDetailsMilestoneListContainer } from "./container/ProjectKanbanDetailsMilestoneListContainer";
import { ProjectKanbanMilestoneCreateRoutes } from "../create/ProjectKanbanMilestoneCreateRoutes";


export const ProjectKanbanDetailsMilestoneListRoutes: Array<AppRouteProps> = [
    {
        path: "",
        element: <ProjectKanbanDetailsMilestoneListContainer />,
        children: [
            ...ProjectKanbanMilestoneCreateRoutes
        ]
    },
];
