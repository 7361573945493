import { GenericSuspense, MainLogo } from "@mzara/component"
import { Outlet } from "react-router-dom"

const ProjectKanbanDetailsMilestoneContainer = () => {


    return (
        <GenericSuspense fallback={<MainLogo />}>
            <Outlet />
        </GenericSuspense>
    )
}

const i18n = [
    'Harea.Project.Kanban.Details.Gantt.DropTicket.Dialog.Title'
]

export default ProjectKanbanDetailsMilestoneContainer
