import {
    MenuItemProps,
    useTranslation,
    useTranslationRoute,
    useTranslations,
    useUrlParamsEncode,
    useUrlParamsValue,
} from "@mzara/component";
import { useFormationCategoryQuery } from "./useFormationCategoryQuery";
import FormationIcon from "../components/FormationIcon";

export const useLeftSideMenuItems = (): Array<MenuItemProps> => {
    const params = useUrlParamsValue();
    const encodeParams = useUrlParamsEncode();
    // console.log(params);
    const tr = useTranslationRoute();
    const t = useTranslation();
    const { data: categories } = useFormationCategoryQuery();
    const [MAIN, ADVERT, FORMATION, PROFIL, CATEGORY] = useTranslations(i18n);

    return [
        {
            ke: "main",
            label: MAIN,
            className: "menu-item-client",
            items: [
                {
                    ke: "advert",
                    label: ADVERT,
                    link: tr("recrutement"),
                    className: "menu-item-child-client",
                    startIcon: "fa-solid fa-audio-description",
                },
                {
                    ke: "formation",
                    label: FORMATION,
                    link: tr("formation"),
                    className: "menu-item-child-client",
                    startIcon: (
                        <FormationIcon color="#6C6868" height="14" width="24" />
                    ),
                },
                {
                    ke: "profil",
                    label: PROFIL,
                    link: tr("profil"),
                    startIcon: "fa-solid fa-circle",
                    className: "menu-item-child-client",
                },
            ],
        },
        {
            ke: "category",
            label: CATEGORY,
            className: "menu-item-client",
            items: categories.data.map((category) => ({
                ke: category.value?.toLowerCase(),
                label: t(category.translationKey),
                link: tr(
                    `formation?${encodeParams({
                        ...params,
                        data: { category: { id_among: [category.id] } },
                    })}`
                ),
                className: "menu-item-child-client",
            })),
        },
    ];
};

const i18n = [
    "std_main",
    "Generic.de.RECRUTING_ADVERT",
    "std_formation",
    "std_profil",
    "std_category",
];
