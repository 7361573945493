import { AppRouteProps } from "@mzara/component";
import React from "react";
const ProjectKanbanCreateContainer = React.lazy(() => import("./container/ProjectKanbanCreateContainer"));

export const ProjectKanbanCreateRoutes: Array<AppRouteProps> = [
    {
        path: "-1",
        title: "Harea.Project.Kanban.Create.Title",
        element: <ProjectKanbanCreateContainer />,
        roles: ["APPOINTMENT"],
    },
];
