import { AppRouteProps } from "@mzara/component";
import FormationContainer from "./container/FormationContainer";

export const FormationRoutes: Array<AppRouteProps> = [
    {
        path: "",
        title: "Harea.Client.Formation.List.Title",
        element: <FormationContainer />,
    },
];
