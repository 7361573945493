import { useGraphqlQuery } from "@mzara/component";
import { UserOrganisationsQuery } from "../query/UserOrganisationsQuery";
import { TUserOrganisationsResponse } from "@mzara/graphql";

export const useUserOrganisationsQuery = ({ id, suspense }: props) => {
    return useGraphqlQuery<UserOrganisationsQuery, TUserOrganisationsResponse>(
        new UserOrganisationsQuery({ data: { id: id } }),
        { suspense }
    );
};
type props = {
    id?: number;
    suspense?: boolean;
};
