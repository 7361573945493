import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectKanbanEditAppointmentContainer } from "./container/ProjectKanbanEditAppointmentContainer";

export const ProjectKanbanDetailsCardEditRoutes: Array<AppRouteProps> = [
    {
        path: "edit",
        title: "std_edit",
        element: <ProjectKanbanEditAppointmentContainer />,
        roles: ["APPOINTMENT"]
    },
];
