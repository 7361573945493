import { AppRouteProps } from "@mzara/component";
import { ProjectKanbanDetailsArchivedContainer } from "./container/ProjectKanbanDetailsArchivedContainer";
import { ProjectKanbanCardDetailsRoutes } from "../details/ProjectKanbanCardDetailsRoutes";

export const ProjectKanbanDetailsArchivedRoutes: Array<AppRouteProps> = [
    {
        path: "archived",
        element: <ProjectKanbanDetailsArchivedContainer />,
        children: [...ProjectKanbanCardDetailsRoutes],
    },
];
