import { AppRouteProps } from "@mzara/component";
import React from "react";

const ContractTemplateDetailsParameter = React.lazy(() => import("./container/ContractTemplateDetailsParameter"));
export const ContractTemplateDetailsParameterRoute: Array<AppRouteProps> = [
    {
        path: "parameter",
        element: <ContractTemplateDetailsParameter />,
        title: "Harea.Contract.Template.Details.Parameter.Title",
    },
];
