import { AppRouteProps } from "@mzara/component";
import * as React from "react";
const SignupContainer = React.lazy(() => import("./container/SignupContainer"));
export const SignupRoutes: Array<AppRouteProps> = [
    {
        path: "signup",
        title: "Harea.Signup.Title",
        element: <SignupContainer />,
    },
];
