import { AppRouteProps } from "@mzara/component";
import React from "react";

const ContractTemplateDetailsAbout = React.lazy(() => import("./container/ContractTemplateDetailsAbout"));
export const ContractTemplateDetailsAboutRoute: Array<AppRouteProps> = [
    {
        path: "about",
        element: <ContractTemplateDetailsAbout />,
        title: "Harea.Contract.Template.Details.About.Title",
    },
];
