import { AppRouteProps } from "@mzara/component";
import AboutContainer from "./container/AboutContainer";

export const AboutRoutes: Array<AppRouteProps> = [
    {
        path: "about",
        title: "Harea.Formation.Details.About.Title",
        element: <AboutContainer />,
    },
];
