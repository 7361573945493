import { useAjax, useMzQuery } from "@mzara/component";

export const useSocialStatementDetailQuery = (valueType: string) => {
    const ajax = useAjax();

    return useMzQuery(["Harea.Payslip.SocialStatement.Query", valueType], async () => {
        const { response } = await ajax.send({
            type: "GET",
            url: `document/paying/socials/${valueType}`,
            content_type: "application/json",
            data_type: 'application/json'
        })

        return response
    }
        
    );
};
