import { BaseControl, BaseControlProps, Chip } from "@mzara/component";
import { useContractManagerQuery } from "hooks/useContractManagerQuery";

export const AppointmentVacationBalance = (props: Props) => {

    const { data } = useContractManagerQuery(props.formData?.user?.id);


    return (
        <BaseControl
            {...props}
            className={`flex flex-row justify-between ${props.className}`}>
            <div>
                <Chip label={data?.vacationNumberLeft?.toString() ?? '0'} className="bg-default" />
            </div>
        </BaseControl>
    );
};

type Props = BaseControlProps & {
    value?: string;
    onChange: (value: Props["value"]) => void;
};
