import {
    Chip,
    DateSpan,
    IconButton,
    Metadata,
    Tooltip,
    useBreakpoints,
    useCdnAssets,
    useTranslation,
    useTranslationRoute,
    useUrlParamsValueToString,
} from "@mzara/component";
import { EvaluationResult, TEvaluation, TRecruitingAdvert, TRecruitingCuriculumVitae } from "@mzara/graphql";
import { useEvaluationStateColor } from "hooks/useEvaluationStateColor";
import { useEvaluationStateLabel } from "hooks/useEvaluationStateLabel";
import moment from "moment";
import { Link } from "react-router-dom";
import { useStateLabelBackground } from "views/admin/hooks/useStateLabelBackground";

const RecruitingEvaluationItem = ({
    id,
    name,
    email,
    state,
    startDate,
    endDate,
    result,
    applicant,
    offer,
    data,
}: RecruitingEvaluationItemProps) => {
    const cdn = useCdnAssets();
    const tr = useTranslationRoute();
    const t = useTranslation()

    const breakPoint = useBreakpoints();
    const urlParamsToString = useUrlParamsValueToString()
    const getEvaluationStateLabel = useEvaluationStateLabel();
    const getEvaluationStateColor = useEvaluationStateColor();
    const getStateLabelBackground = useStateLabelBackground();

    return (
        <article className="recruiting-evaluation-item w-full flex items-center justify-between px-2 pb-4 border-default border-b">
            <div className="recruiting-evaluation-item-body flex flex-col gap-4">
                <section className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                        <Link
                            to={
                                breakPoint.xs
                                    ? tr(
                                        `admin/evaluation/${id}?${urlParamsToString}`
                                    )
                                    : tr(
                                        `admin/evaluation/viewer/${id}?${urlParamsToString}`
                                    )
                            }
                        >
                            <h3 className="font-bold text-base">

                                {name}
                                <span> • </span>
                                {data.subject}
                            </h3>
                        </Link>

                        <div>
                            <div className="flex gap-2">
                                {result && (
                                    <Chip className="bg-primary" label={`${result?.rate ?? 0}/${result?.rateTotal ?? 0
                                        }`} />
                                )}

                                <Chip
                                    className={`
                                    text-white 
                                    ${getStateLabelBackground(state)}
                                `}
                                    label={getEvaluationStateLabel(state)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-4">

                        <Metadata value={email} />
                        <Metadata
                            value={
                                <Link
                                    className="text-link"
                                    to={tr(
                                        `/admin/evaluation/template/${data.wizard.id}`
                                    )}
                                >
                                    {data.wizard.title}
                                </Link>
                            }
                        />

                        <Metadata
                            icon="fa-clock"
                            value={
                                <DateSpan value={startDate} />
                            }
                        />

                        {
                            endDate &&
                            <Tooltip className="" trigger="hover" text={t('Harea.Evaluation.Recruiting.Details.Info.Metadata.EndDate.Label')}>
                                <Metadata
                                    icon="fa-check"
                                    value={
                                        <DateSpan value={endDate} />
                                    }
                                />
                            </Tooltip>
                        }


                        {applicant && (
                            <Metadata
                                value={
                                    <div className="flex items-center gap-2">
                                        <span className="w-[14px] h-[14px]">
                                            <img
                                                className=""
                                                src={cdn("/img/icon/file-cv.svg")}
                                                alt="icon-menu"
                                            />
                                        </span>
                                        <Link
                                            className="text-link"
                                            to={tr(
                                                `admin/recruiting/cv/${applicant?.id}/details`
                                            )}
                                        >
                                            {applicant?.name}
                                        </Link>
                                    </div>
                                }
                            />
                        )}
                        {
                            offer &&
                            <Metadata
                                icon="fa-audio-description"
                                value={
                                    <Link
                                        className="text-link"
                                        to={tr(`admin/recruiting/${offer?.id}/about`)}
                                    >
                                        {offer?.title}
                                    </Link>
                                }
                            />

                        }
                    </div>
                </section>
            </div>

            <Link
                data-testid={`evaluation-recruiting-row-${id}-link`}
                to={tr(`admin/evaluation/${id}`)}
            >
                <IconButton icon="fa-solid fa-angle-right" />
            </Link>
        </article>
    );
};

export default RecruitingEvaluationItem;

type RecruitingEvaluationItemProps = {
    id?: number;
    name?: string;
    email?: string;
    state?: string;
    startDate?: string;
    endDate?: string;
    result?: EvaluationResult;
    applicant?: TRecruitingCuriculumVitae;
    offer?: TRecruitingAdvert;
    data?: TEvaluation
};
