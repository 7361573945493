import { useParams } from "react-router-dom";
import { useSocialStatementDetailQuery } from "../hooks/useSocialStatementDetailQuery";
import {
    Box,
    Chip,
    DateSpan,
    IconButton,
    List,
    Metadata,
    Tooltip,
    useNumberFormat,
    useSetCurrentDocumentTitle,
    useTranslation,
} from "@mzara/component";

const SocialStatementDetailContainer = () => {
    const { valueType } = useParams();

    const formatCurrency = useNumberFormat({
        style: "currency",
        currency: "MGA",
    });
    const t = useTranslation();
    useSetCurrentDocumentTitle(`UserFicheSocialDeclarationType`, valueType)

    const { data, isLoading } = useSocialStatementDetailQuery(valueType);

    return (
        <Box
            className="box-transparent shadow-none p-0 !bg-transparent"
            title={
                <>
                    {t("Harea.Payslip.SocialsStatement.Detail.History.Box.Title")} - {valueType}
                </>
            }
        >
            <List
                rows={data}
                search={false}
                selection={false}
                pagination={false}
                loading={isLoading}
                onRenderRow={(row, index) => (
                    <div
                        key={index}
                        className="flex gap-8 items-center justify-between"
                    >
                        <Metadata
                            icon="fa-sack-dollar"
                            value={
                                <Chip label={formatCurrency(row.value)} />
                            }
                        />
                        <Metadata
                            icon="fa-clock"
                            value={
                                <DateSpan value={row.createdAt} normalFormat />
                            }
                        />
                        <Tooltip
                            trigger="hover"
                            text={row.comment}
                        >
                            <Metadata
                                icon="fa-comments"
                            />
                        </Tooltip>

                    </div>
                )}
            />
        </Box>
    );
};

export default SocialStatementDetailContainer;
