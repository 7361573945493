import { AppRouteProps } from '@mzara/component'
import React, { Children } from 'react'
import { PublicationViewerRoutes } from './view/viewer/PublicationViewerRoutes'
const PublicationContainer = React.lazy(() => import('./containers/PublicationContainer'))

export const PublicationRoutes: Array<AppRouteProps> = [
    {
        path: '',
        title: 'Harea.Recruiting.Publication.title',
        element: <PublicationContainer />,
        children: [
            ...PublicationViewerRoutes
        ],
        roles: ["RECRUTING_ADVERT"]
    },
]
