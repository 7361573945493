import { AppRouteProps } from "@mzara/component";
import React from "react";
import { KanbanCardDetails } from "./container/KanbanCardDetails";
import { ProjectKanbanDetailsCardEditRoutes } from "./views/edit/ProjectKanbanDetailsCardEditRoutes";

export const ProjectKanbanDetailsDetailRoutes: Array<AppRouteProps> = [
    {
        path: "about",
        title: "Harea.Project.Kanban.Details.Menu.About.Title",
        element: <KanbanCardDetails />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectKanbanDetailsCardEditRoutes
        ]
    },
];
