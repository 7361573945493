import { ControlList, Dialog, useTranslationRoute, useTranslations } from "@mzara/component";
import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { useMindMapInvitationEditForm } from "../hooks/useMindMapInvitationEditForm";
import { useMindMapInvitationsQuery } from "../../../hooks/useMindMapInvitationsQuery";

const MindMapInvitationEditContainer = () => {

    const { id, invitationId } = useParams()
    const [TITLE] = useTranslations(i18n);
    const navigate = useNavigate()
    const tr = useTranslationRoute()
    const { invalidateQuery } = useMindMapInvitationsQuery(parseInt(id))
    const control = useMindMapInvitationEditForm()
    const location = useLocation()

    const handleClose = useCallback(() => {
        navigate(location.pathname.split('/').slice(0, -1).join('/'))
    }, [location])

    const handleSubmited = useCallback((val: Record<string, any>) => {
        invalidateQuery()
        handleClose()
    }, [])

    return (
        <Dialog open title={TITLE} onClose={handleClose} className="">
            <ControlList
                key={invitationId}
                {...control}
                defaultValue={{
                    id: _.isNumber(invitationId) ? parseInt(invitationId) : undefined,
                    mindMap: { id: parseInt(id) }
                }}
                onCancel={handleClose}
                onSubmited={handleSubmited}
            />
        </Dialog>
    );
};
export default MindMapInvitationEditContainer;

const i18n = [
    "Harea.Project.Kanban.Viewer.Menu.Invitation.Title",
];

