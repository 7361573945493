import { AppRouteProps } from "@mzara/component";
import FormationClientContainer from "./container/FormationClientContainer";
import { FormationRoutes } from "./views/formation/FormationRoutes";
import { FormationDetailsRoutes } from "./views/formation-details/FormationDetailsRoutes";

export const FormationClientRoutes: Array<AppRouteProps> = [
    {
        path: "formation",
        title: "Harea.Client.Formation.Title",
        element: <FormationClientContainer />,
        children: [...FormationRoutes, ...FormationDetailsRoutes],
    },
];
