import { AppRouteProps } from "@mzara/component";
import React from "react";
const ForgotContainer = React.lazy(
    () => import("./containers/ForgotContainer")
);

export const ForgotRoutes: Array<AppRouteProps> = [
    {
        path: "forgot-password",
        element: <ForgotContainer />,
    },
];
