import { Button, Chip, ControlSkeleton, DateSpan, GenericSuspense, MDEditor, Metadata, useFileUrl, useTranslationRoute, useTranslations, useUrlParamsValueToString } from "@mzara/component"
import { TAppointment } from "@mzara/graphql"
import { Thread } from "@mzara/thread"
import { Link, Outlet, useParams } from "react-router-dom"
import { ReactNode, useCallback, useMemo } from "react"
import { FileItem } from "views/kanban/views/details/views/details/views/details/components/FileItem"
import { VacationResponse } from "./VacationResponse"
import { VacationStatus } from "./VacationStatus"
import { VacationDates } from "./VacationDates"
import { useAppointmentDetailsEditLink } from "hooks/useAppointmentDetailsEditLink"
import { useAppointmentQuery } from "hooks/useAppointmentQuery"
import { AppointmentDetailsType } from "./AppointmentDetailsType"
import { RepetitionControl } from "views/create/views/event/components/RepetitionControl"

export const AppointmentDetails = (props: Props) => {
    const [
        CREATOR,
        STATUS,
        CLOSED,
        OPENED,
        CREATED_AT,
        DATE_END,
        LABELS,
        USERS,
        DESCRIPTION,
        ATTACHMENT,
        METADATA,
        ARCHIVED,
        EDIT,
        MILESTONE,
        USER,
        REPETITION
    ] = useTranslations(i18n)
    const { id } = useParams()
    const { invalidateQuery } = useAppointmentQuery(parseInt(id), true, props.disableDetailsQuery !== true);
    const urlParamsToString = useUrlParamsValueToString()
    const editLink = useAppointmentDetailsEditLink(props.type)
    const fileUrl = useFileUrl()
    const tr = useTranslationRoute()

    const ticketStatus = useMemo(() => {
        if (props?.dateArchive) {
            return ARCHIVED
        }

        return props?.isClosed ? CLOSED : OPENED
    }, [props])

    const getEndIcon = useCallback((invitation) => {
        if (invitation?.isValidated) {
            return "fa-circle-check text-success"
        }

        if (invitation?.isRevocked) {
            return "fa-circle-xmark text-danger"
        }
    }, [props])

    return (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                    <div className="flex justify-between flex-wrap">
                        <h3 className="flex gap-4 items-center">
                            {METADATA}
                            <AppointmentDetailsType {...props} />
                        </h3>
                        {
                            props.isEditable &&
                            <div className="flex gap-2">
                                <Link to={editLink}>
                                    <Button
                                        className="btn-default"
                                        startIcon="fa-pen"
                                        label={EDIT}
                                    />
                                </Link>
                                {props.toolbox}
                            </div>
                        }
                    </div>
                    <div className="flex justify-end">
                        {
                            props.type === 'CONGE' &&
                            <VacationResponse {...props} onSubmited={() => invalidateQuery()} />
                        }
                    </div>
                </div>
                <div className="flex gap-4 flex-wrap">
                    <Metadata
                        label={CREATOR}
                        value={(
                            <Link to={`user/${props?.userCreator.id}`}>
                                <Chip
                                    userId={props?.userCreator?.id}
                                    label={props?.userCreator?.fullName}
                                />
                            </Link>
                        )}
                    />
                    {
                        props.type === 'CONGE' &&
                        <Metadata
                            label={USER}
                            value={(
                                <Link to={`user/${props?.user.id}`}>
                                    <Chip
                                        userId={props?.user?.id}
                                        label={props?.user?.fullName}
                                    />
                                </Link>
                            )}
                        />
                    }
                    {
                        props.type === 'CONGE' &&
                        <VacationStatus {...props} />
                    }
                    {
                        props.type === 'KANBAN' &&
                        <Metadata
                            label={STATUS}
                            value={(
                                <Chip
                                    className={`${props.isClosed ? 'bg-danger text-white' : 'bg-default'}`}
                                    label={ticketStatus}
                                />
                            )}
                        />
                    }
                </div>


                <div className="flex gap-4 flex-wrap">
                    {
                        props.invitations?.length > 0 &&
                        <Metadata
                            label={USERS}
                            className="flex-col items-start"
                            value={(
                                <div className="flex gap-2 flex-wrap">
                                    {
                                        props.invitations.map((item) => (
                                            <Link to={`user/${item?.user?.id}`}>
                                                <Chip
                                                    userId={item?.user?.id}
                                                    label={item?.user?.fullName}
                                                    endIcon={getEndIcon(item)}
                                                />
                                            </Link>
                                        ))
                                    }
                                </div>
                            )}
                        />
                    }

                    {
                        props.labels?.length > 0 &&
                        <Metadata
                            label={LABELS}
                            className="flex-col items-start"
                            value={(
                                <div className="flex gap-2 flex-wrap">
                                    {
                                        props.labels?.map((item, index) => (
                                            <Chip
                                                key={index}
                                                className="bg-white px-2 py-0 text-xs"
                                                label={item.value}
                                                color={item.color}
                                            />
                                        ))
                                    }
                                </div>
                            )}
                        />
                    }
                    {
                        props.milestone &&
                        <Metadata
                            label={MILESTONE}
                            className="flex-col items-start"
                            value={(
                                <Link to={tr(`admin/project/kanban/${id}/milestone/${props.milestone?.id}?${urlParamsToString}`)}>
                                    <Chip
                                        className="bg-default"
                                        startIcon="fa-solid fa-bullseye"
                                        label={props.milestone?.title}
                                        color={props.milestone?.color}
                                    />
                                </Link>
                            )}
                        />
                    }
                </div>

                <div className="flex gap-4">
                    {
                        props.createdAt &&
                        <Metadata
                            icon="fa-clock"
                            value={(
                                <DateSpan value={props.createdAt} />
                            )}
                            title={CREATED_AT}
                        />
                    }
                    {
                        props.dateBegin &&
                        <Chip
                            startIcon="fa-clock"
                            className="bg-default"
                            label={
                                <DateSpan value={props.dateBegin} format="DD-MM-YYYY HH:mm" />
                            }
                            title={DATE_END}
                        />
                    }
                    {
                        props.dateEnd &&
                        <Chip
                            startIcon="fa-clock"
                            className="bg-danger text-white"
                            label={
                                <DateSpan value={props.dateEnd} format="DD-MM-YYYY HH:mm" />
                            }
                            title={DATE_END}
                        />
                    }

                    {
                        ['CONGE', 'ABSCENCE'].includes(props.type) &&
                        <VacationDates {...props} hideDates />
                    }
                </div>

                {
                    props.repetition &&
                    <RepetitionControl label={REPETITION} value={props.repetition} readonly />
                }

                {
                    props.cover &&
                    <img className="w-full h-60 object-cover" src={fileUrl(props.cover?.id)} />
                }

            </div>

            <div className="flex flex-col gap-2">
                <h3>{DESCRIPTION}</h3>

                <GenericSuspense fallback={<ControlSkeleton />}>
                    <MDEditor readonly value={props.description ?? ''} />
                </GenericSuspense>
            </div>

            {
                props.files?.length > 0 &&
                <div className="flex flex-col gap-2">
                    <h3>{ATTACHMENT}</h3>

                    <div className="flex gap-4 flex-wrap">
                        {
                            props.files?.map((item, index) => (
                                <FileItem {...item} key={index} />
                            ))
                        }
                    </div>
                </div>
            }

            {
                props?.thread && (
                    <Thread
                        id={props?.thread?.id}
                        className="p-0 shadow-none"
                        shortenButton
                    />
                )
            }
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </div>
    )
}

type Props = Partial<TAppointment> & {
    toolbox?: ReactNode
    disableDetailsQuery?: boolean
}

const i18n = [
    'std_creator',
    'std_status',
    'std_closed',
    'std_opened',
    'std_created_at',
    'std_date_end',
    'Harea.Project.Calendar.Details.Labels.Label',
    'Harea.Project.Calendar.Details.Users.Label',
    'Harea.Project.Calendar.Details.Description.Label',
    "std_attachment",
    "std_metadata",
    "std_archived",
    'std_edit',
    'Harea.Project.Calendar.Details.Milestone.Label',
    'std_user',
    "Harea.Vacation.Calendar.Event.Create.Vacation.Form.Label.Repetition",
]

