import Gantt from "frappe-gantt"
import { useCallback } from "react"

export const useProjectGantt = (props: ProjectGanttProps) => {

    const init = useCallback((el: HTMLDivElement) => {
        return new Gantt(el, props.tasks, props.options)
    }, [props])

    return init
}

export type ProjectGanttProps ={
    tasks: Gantt.Task[]
    options?: Gantt.Options
}
