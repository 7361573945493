import { AppRouteProps } from "@mzara/component";
import React from "react";
import { CalendarPlanningRoutes } from "./views/planning/CalendarPlanningRoutes";
import { ProjectCalendarCardDetailsRoutes } from "./views/details/ProjectCalendarCardDetailsRoutes";
import { ProjectAppointmentCreateRoutes } from "views/create/ProjectAppointmentCreateEventRoutes";
const CalendarContainer = React.lazy(() => import("./container/CalendarContainer"));

export const ProjectCalendarRoutes: Array<AppRouteProps> = [
    {
        path: "calendar",
        title: "Harea.Project.Calendar",
        element: <CalendarContainer id="CalendarContainer" view="timeGridWeek" />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectAppointmentCreateRoutes,
            ...ProjectCalendarCardDetailsRoutes,
            ...CalendarPlanningRoutes
        ]
    },
];
