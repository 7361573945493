import {
    BaseControlProps,
    Box,
    Button,
    ButtonSubmit,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";
import AdvertInfo from "components/AdvertInfo";
import { useAdvertDetailsQuery } from "hooks/useAdvertDetailsQuery";
import { useNavigate } from "react-router-dom";
import { useRecruitingAdvertPublishMutation } from "../hooks/useRecruitingAdvertPublishMutation";
import { useEffect } from "react";

const AdvertPreview = ({ formData, onCancel }: BaseControlProps) => {
    const navigate = useNavigate();

    const tr = useTranslationRoute();
    const [PUBLISH, CREATE, BACK] = useTranslations(i18n);

    const { data } = useAdvertDetailsQuery({
        id: parseInt(formData?.id),
        suspense: true,
    });
    const mutation = useRecruitingAdvertPublishMutation();


    const handlePublishClick = () => {
        mutation.mutate({
                data: {
                    id: parseInt(formData?.id),
                    isPublished: true,
                    thread: { id: null },
                },
            }, {
            onSuccess: () => {
                    // console.log('success')
                    handleCreateClick()
                },
                onError: (e) => {
                    console.error('error', e)
                }
            })
    };

    const handleCreateClick = () => {
        navigate(tr(`admin/recruiting/${formData?.id}/about`))
    };

    return (
        <div className="flex flex-col gap-5 w-full">
            <AdvertInfo data={data} />

            <Box className="flex justify-between">
                <Button
                    className="btn-default"
                    onClick={onCancel}
                    label={BACK}
                    startIcon="fa-chevron-left"
                />

                <div className="flex justify-end gap-4">
                    <ButtonSubmit
                        data-testid="recruiting-advert-create-button"
                        label={CREATE}
                        disabled={mutation.isLoading}
                        className="rounded-md bg-default"
                        startIcon="fa-save"
                        type="button"
                        onClick={handleCreateClick}
                    />
                    <ButtonSubmit
                        data-testid="recruiting-advert-publish-button"
                        label={PUBLISH}
                        isSubmit={mutation.isLoading}
                        className="rounded-md btn-primary"
                        endIcon="fa-bullhorn"
                        type="button"
                        onClick={handlePublishClick}
                    />
                </div>
            </Box>
        </div>
    );
};

export default AdvertPreview;

const i18n = ["std_publish", "std_create", 'std_back'];
