import { useGraphqlMutation, useTranslation } from "@mzara/component";
import { TAppointment } from "@mzara/graphql";

export const useAppointmentGraphqlMutation = () => {
    const t = useTranslation()
    return useGraphqlMutation<TAppointment>(gql, { showLoadingMessage: true, loadingMessage: t('std_saving') });
};

const gql = `
    mutation AppointmentGraphqlMutation($data: JSONObject, $datas: [JSONObject!]) {
        saveAppointment(data: { data: $data, datas: $datas }) {
            id
        }
    }
`;
