import { AppRouteProps } from "@mzara/component";
import React from "react";

const CreateContractContainer = React.lazy(() => import("./container/CreateContractContainer"));

export const CreateContractRoute: Array<AppRouteProps> = [
    {
        path: "create",
        element: <CreateContractContainer />,
        roles: ["CONTRACT"]
    },
];
