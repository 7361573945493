import { AppRouteProps } from "@mzara/component";
import ProjectKanbanMilestoneAboutContainer from "./container/ProjectKanbanMilestoneAboutContainer";

export const ProjectKanbanMilestoneAboutRoutes: Array<AppRouteProps> = [
    {
        path: "about",
        title: "std_about",
        element: <ProjectKanbanMilestoneAboutContainer />,
        roles: ["APPOINTMENT"],
    },
];
