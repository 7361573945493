 import { DragDropContext, DropResult, Droppable, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd"
import { useCallback } from "react";
import { useProjectGanttOpenCardsDragEnd } from "../hooks/useProjectGanttOpenCardsDragEnd";

export const ProjectGanttDndProvider = (props: Props) => {

    const _handleDragEnd = useProjectGanttOpenCardsDragEnd(props.id);

    const handleDragEnd = useCallback((result: DropResult) => {

        // dropped nowhere
        if (!result.destination) {
            return;
        }

        if (result.destination.droppableId === 'GANTT') {
            props.onGanttDropped?.(result.draggableId)
            return;
        }
        _handleDragEnd(result)
    }, [_handleDragEnd])

    return (
        <DragDropContext
            onDragEnd={handleDragEnd}
        >
            <Droppable
                droppableId="board"
                type="BOARD"
                direction="horizontal"
                ignoreContainerClipping
                isCombineEnabled>
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        // isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}
                    >
                        { props.children }
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

type Props = {
    id: string
    children: React.ReactNode
    onGanttDropped?: (id: string) => void
}
