import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectKanbanDetailsMilestoneContainer from "./container/ProjectKanbanDetailsMilestoneContainer";
import { ProjectKanbanDetailsViewerRoutes } from "../viewer/ProjectKanbanDetailsViewerRoutes";
import { ProjectKanbanDetailsCreateRoutes } from "../create/ProjectKanbanDetailsCreateRoutes";
import { ProjectKanbanCardDetailsRoutes } from "../details/ProjectKanbanCardDetailsRoutes";
import { ProjectKanbanDetailsMilestoneListRoutes } from "./views/list/ProjectKanbanDetailsMilestoneListRoutes";
import { ProjectKanbanDetailsMilestoneDetailsRoutes } from "./views/details/ProjectKanbanDetailsMilestoneDetailsRoutes";


export const ProjectKanbanDetailsMilestoneRoutes: Array<AppRouteProps> = [
    {
        path: "milestone",
        title: "Harea.Project.Kanban.Details.Milestone",
        element: <ProjectKanbanDetailsMilestoneContainer />,
        children: [
            ...ProjectKanbanDetailsViewerRoutes,
            ...ProjectKanbanDetailsMilestoneListRoutes,
            ...ProjectKanbanDetailsMilestoneDetailsRoutes
        ]
    },
];
