import { AppRouteProps } from "@mzara/component";
import React from "react";
import { DetailsRoutes } from "./views/details/DetailsRoutes";
import { EvaluationsRoutes } from "./views/evaluations/EvaluationsRoutes";
import { ParametersRoutes } from "./views/paramaters/ParametersRoutes";

const CvDetailsContainer = React.lazy(() => import('./containers/CvDetailsContainer'))

export const CvDetailsRoutes: Array<AppRouteProps> = [
    {
        path: 'cv/:id',
        title: '{{Generic_Auth_Cv_details_title}}',
        element: <CvDetailsContainer />,
        children: [
            ...EvaluationsRoutes,
            ...DetailsRoutes,
            ...ParametersRoutes
        ],
        roles: ["RECRUITING_ADVERT_CV"]
    }
]