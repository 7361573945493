import {
    Dialog,
    useUrlParamsValueToString
} from "@mzara/component";
import { TAppointment } from "@mzara/graphql";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useKanbanCardDetailsQuery } from "../../../../../hooks/useKanbanCardDetailsQuery";
import { ProjectKanbanEditAppointment } from "../components/ProjectKanbanEditAppointment";

export const ProjectKanbanEditAppointmentContainer = () => {
    
    const { id, cardNum } = useParams()
    const { data } = useKanbanCardDetailsQuery(parseInt(id), parseInt(cardNum));
    const navigate = useNavigate()
    const urlParamsToString = useUrlParamsValueToString()

    const handleClose = useCallback(() => {
        navigate(`..?${urlParamsToString}`)
    }, [id, urlParamsToString])

    return (
        <Dialog
            open={id !== undefined}
            title={data?.title}
            onClose={handleClose}>
            <ProjectKanbanEditAppointment
                onClose={handleClose}
            />
        </Dialog>
    );
};

export type VacationDialogStateProps = {
    id?: number;
    isKanban?: boolean;
    onClose?: () => void;
    onSubmited?: (data?: TAppointment) => void;
    onDeleted?: (data?: Record<string, any>) => void;
};
