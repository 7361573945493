import { AppRouteProps } from "@mzara/component";
import React from "react";
import { CreatePortfolioRoute } from "views/create/CreatePortfolioRoute";

const PortfolioContainer = React.lazy(() => import("./container/PortfolioContainer"));

export const PortfolioRoutes: Array<AppRouteProps> = [
    {
        path: "portfolio",
        title: "Harea.Portfolio.Heading.Title",
        element: <PortfolioContainer />,
        roles: ["PORTFOLIO"],
        children: [
            ...CreatePortfolioRoute,
        ]
    },
];
