import { Box, Button, LovSelect, LovSelectValue, useTranslations } from "@mzara/component"
import { TListOfValue } from "@mzara/graphql"
import { useState } from "react"

export const KanbanAddColumn = (props: KanbanColumnProps) => {

    const [
        TITLE,
        SELECT_LABEL,
        SELECT_PLACEHOLDER,
        SELECT_ADD_LABEL,
    ] = useTranslations(i18n)
    const [value, setValue] = useState<LovSelectValue>({ id: null })

    return (
        <Box 
            title={TITLE} 
            className="flex flex-col gap-2 min-w-[280px] max-w-[280px] !shadow-none !bg-transparent">
            
            <div className="flex flex-col gap-4">
                <LovSelect
                    tp="APPOINTMENT_LABELS"
                    label={SELECT_LABEL}
                    placeholder={SELECT_PLACEHOLDER}
                    value={value}
                    onChange={(value) => setValue(value)}
                    />
                <Button
                    className="btn-primary"
                    label={SELECT_ADD_LABEL}
                    disabled={props.columns?.some((item) => item.id === value.id) || !value.id}
                    onClick={() => props.onAddClick?.(value)}
                    />
            </div>
        </Box>
    )
}

const i18n = [
    'Harea.Vacation.Kanban.Column.Add.Title',
    'Harea.Vacation.Kanban.Column.Select.Label',
    'Harea.Vacation.Kanban.Column.Select.Placeholder',
    'Harea.Vacation.Kanban.Column.Select.Add.Label',
]

type KanbanColumnProps = {
    columns: Array<TListOfValue>
    onAddClick?: (value: LovSelectValue) => void
}
