import { MenuItemProps, useTranslations } from "@mzara/component"
import { useMemo } from "react"

export const useKanbanColumnMenu = () => {

    const [
        DELETE,
        MOVE_LEFT,
        MOVE_RIGHT,
    ] = useTranslations(i18n)

    return useMemo((): Array<MenuItemProps> => {
        return [
            {
                ke: 'MOVE',
                label: MOVE_LEFT,
                startIcon: 'fa-solid fa-arrow-left',
                value: 'LEFT'
            },
            {
                ke: 'MOVE',
                label: MOVE_RIGHT,
                startIcon: 'fa-solid fa-arrow-right',
                value: 'RIGHT',
            },
            {
                divide: true
            },
            {
                ke: 'DELETE',
                label: DELETE,
                startIcon: 'fa-solid fa-trash',
                value: 'DELETE'
            }
        ]
    }, [])
}

const i18n = [
    'std_delete',
    'std_move_left',
    'std_move_right',
]
