import { ControlListProps, useTranslation } from "@mzara/component";
import { useSendEmailForm } from "../../../../../hooks/useSendEmailForm";

export const useAddEvaluationForm = (id: number): ControlListProps => {
    const t = useTranslation();
    const form = useSendEmailForm();
    return {
        ...form,
        data: {
            ...form.data,
            forms: [
                {
                    type: "select",
                    name: "curiculumVitae",
                    label: t(
                        "Harea.Advert.Details.Evaluation.AddEvaluation.Dialog.Form.CurriculumVitae.Label"
                    ),
                    GQLProps: {
                        gql: cvGQL,
                        variables: { data: { recrutingAdvert: { id } } },
                        labelProp: "name",
                        valueProp: "id",
                        rootProp: "recruitingCuriculumVitaes.data",
                    },
                    required: true,
                    valueObject: true,
                    valueProperty: "id",
                },
                ...form.data.forms,
                {
                    type: "select",
                    name: "wizard",
                    label: t("std_assessment"),
                    GQLProps: {
                        gql: evaluationGQL,
                        variables: { data: { type: "EVALUATION" } },
                        labelProp: "title",
                        valueProp: "id",
                        rootProp: "wizards.data",
                    },
                    required: true,
                    valueObject: true,
                    valueProperty: "id",
                },
            ],
            next: {
                label: t("std_add"),
            },
        },
        id: "add-evaluation-form",
    };
};

const cvGQL = `
    query recruitingCVsSelectQuery($data: JSONObject) {
        recruitingCuriculumVitaes(filter: { data: $data }) {
            total 
            data {
                id
                name
                email
                recrutingAdvert {
                    id
                }
            }
        }
    }
`;

const evaluationGQL = `
    query recruitingEvaluationTemplateSelectQuery($data: JSONObject) {
        wizards(filter: { data: $data }) {
            total 
            data {
                id
                title
                type
            }
        }
    }
`;
