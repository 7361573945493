import { Button, Dialog, GenericSuspense, MainLogo, useTranslations } from "@mzara/component"
import { Link, Outlet, useNavigate, useParams } from "react-router-dom"
import { useMindMapInvitationsQuery } from "../hooks/useMindMapInvitationsQuery"
import { useCallback, useState } from "react"
import {  useMindMapInvitationDeleteMutation } from "../hooks/useMindMapInvitationDeleteMutation"
import Mustache from 'mustache'
import { MindMapInvitationItem } from "../components/MindMapInvitationItem"

export const MindMapInvitationContainer = () => {

    const [
        ADD,
        CONFIRMATION,
        CONFIRM_MESSAGE,
        CONFIRM,
        CANCEL,
    ] = useTranslations(i18n)
    const { id } = useParams()
    const { data, invalidateQuery } = useMindMapInvitationsQuery(parseInt(id), true)
    const navigate = useNavigate()
    const [invitationId, setInvitationId] = useState<number>();
    const deleteMutation = useMindMapInvitationDeleteMutation();
    const handleDelete = useCallback(() => {
        deleteMutation.mutate(
            {
                ids: [invitationId],
            },
            {
                onSuccess: () => {
                    invalidateQuery();
                    setInvitationId(undefined);
                },
            }
        );
    }, [invitationId])

    return (
        <div className="flex flex-col gap-4">
            <div className="flex justify-end">
                <Link to={"create"}>
                    <Button className="btn-default" startIcon="fa-plus" label={ADD} />
                </Link>
            </div>
            <div className="flex flex-col gap-4">
                <MindMapInvitationItem
                    user={data.userCreator}
                    actions={"OWNER" as any}
                    createdAt={data.createdAt}
                />
                {
                    data?.invitations?.map((item) => (
                        <MindMapInvitationItem
                            key={item.id}
                            {...item}
                            isEditable={data.isEditable as any}
                            onEditClick={() => navigate(`edit/${item.id}`)}
                            onDeleteClick={() => setInvitationId(item.id)}
                        />
                    ))
                }
            </div>

            <GenericSuspense fallback={<MainLogo />}>
                <Outlet />
            </GenericSuspense>

            <Dialog
                open={invitationId !== undefined}
                title={CONFIRMATION}
                confirmation
                message={Mustache.render(
                    CONFIRM_MESSAGE, 
                    { 
                        userFullName: data?.invitations.find((item) => item.id === invitationId)?.user?.fullName
                    }
                )}
                btnCancel={{
                    label: CANCEL,
                    startIcon: "",
                }}
                btnOk={{
                    className: "btn-danger",
                    label: CONFIRM,
                    endIcon: "",
                    isSubmit: deleteMutation.isLoading
                }}
                onClose={() => setInvitationId(undefined)}
                onCancel={() => setInvitationId(undefined)}
                onConfirm={handleDelete}
            />
        </div>
    )
}

const i18n = [
    'std_add',
    "std_confirmation",
    "Harea.MindMap.Designer.Invitation.Delete.Confirm.Message",
    "std_confirm",
    "std_cancel",

]
