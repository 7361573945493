import { ControlList, Dialog, useTranslationRoute, useTranslations, useUrlParamsValueToString } from "@mzara/component";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProjectKanbanInvitationEditForm } from "../hooks/useProjectKanbanInvitationEditForm";
import { useAppointmentBoardInvitationsQuery } from "../../../hooks/useAppointmentBoardInvitationsQuery";
import _ from "lodash";
import { useKanbanDetailsTab } from "views/kanban/views/details/hooks/useKanbanDetailsTab";

const ProjectKanbanInvitationEditContainer = () => {

    const { id, invitationId } = useParams()
    const [TITLE] = useTranslations(i18n);
    const navigate = useNavigate()
    const tr = useTranslationRoute()
    const { invalidateQuery } = useAppointmentBoardInvitationsQuery(parseInt(id))
    const control = useProjectKanbanInvitationEditForm()
    const tab = useKanbanDetailsTab()
    const urlParamsToString = useUrlParamsValueToString()

    const handleClose = useCallback(() => {
        navigate(tr(`admin/project/kanban/${id}/${tab}/viewer/invitation?${urlParamsToString}`))
    }, [id, urlParamsToString])

    const handleSubmited = useCallback((val: Record<string, any>) => {
        invalidateQuery()
        handleClose()
    }, [])

    return (
        <Dialog open title={TITLE} onClose={handleClose} className="">
            <ControlList
                key={invitationId}
                {...control}
                defaultValue={{
                    id: _.isNumber(invitationId) ? parseInt(invitationId) : undefined,
                    appointmentBoard: { id: parseInt(id) }
                }}
                onCancel={handleClose}
                onSubmited={handleSubmited}
            />
        </Dialog>
    );
};
export default ProjectKanbanInvitationEditContainer;

const i18n = [
    "Harea.Project.Kanban.Viewer.Menu.Invitation.Title",
];

