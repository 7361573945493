import { useCallback } from "react";
import { getHareaErrorTranslations } from "utils/hareaErrorTraduction";

export const useHareaError = () => {
    const hareaErrorTranslations = getHareaErrorTranslations();

    return useCallback((error: any): HareaError => {
        const errorMessage = error.response?.errors[0].message;

        if (errorMessage?.includes("NOT_FOUND")) {
            return {
                code: 404,
                title: hareaErrorTranslations.NOT_FOUND.title,
                message: hareaErrorTranslations.NOT_FOUND.message,
            };
        }

        if (errorMessage?.includes("OFFLINE")) {
            return {
                code: 408,
                title: hareaErrorTranslations.OFFLINE.title,
                message: hareaErrorTranslations.OFFLINE.message,
            };
        }

        if (errorMessage?.includes("FORBIDDEN_CREATE")) {
            return {
                code: 403,
                title: hareaErrorTranslations.FORBIDDEN_CREATE.title,
                message: hareaErrorTranslations.FORBIDDEN_CREATE.message,
            };
        }

        if (errorMessage?.includes("FORBIDDEN_EDIT")) {
            return {
                code: 403,
                title: hareaErrorTranslations.FORBIDDEN_EDIT.title,
                message: hareaErrorTranslations.FORBIDDEN_EDIT.message,
            };
        }

        if (errorMessage?.includes("FORBIDDEN_DELETE")) {
            return {
                code: 403,
                title: hareaErrorTranslations.FORBIDDEN_DELETE.title,
                message: hareaErrorTranslations.FORBIDDEN_DELETE.message,
            };
        }

        if (errorMessage?.includes("FORBIDDEN")) {
            return {
                code: 403,
                title: hareaErrorTranslations.FORBIDDEN.title,
                message: hareaErrorTranslations.FORBIDDEN.message,
            };
        }

        if (errorMessage?.includes("INTERNAL_SERVER_ERROR")) {
            return {
                code: 500,
                title: hareaErrorTranslations.INTERNAL_SERVER_ERROR.title,
                message: hareaErrorTranslations.INTERNAL_SERVER_ERROR.message,
            };
        }

        return {
            title: hareaErrorTranslations.APP_ERROR.title,
            message: hareaErrorTranslations.APP_ERROR.message,
        };
    }, []);
};

type HareaError = {
    code?: number;
    title: string;
    message: string;
};
