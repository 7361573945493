import { MenuItemProps, useTranslation } from "@mzara/component";
import { TRecruitingCuriculumVitae } from "@mzara/graphql";
import { useMemo } from "react";
import { useRecruitingCVListQuery } from "./useRecruitingCVListQuery";

export const useCVFilterMenuItems = () => {
    const t = useTranslation();

    const { data: cvs } = useRecruitingCVListQuery(true);

    return useMemo((): Array<MenuItemProps> => {
        return [
            {
                id: 0,
                ke: "default",
                label: t("std_all_applications"),
            },
            ...cvs.data.map((cv: TRecruitingCuriculumVitae) => ({
                id: cv.id,
                ke: cv.name?.toLowerCase(),
                label: cv.name,
            })),
        ];
    }, [cvs]);
};
