import { AppRouteProps } from "@mzara/component";
import { AdvertDetailRoutes } from "./views/advert-details/AdvertDetailRoutes.tsx";
import { AdvertSearchRoutes } from "./views/advert-search/AdvertSearchRoutes";
import { AdvertRoutes } from "./views/advert/AdvertRoutes";
import { RecruitingClientContainer } from "./container/RecruitingClientContainer.js";

export const RecruitingClientRoutes: Array<AppRouteProps> = [
    {
        path: "",
        title: "Recruiting.Client",
        element: <RecruitingClientContainer />,
        children: [
            ...AdvertRoutes,
            ...AdvertDetailRoutes,
            ...AdvertSearchRoutes,
        ],
    },
];
