import {
    MenuItemProps,
    useGraphqlMutation,
    useTranslations,
} from "@mzara/component";
import { WizardSequentialDesigner } from "@mzara/wizard";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

const MainPageContainer = () => {
    const { id } = useParams();

    const [NEW_QUESTION_LABEL, EMPTY_MESSAGE] = useTranslations(i18n);
    const createMutation = useGraphqlMutation(FORMATION_MUTATION);

    const handleAddClick = useCallback(async () => {
        const result = await createMutation.mutateAsync({
            data: {
                value: JSON.stringify({
                    data: {
                        title: NEW_QUESTION_LABEL,
                        forms: [],
                    },
                }),
            },
        });
        return result.saveWizardNode.id;
    }, []);

    const showFn = useCallback((menu: MenuItemProps, index: number) => {
        const items = ["title", "video", "image-legend"];

        return items.includes(menu.value);
    }, []);

    return (
        <div data-testid="formation-details-tab-main_page">
            <WizardSequentialDesigner
                id={parseInt(id)}
                wizardNodeType="FORMATION"
                controlListSelectMenuShowFn={showFn}
                generatedName={process.env.NODE_ENV !== "test"}
                emptyMessage={EMPTY_MESSAGE}
                onAddClick={handleAddClick}
            />
        </div>
    );
};

export default MainPageContainer;

export const FORMATION_MUTATION = `
    mutation ($data: JSONObject) {
        saveFormation (data: { data: $data }){ id }
    }
`;

const i18n = [
    "Harea.Formation.Details.MainPage.Node.NewQuestion.Label",
    "Harea.Formation.Details.MainPage.Empty.Message",
];
