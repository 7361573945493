import React from "react";
import { Outlet } from "react-router-dom";
import { GenericSuspense } from "@mzara/component";
import { EvaluationNavBar } from "./EvaluationNavBar";

const EvaluationLayout = () => {
    return (
        <div className="client-layout min-h-screen bg-body-background">
            <EvaluationNavBar />

            <main>
                <GenericSuspense>
                    <Outlet />
                </GenericSuspense>
            </main>
        </div>
    );
};

export default EvaluationLayout;
