import { ControlList } from "@mzara/component"
import { useParams } from "react-router-dom"
import { useAppointmentBoardForm } from "views/kanban/hooks/useAppointmentBoardForm";

export const ProjectKanbanDetailsInformationsContainer = () => {
    const { id } = useParams()
    const control = useAppointmentBoardForm();

    return (
        <ControlList
            {...control}
            defaultValue={{ id: parseInt(id) }}
        />
    )
}
