import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectAppointmentCreatePlanningContainer from "./container/ProjectAppointmentCreatePlanningContainer";

export const ProjectAppointmentCreatePlanningRoutes: Array<AppRouteProps> = [
    {
        path: "planning",
        title: "Harea.Project.Appointment.Create.Planning.Label",
        element: <ProjectAppointmentCreatePlanningContainer />,
        roles: ["APPOINTMENT_ABSCENCE"]
    },
];
