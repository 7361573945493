import { MenuItemProps, useTranslationRoute, useTranslations } from "@mzara/component"
import { useMemo } from "react"

export const useMindMapDetailsViewerMenu = (id: number) => {

    const [
        THEMING,
        EDIT,
        INVITATIONS,
        DANGER,
    ] = useTranslations(i18n)

    const translatedRoute = useTranslationRoute()

    return useMemo((): Array<MenuItemProps> => ([
        {
            ke: 'node',
            label: THEMING,
            link: translatedRoute(`admin/mind-map/${id}/designer/viewer/node/-1`),
        },{
            ke: 'edit',
            label: EDIT,
            link: translatedRoute(`admin/mind-map/${id}/designer/viewer/edit`),
        },
        {
            ke: 'invitation',
            label: INVITATIONS,
            link: translatedRoute(`admin/mind-map/${id}/designer/viewer/invitation`),
        },
        {
            ke: 'danger',
            label: DANGER,
            link: translatedRoute(`admin/mind-map/${id}/designer/viewer/danger`),
        },
    ]), [INVITATIONS])
}

const i18n = [
    'Harea.MindMap.Details.Designer.Edit.Viewer.Menu.Theming.title',
    'Harea.MindMap.Details.Designer.Edit.Viewer.Menu.Edit.title',
    'Harea.MindMap.Details.Designer.Edit.Viewer.Menu.Invitations.title',
    'Harea.MindMap.Details.Designer.Edit.Viewer.Menu.Danger.title',
]
