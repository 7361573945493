import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectCalendarDetailsAppointmentContainer from "./container/ProjectCalendarDetailsAppointmentContainer";
import { ProjectEventEditRoutes } from "./views/edit/ProjectEventEditRoutes";

export const ProjectCalendarCardDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "{{AppointmentTitle}}",
        element: <ProjectCalendarDetailsAppointmentContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectEventEditRoutes
        ]
    },
];
