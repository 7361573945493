import { AppRouteProps } from "@mzara/component";
import SocialStatementDetailContainer from "./container/SocialStatementDetailContainer";

export const SocialStatementDetailRoute: Array<AppRouteProps> = [
    {
        path: ":valueType",
        title: "{{UserFicheSocialDeclarationType}}",
        element: <SocialStatementDetailContainer />,
        roles: ["PAYING_SLIP_SOCIALS"],
    },
];
