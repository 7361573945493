import { useGraphqlQuery } from "@mzara/component";
import { TRecruitingAdvertsResponse } from "@mzara/graphql";
import { RecruitingAdvertList } from "../queries/RecruitingAdvertList";

export const useRecruitingAdvertListQuery = (suspense?: boolean) => {
    return useGraphqlQuery<RecruitingAdvertList, TRecruitingAdvertsResponse>(
        new RecruitingAdvertList({ data: { isPublished: true } }),
        {
            suspense: suspense || false,
        }
    );
};
