import { AppRouteProps } from "@mzara/component";
import { PublicStaticPage } from "components/PublicStaticPage";
import React from "react";
import { StaticPageCreateRoutes } from "views/create/StaticPageCreateRoutes";
import { StaticPageDetailsRoutes } from "views/details/StaticPageDetailsRoutes";

const StaticPage = React.lazy(() => import('./containers/StaticPage'))

export const StaticPageRoutes: Array<AppRouteProps> = [
    {
        path: 'static-page',
        title: 'Bo.StaticPage.title',
        element: <StaticPage/>,
        children: [
            ...StaticPageCreateRoutes
        ]
    },
    {
        path: 'static-page',
        title: 'Bo.StaticPage.title',
        children: [
            ...StaticPageDetailsRoutes
        ]
    },
    {
        path: 'static/:permalink',
        title: "Bo.StaticPage.title",
        element: <PublicStaticPage/>
    }
]