import { AppRouteProps } from "@mzara/component";
import React from "react";
import { AboutPublicationDetailsRoutes } from "./views/about/AboutPublicationDetailsRoutes";
import { CvListPublicationRoutes } from "./views/cv/CvListPublicationRoutes";
import { AdvertParametersRoutes } from "./views/parameters/AdvertParametersRoutes";
import { EvaluationsRoutes } from "./views/evaluations/EvaluationsRoutes";

const PublicationDetailsContainer = React.lazy(
    () => import("./containers/PuclicationDetailsContainer")
);
export const PublicationDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "{{PublicationDetailsTitle}}",
        element: <PublicationDetailsContainer />,
        children: [
            ...AboutPublicationDetailsRoutes,
            ...CvListPublicationRoutes,
            ...EvaluationsRoutes,
            ...AdvertParametersRoutes,
        ],
        roles: ["RECRUTING_ADVERT"]

    },
];