import { useCdnAssets, useFileUrl } from "@mzara/component";
import { useUserOrganisationsQuery } from "./useUserOrganisationsQuery";

export const useItemsUserOrganisations = () => {
    const url = useFileUrl()
    const { data } = useUserOrganisationsQuery({ suspense: true });
    const items = data?.data.map((v) => {
        return {
            ke: `${v.id}`,
            id: v.id,
            startIcon: <img src={url(v.logo?.id!)} className="h-4 bg-default border-none rounded-sm" />,
            label: v.designation,
            "data-testid": `avatar-menu-organisation-${v.organisationKey}`,
            className: "bg-[#D9D9D922] rounded-md",
        };
    });
    return items ?? [];
};
