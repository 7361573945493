import { MenuItemProps, useTranslationRoute, useTranslations } from "@mzara/component";
import { useParams } from "react-router-dom";

export const useContractTemplateDetailsMenuItems = (): Array<MenuItemProps> => {
    const [ABOUT, PARAMETER] = useTranslations(i18n)
    const { id } = useParams()
    const t = useTranslationRoute()
    return [
        {
            ke: "about",
            label: ABOUT,
            "data-testid": "contract-template-details-menu-about",
            link: t(`admin/contract/template/details/${id}/about`)
        },
        {
            ke: "parameter",
            label: PARAMETER,
            "data-testid": "contract-template-details-menu-parameter",
            link: t(`admin/contract/template/details/${id}/parameter`)
        }
    ]
}
const i18n = ["Harea.Contract.Template.Details.About", "Harea.Contract.Template.Details.Parameter"]