import { AppRouteProps } from "@mzara/component";
import React from "react";

const ParamsTemplateDetailsContainer = React.lazy(() => import('./containers/ParamsTemplateDetailsContainer'))
export const ParamsTemplateDetailsRoutes: Array<AppRouteProps> = [
    {
        path: 'params',
        title: '{{EvaluationTemplateDetailsTab}}',
        element: <ParamsTemplateDetailsContainer />
    }
]