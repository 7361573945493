import { Chip, useTranslations } from "@mzara/component";
import { TAppointment } from "@mzara/graphql"
import { useAppointmentEventColor } from "hooks/useAppointmentEventColor"

export const AppointmentDetailsType = (props: Props) => {

    const [APPOINTMENT, VACATION, PERMISSION, MISSING, PLANNING] = useTranslations(i18n);
    const className = useAppointmentEventColor(props)

    if (props.type === 'ABSENCE') {
        return (
            <Chip label={MISSING} className={className} />
        )
    }

    if (props.type === 'CONGE') {
        return (
            <Chip label={VACATION} className={className} />
        )
    }

    if (props.type === 'PERMISSION') {
        return (
            <Chip label={PERMISSION} className={className} />
        )
    }

    if (props.type === 'PLANNING') {
        return (
            <Chip label={PLANNING} className={className} />
        )
    }

    return (
        <></>
    )
}

const i18n = [
    "Harea.Project.Appointment.Create.Event.Label", 
    "Harea.Project.Appointment.Create.Vacation.Label", 
    "Harea.Project.Appointment.Create.Permission.Label",
    "Harea.Project.Appointment.Create.Missing.Label",
    "Harea.Project.Appointment.Create.Planning.Label",
];
type Props = Partial<TAppointment>
