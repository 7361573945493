import { Box, Button, useTranslationRoute, useTranslations } from "@mzara/component";
import { Link } from "react-router-dom";

const Congratulation = () => {
    const [
        TITLE,
        DESCRIPTION,
        CTA
    ] = useTranslations(i18n)
    const tr = useTranslationRoute()
    return (
        <div className="flex flex-col gap-4 w-full max-w-xl m-auto">
            <Box>
                <h2 className="text-2xl text-center">{TITLE}</h2>
                <p className="mt-8 text-center" dangerouslySetInnerHTML={{ __html: DESCRIPTION.replaceAll('\n', '<br/>') }} />

                <div className="mt-4 flex justify-center w-full">
                    <a href="https://isasolutions.mg" className="">
                        <Button
                            className="btn-primary"
                            label={CTA}
                        />
                    </a>
                </div>
            </Box>
        </div>
    );
};


const i18n = [
    'Harea.Evaluation.Client.Finished.Title',
    'Harea.Evaluation.Client.Finished.Description',
    'Harea.Evaluation.Client.Finished.CallToAction.Label',
]

export default Congratulation;
