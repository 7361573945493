import { AppRouteProps } from "@mzara/component";
import AdminContainer from "./container/AdminContainer";
import { RecruitingRoutes } from "./views/recruiting/RecruitingRoutes";
import { TemplateRoutes } from "./views/template/TemplateRoutes";

export const EvaluationAdminRoutes: Array<AppRouteProps> = [
    {
        path: "evaluation",
        title: "Harea.Admin.LeftSideBar.Menu.Recruiting.Evaluations.Label",
        element: <AdminContainer />,
        children: [
            ...RecruitingRoutes,
            ...TemplateRoutes
        ],
    },
];
