import { ControlListProps, useTranslations } from "@mzara/component";

export const useMindMapInvitationEditForm = (): ControlListProps => {
    const [
        USER,
        ACTION,
        ACTIONS_READ,
        ACTIONS_EDIT,
        ACTIONS_COMMENT,
        SAVE,
        CANCEL
    ] = useTranslations(i18n);

    return {
        data: {
            forms: [
                {
                    type: "select",
                    label: USER,
                    name: "user",
                    required: true,
                    GQLProps: {
                        gql: `
                            query {
                                users (filter:{ sort: [{ sort: "ASC", value: "fullName" }] }) {
                                    data{
                                        id fullName profilePicture { id }
                                    }
                                }
                            }
                        `,
                        rootProp: "users.data",
                        labelProp: "fullName",
                        valueProp: "id",
                        fileImgProp: "profilePicture.id",
                    },
                    valueObject: true,
                    valueProperty: "id",
                },
                {
                    type: "select",
                    name: "actions",
                    label: ACTION,
                    options: [
                        { label: ACTIONS_READ, value: 'READ' },
                        { label: ACTIONS_EDIT, value: 'EDIT' },
                        { label: ACTIONS_COMMENT, value: 'COMMENT' },
                    ],
                    required: true,
                },
                {
                    type: "hidden",
                    name: "id",
                },
            ],
            value: {},
            next: {
                label: SAVE,
                className: "btn-primary",
            },
            back: {
                label: CANCEL,
            },
            graphQL: {
                entity: "mindMapInvitation",
            },
            fields: {},
        },
        id: "mind-map-invitation-form",
    };
}
const i18n = [
    'Harea.Project.Kanban.Invitation.User.Label',
    'Harea.Project.Kanban.Invitation.Actions.Label',
    'Harea.Project.Kanban.Invitation.Actions.READ.Label',
    'Harea.Project.Kanban.Invitation.Actions.EDIT.Label',
    'Harea.Project.Kanban.Invitation.Actions.COMMENT.Label',
    "std_save",
    "std_cancel",
];