import { MenuItemProps, useTranslation } from "@mzara/component";
import { TRecruitingAdvert } from "@mzara/graphql";
import { useMemo } from "react";
import { useRecruitingAdvertListQuery } from "./useRecruitingAdvertListQuery";

export const useAdvertFilterMenuItems = () => {
    const t = useTranslation();

    const { data: adverts } = useRecruitingAdvertListQuery(true);

    return useMemo((): Array<MenuItemProps> => {
        return [
            {
                id: 0,
                ke: "default",
                label: t("std_all_adverts"),
            },
            ...adverts.data.map((advert: TRecruitingAdvert) => ({
                id: advert.id,
                ke: advert.title?.toLowerCase(),
                label: advert.title,
            })),
        ];
    }, [adverts]);
};
