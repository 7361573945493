import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectDayOffCreateContainer from "./container/ProjectDayOffCreateContainer";
import { ProjectAppointmentCreateVacationRoutes } from "views/create/views/vacation/ProjectAppointmentCreateVacationRoutes";
import { ProjectAppointmentCreateMissingRoutes } from "views/create/views/missing/ProjectAppointmentCreateMissingRoutes";

export const ProjectDayOffCreateRoutes: Array<AppRouteProps> = [
    {
        path: "create",
        element: <ProjectDayOffCreateContainer />,
        children: [
            ...ProjectAppointmentCreateMissingRoutes,
        ]
    },
];
