import { AppRouteProps } from "@mzara/component";
import React from "react";

const CreateContractTemplateContainer = React.lazy(() => import("./container/CreateContractTemplateContainer"));

export const CreateContractTemplateRoute: Array<AppRouteProps> = [
    {
        path: "create",
        element: <CreateContractTemplateContainer />,
        title: "Harea.Recruiting.Contract.Template..Create.Title",
    },
];
