
import {
    GenericSuspense,
    MainLogo,
    RightSidebar,
} from "@mzara/component";
import { useEffect, useState } from "react";
import { PublicationViewer } from "../components/PublicationViewer";

const PublicationViewerContainer = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpen(true);
        }, 50);
    }, []);

    return (
        <RightSidebar
            data-testid="recruiting-advert-viewer"
            className={`right-sidebar-absolute overflow-x-hidden ${
                open ? "" : "hide"
            }`}
        >
            <GenericSuspense fallback={<MainLogo className="absolute" />}>
                <PublicationViewer />
            </GenericSuspense>
        </RightSidebar>
    );
};

export default PublicationViewerContainer;

