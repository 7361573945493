import { Dialog, GenericSuspense, MainLogo, useDebounce, useTranslations, useUrlParamsState } from "@mzara/component"
import { ProjectGantt } from "../components/ProjectGantt"
import { ProjectGanttTickets } from "../components/ProjectGanttTickets"
import { Outlet, useParams } from "react-router-dom"
import { ProjectGanttDndProvider } from "../components/ProjectGanttDndProvider"
import { useCallback, useRef, useState } from "react"
import { ProjectCardDateControl } from "../components/ProjectCardDateControl"
import { useAppointmentBoardQuery } from "../../../hooks/useAppointmentBoardQuery"
import { useProjectGanttOpenedCards } from "../hooks/useProjectGanttOpenedCards"
import { useAppointmentMutation } from "../hooks/useAppointmentMutation"
import { TAppointment } from "@mzara/graphql"

const ProjectKanbanDetailsGanttContainer = () => {

    const [
        DIALOG_TITLE
    ] = useTranslations(i18n)

    const dataToSave = useRef<Array<Partial<TAppointment>>>([])
    const saveDebounce = useDebounce()
    const { id } = useParams()
    const { setUrlParams, urlParams } = useUrlParamsState()
    const [idToDrop, setIdToDrop] = useState<number>()

    const { data, invalidateQuery } = useAppointmentBoardQuery(parseInt(id))
    const mutation = useAppointmentMutation()

    const handleGanttDropped = useCallback((id: string) => {
        setIdToDrop(parseInt(id))
    }, [])

    const handleSubmited = useCallback(() => {
        setIdToDrop(undefined)
        invalidateQuery()
    }, [])

    const handleChange = useCallback((value: Partial<TAppointment>) => {
        dataToSave.current.push(value)
        saveDebounce(() => {
            mutation.mutate({
                datas: dataToSave.current
            }, {
                onSuccess: () => {
                    invalidateQuery()
                    dataToSave.current = []
                }
            })
        }, 500)
    }, [mutation])
    
    return (
        <ProjectGanttDndProvider id={id} onGanttDropped={handleGanttDropped}>
            <div className="grid grid-cols-12 gap-2" style={{ height: 'calc(100vh - 140px)' }}>

                <div className="col-span-12 sm:col-span-5 lg:col-span-5 xl:col-span-4 2xl:col-span-3 flex justify-start h-full overflow-hidden">
                    <ProjectGanttTickets />
                </div>
                <div className="col-span-12 sm:col-span-7 lg:col-span-7 xl:col-span-8 2xl:col-span-9 overflow-auto max-h-full">
                    <ProjectGantt 
                        appointments={data?.appointments}
                        view={urlParams.gantView}
                        onChange={handleChange}
                        onClick={(id) => setIdToDrop(id)}
                        onViewChange={(value) => setUrlParams((v) => ({ ...v, gantView: value }))}
                        />
                </div>

                <GenericSuspense fallback={<MainLogo />}>
                    <Outlet />
                </GenericSuspense>

                <Dialog title={DIALOG_TITLE} open={idToDrop !== undefined} onClose={() => setIdToDrop(undefined)}>
                    <ProjectCardDateControl boardId={parseInt(id)} id={idToDrop} onSubmited={handleSubmited} onCancel={() => setIdToDrop(undefined)} />
                </Dialog>

            </div>
        </ProjectGanttDndProvider>
    )
}

const i18n = [
    'Harea.Project.Kanban.Details.Gantt.DropTicket.Dialog.Title'
]

export default ProjectKanbanDetailsGanttContainer
