import { AppRouteProps } from "@mzara/component";
import React from "react";

const ContractDepartureDetailsAboutContainer = React.lazy(() => import("./container/ContractDepartureDetailsAboutContainer"));
export const ContractDepartureDetailsAboutRoute: Array<AppRouteProps> = [
    {
        path: "about",
        element: <ContractDepartureDetailsAboutContainer />,
        title: "Harea.Contract.Departure.Details.About.Title",
    },
];
