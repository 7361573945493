export const getHareaErrorTranslations = (
    lang: keyof typeof HAREA_ERROR_TRANSLATION = "fr"
): Record<string, any> => {
    return HAREA_ERROR_TRANSLATION[lang];
};

const HAREA_ERROR_TRANSLATION = {
    fr: {
        OFFLINE: {
            title: "Hors connexion",
            message: "Vous êtes actuellement hors connexion",
        },
        FORBIDDEN: {
            title: "Accès non authorisé",
            message:
                "Vous ne possédez pas les droits nécessaire pour accéder à cette information",
        },
        NOT_FOUND: {
            title: "Page introuvable",
            message: "Le contenu que vous recherchez est introuvable",
        },
        INTERNAL_SERVER_ERROR: {
            title: "Erreur interne du serveur",
            message:
                "Désolé, il y a eu une erreur lors du traitement de votre requette",
        },
        FORBIDDEN_CREATE: {
            title: "Action non authorisé",
            message: "Désolé, vous ne disposez pas du droit de création",
        },
        FORBIDDEN_EDIT: {
            title: "Action non authorisé",
            message: "Vous ne disposez pas du droit d'édition",
        },
        FORBIDDEN_DELETE: {
            title: "Action non authorisé",
            message: "Vous ne disposez pas du droit de suppression",
        },
        APP_ERROR: {
            title: "Une erreur s'est produite",
            message:
                "Désolé, il y a eu une erreur lors du chargement de l'application",
        },
    },
};
