import { List } from "@mzara/component";
import { useRecruitingEvaluationListSortBar } from "../hooks/useRecruitingEvaluationListSortBar";
import { TEvaluation } from "@mzara/graphql";
import RecruitingEvaluationItem from "./RecruitingEvaluationItem";
import { RecruitingEvaluationListQuery } from "../queries/RecruitingEvaluationListQuery";

const RecruitingEvaluationList = ({ id }: RecruitingEvaluationListProps) => {
    const sortItems = useRecruitingEvaluationListSortBar();

    return (
        <List
            search={false}
            selection={false}
            id={id}
            sort={{ items: sortItems }}
            GQLRequest={RecruitingEvaluationListQuery}
            defaultFilter={{
                pageSize: 10,
                page: 0,
                sort: [{ sort: "DESC", value: "updatedAt" }],
            }}
            onRenderRow={(row: TEvaluation) => {
                const result = JSON.parse(row?.result as string);

                return (
                    <RecruitingEvaluationItem
                        key={row?.id}
                        id={row?.id}
                        name={row?.name}
                        email={row?.email}
                        result={result}
                        state={row?.state}
                        startDate={row?.createdAt}
                        endDate={row?.dateEnd}
                        applicant={row?.curiculumVitae}
                        offer={row?.recruitingAdvert}
                        data={row}
                    />
                );
            }}
        />
    );
};

export default RecruitingEvaluationList;

type RecruitingEvaluationListProps = {
    id: string;
};
