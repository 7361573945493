import {
    MenuItemProps,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";
import { useMemo } from "react";

export const useClientMenuItems = () => {
    const [PROFILE, LOGOUT] = useTranslations(i18n);
    const translationRoute = useTranslationRoute();

    return useMemo((): Array<MenuItemProps> => {
        return [
            {
                label: "Accueil",
                link: translationRoute(`/`),
                startIcon: "fa-solid fa-home",
            },
            {
                label: "Recrutement",
                link: translationRoute(`recrutement`),
                startIcon: "fa-solid fa-audio-description",
            },
            {
                label: "Formations",
                link: translationRoute(`formation`),
                startIcon: "fa-solid fa-award",
            },
        ];
    }, []);
};

const i18n = ["std_profil", "std_logout"];
