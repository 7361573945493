import { useTranslationRoute, useTranslations } from "@mzara/component";
import { useMemo } from "react";

export const useLeftSideBarMenuRecruiting = () => {

    const [
        RECRUITING,
        ADVERT,
        CANDIDATES,
        EVALUATIONS,
        TEMPLATES
    ] = useTranslations(i18n)
    const translationRoute = useTranslationRoute();
    return useMemo(() => ({
        ke: "general",
        label: RECRUITING,
        className: "menu-item-category",
        items: [
            {
                label: ADVERT,
                link: translationRoute("admin/recruiting"),
                startIcon: "fa-solid fa-audio-description",
                roles: ["RECRUTING_ADVERT"],
            },
            {
                label: CANDIDATES,
                link: translationRoute("admin/recruiting/cv"),
                startIcon: "fa-solid fa-pen-clip",
                roles: ["RECRUITING_ADVERT_CV"],
            },
            {
                label: EVALUATIONS,
                startIcon: "fa-solid fa-clipboard-user",
                link: translationRoute("admin/evaluation"),
                roles: ['RECRUITING_ADVERT_EVALUATION']
            },
        ],
    }), [])
}

const i18n = [
    'Harea.Admin.LeftSideBar.Menu.Recruiting.Label',
    'Harea.Admin.LeftSideBar.Menu.Recruiting.Advert.Label',
    'Harea.Admin.LeftSideBar.Menu.Recruiting.Candidates.Label',
    'Harea.Admin.LeftSideBar.Menu.Recruiting.Evaluations.Label',
    'Harea.Admin.LeftSideBar.Menu.Recruiting.Evaluations.Templates.Label',
]
