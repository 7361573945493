import { useUrlParamsValue } from "@mzara/component";
import moment, { Moment } from "moment"
import { useMemo } from "react"
import { useParams } from "react-router-dom";

export const useProjectAppointmentCreateDefaultValue = () => {

    const { id } = useParams()
    const urlParams = useUrlParamsValue();
    return useMemo(() => {
        const dateDefault: Moment = moment(urlParams.date)

        return {
            id: id ? parseInt(id) : undefined,
            notificationTime: [10],
            dateBegin: dateDefault?.format(),
            hourBegin: dateDefault?.format("HH:mm"), 
            hourEnd: dateDefault?.add(1, "hour")?.format("HH:mm"), 
            title: urlParams.data?.title,
            description: urlParams.data?.description,
            entity: urlParams.data?.entity,
            entityId: urlParams.data?.entityId,
            type: urlParams.data?.type ?? 'NORMAL',
            noChoice: urlParams.data?.noChoice,
            board: urlParams.data?.board,
        }
    }, [urlParams])
}
