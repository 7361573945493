import { TAppointment } from "@mzara/graphql";
import { useMemo, useState } from "react";
import { useAcceptAppointmentInvitationMutation } from "../hooks/useAcceptAppointmentInvitationMutation";
import { useRejectAppointmentInvitationMutation } from "../hooks/useRejectAppointmentInvitationMutation";
import { Button, ControlList, Dialog, useRuntimeEnv, useTranslations } from "@mzara/component";
import { AppointmentDetailsToolboxProps } from "./AppointmentDetailsToolbox";

export const AppointmentDetailsInvitations = (props: AppointmentDetailsToolboxProps) => {

    const [
        ACCEPT,
        REFUSE,
        REJECT_INVITATION,
    ] = useTranslations(i18n);
    const { runtimeEnv } = useRuntimeEnv(true);
    const [rejectDialog, setRejectDialog] = useState(false);

    const acceptInvitation = () => {
        acceptAppointmentInvitation.mutate({},
            {
                onSuccess: () => {
                    props.onUpdated?.()
                }
            });
    };

    const rejectInvitation = (value) => {
        rejectAppointmentInvitation.mutate(value, {
            onSuccess: () => {
                setRejectDialog(false);
                props.onUpdated?.()
            },
        });
    };

    const invitation = useMemo(() => {
        return props?.invitations?.find((invitation) => invitation?.user?.id === runtimeEnv.user.id);
    }, [props])

    const hasGivedResponse = useMemo(() => {
        return invitation?.isValidated || invitation?.isRevocked
    }, [invitation])

    const showInvitationButtons = useMemo(() => {

        if (props?.userCreator?.id === runtimeEnv.user?.id) {
            return false
        }
        return invitation !== undefined
    }, [props, invitation, hasGivedResponse])


    const invitationKe = props?.invitations?.find((invitation) => invitation?.user?.id === runtimeEnv.user.id)?.ke;
    const acceptAppointmentInvitation = useAcceptAppointmentInvitationMutation(invitationKe);
    const rejectAppointmentInvitation = useRejectAppointmentInvitationMutation(invitationKe);

    return (
        <div className="flex">
            {
                showInvitationButtons &&
                <>
                    <Button label={REFUSE} className={`${invitation?.isRevocked ? 'btn-primary' : 'btn-default'}`} onClick={() => setRejectDialog(true)} />
                    <Button label={ACCEPT} className={`${invitation?.isValidated ? 'btn-primary' : 'btn-default'}`} onClick={acceptInvitation} />
                </>

            }
            <Dialog
                open={rejectDialog}
                title={REJECT_INVITATION}
                onClose={() => setRejectDialog(false)}>
                <ControlList
                    data={{
                        forms: [
                            {
                                type: "textarea",
                                name: "message",
                                label: "Message"
                            }
                        ]
                    }}
                    onCancel={() => setRejectDialog(false)}
                    onSubmit={rejectInvitation}
                />
            </Dialog>
        </div>
    )
}

const i18n = [
    "std_accept",
    "std_refuse",
    "Harea.Vacation.Edit.Reject.Message",
    "Harea.Vacation.Edit.Modify.Event.Title",
];

