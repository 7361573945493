import { useGraphqlQuery } from "@mzara/component";
import { TFormationChaptersResponse } from "@mzara/graphql";
import { FormationChapterListQuery } from "../queries/FormationChapterListQuery";

export const useFormationChapterListQuery = (
    formationId?: number,
    suspense?: boolean
) => {
    return useGraphqlQuery<
        FormationChapterListQuery,
        TFormationChaptersResponse
    >(
        new FormationChapterListQuery({
            data: { formation: { id: formationId } },
        }),
        { suspense: suspense || false }
    );
};
