import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectAppointmentCreateContainer from "./container/ProjectAppointmentCreateContainer";
import { ProjectAppointmentCreateEventRoutes } from "./views/event/ProjectAppointmentCreateEventRoutes";
import { ProjectAppointmentCreateMissingRoutes } from "./views/missing/ProjectAppointmentCreateMissingRoutes";
import { ProjectAppointmentCreateVacationRoutes } from "./views/vacation/ProjectAppointmentCreateVacationRoutes";
import { ProjectAppointmentCreatePlanningRoutes } from "./views/planning/ProjectAppointmentCreatePlanningContainer";

export const ProjectAppointmentCreateRoutes: Array<AppRouteProps> = [
    {
        path: "create",
        title: "Harea.Project.Appointment.Create.Label",
        element: <ProjectAppointmentCreateContainer />,
        children: [
            ...ProjectAppointmentCreateEventRoutes,
            ...ProjectAppointmentCreateMissingRoutes,
            ...ProjectAppointmentCreateVacationRoutes,
            ...ProjectAppointmentCreatePlanningRoutes
        ]
    },
    {
        path: "-1",
        title: "Harea.Project.Appointment.Create.Label",
        element: <ProjectAppointmentCreateContainer />,
        children: [
            ...ProjectAppointmentCreateEventRoutes,
            ...ProjectAppointmentCreateMissingRoutes,
            ...ProjectAppointmentCreateVacationRoutes,
            ...ProjectAppointmentCreatePlanningRoutes
        ]
    },
];
