import { Box, GenericSuspense, IconButton, Menu, RightSidebar, useUrlParamsValueToString } from "@mzara/component"
import { useEffect } from "react"
import { Link, Outlet, useNavigate, useParams } from "react-router-dom"
import { useAppointmentBoardViewerMenu } from "../hooks/useAppointmentBoardViewerMenu"

export const AppointmentViewerContainer = () => {

    const { id } = useParams()
    const menus = useAppointmentBoardViewerMenu(parseInt(id))
    const [, , , , , , , , tab] = location.pathname.split("/");
    const navigate = useNavigate()
    const urlParamsValueString = useUrlParamsValueToString()

    useEffect(() => {
        if (!menus.some((item) => item.ke === tab)) {
            navigate(menus[0].link, { replace: true })
        }
    }, [tab])

    return (
        <RightSidebar className="right-sidebar-absolute bg-white pb-8 shadow-md !z-[60]">
            <Link className="absolute top-0 right-0" to={`..?${urlParamsValueString}`}>
                <IconButton icon="fa-times" />
            </Link>
            <div>
                <Box className=' box-p-0 sticky-top overflow-x-auto overlow-y-hidden !p-0'>
                    <Menu type="HORIZONTAL" className='menuPublication' items={menus} activeFn={(menu) => menu.ke === tab} />
                </Box>
                <div className="pt-4">
                    <GenericSuspense>
                        <Outlet />
                    </GenericSuspense>
                </div>
            </div>
        </RightSidebar>
    )
}

