import { AppointmentDetailsInvitations } from "./AppointmentDetailsInvitations";
import { AppointmentDetailsMenu } from "./AppointmentDetailsMenu";
import { TAppointment } from "@mzara/graphql";

export const AppointmentDetailsToolbox = (props: AppointmentDetailsToolboxProps) => {

    

    return (
        <div className="flex">
            <AppointmentDetailsInvitations {...props} />

            <AppointmentDetailsMenu {...props} />
        </div>
        
    )
}

export type AppointmentDetailsToolboxProps = Partial<TAppointment> & {
    onUpdated?: () => void
    onDeleted?: () => void
}

const i18n = [
    "Harea.Vacation.Edit.Delete.Confirm.Message",
    "Harea.Calendar.Edit.DeleteOne.Button.Label",
    "Harea.Calendar.Edit.DeleteAll.Button.Label",
    "Harea.Vacation.Edit.Repetition.Confirm.Message",
    "std_confirmation",
    "std_cancel",
    "std_confirm",
    "std_accept",
    "std_refuse",
    "Harea.Vacation.Edit.Reject.Message",
    "Harea.Vacation.Edit.Modify.Event.Title",
    "Harea.Vacation.Edit.Title.Details.Event",
    'std_closed',
    'std_creator',
    'std_status',
    'std_opened'
];

