
import { Dialog, GenericSuspense, MainLogo, MenuItemProps, useTranslationRoute, useTranslations, useUrlParamsEncode, useUrlParamsState } from '@mzara/component';
import { DragDropContext, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAppointmentBoardQuery } from '../../../hooks/useAppointmentBoardQuery';
import { useAppointmentBoardGraphqlMutation } from 'views/kanban/hooks/useAppointmentBoardGraphqlMutation';
import { useCallback, useState } from 'react';
import { TAppointment, TListOfValue } from '@mzara/graphql';
import { useKanbanCards } from '../../../hooks/useKanbanCards';
import { useKanbanOpenedCards } from '../hooks/useKanbanOpenedCards';
import { useKanbanClosedCards } from '../hooks/useKanbanClosedCards';
import { useKanbanColumns } from '../hooks/useKanbanColumns';
import { useKanbanDragEnd } from '../hooks/useKanbanDragEnd';
import BarLoader from 'react-spinners/BarLoader';
import { KanbanColumn, KanbanColumnProps } from '../../../components/KanbanColumn';
import { KanbanAddColumn } from '../components/KanbanAddColumn';
import { useKanbanColumnMenu } from '../../../hooks/useKanbanColumnMenu';
import { useAppointmentGraphqlMutation } from 'views/kanban/hooks/useAppointmentGraphqlMutation';
import moment from 'moment';

export const ProjectKanbanDetailsBoardContainer = () => {

    const [
        OPENED,
        CLOSED,
        CONFIRMATION,
        OK,
        CANCEL,
        PARAMS,
        ARCHIVE,
        CONFIRM,
        CONFIRM_MESSAGE
    ] = useTranslations(i18n)

    const { id } = useParams()
    const { urlParams, setUrlParams } = useUrlParamsState()
    const encode = useUrlParamsEncode()
    const { data: board, invalidateQuery, isFetching } = useAppointmentBoardQuery(parseInt(id), false)
    const navigate = useNavigate()
    const tr = useTranslationRoute()
    const mutation = useAppointmentBoardGraphqlMutation()
    const archiveMutation = useAppointmentGraphqlMutation()
    const [columnToDelete, setColumnToDelete] = useState<TListOfValue>()
    const openedCards = useKanbanOpenedCards(id)
    const closedCards = useKanbanClosedCards(id)
    const columns = useKanbanColumns(id)
    const [columnToArchive, setColumnToArchive] = useState<KanbanColumnProps>()
    const columnMenuItems = useKanbanColumnMenu()

    const handleAddClick = useCallback(() => {
        navigate(`-1?${encode({
            ...urlParams,
            data: {
                board: { id: board?.id },
                noChoice: true,
                date: null,
                hourBegin: null,
                hourEnd: null,
            }
        })}`)
    }, [board, setUrlParams, id, openedCards])

    const handleCardClick = useCallback((e: TAppointment) => {
        navigate(`${e.num}?${encode(urlParams)}`)
    }, [board, setUrlParams, id])

    const handleAddColumnClick = useCallback((value: TListOfValue) => {
        const columnsOrder = JSON.parse(board?.columnsOrder ?? '[]')
        mutation.mutate({
            data: {
                id: board?.id,
                columns: [
                    ...board?.columns,
                    value
                ],
                columnsOrder: JSON.stringify([
                    ...columnsOrder,
                    value
                ])
            }
        }, {
            onSuccess: () => invalidateQuery()
        })
    }, [board])

    const handleMoveClick = useCallback((value: TListOfValue, direction: string) => {
        const columnsOrder = JSON.parse(board?.columnsOrder ?? '[]')
        const index = columnsOrder.findIndex((item) => item.id === value.id)
        let nextIndex = index
        if (index !== -1) {
            nextIndex = direction == 'LEFT' ? nextIndex - 1 : nextIndex + 1
        }

        if (nextIndex !== index && nextIndex >= 0 && nextIndex < columnsOrder.length) {
            columnsOrder.splice(index, 1)
            columnsOrder.splice(nextIndex, 0, value)
        }

        mutation.mutate({
            data: {
                id: board?.id,
                columnsOrder: JSON.stringify(columnsOrder)
            }
        }, {
            onSuccess: () => {
                invalidateQuery()
            }
        })
    }, [board])

    const handleDeleteColumnClick = useCallback(() => {
        const columnsOrder = JSON.parse(board?.columnsOrder ?? '[]')
        mutation.mutate({
            data: {
                id: board?.id,
                columns: board?.columns.filter((item) => item.id !== columnToDelete.id),
                columnsOrder: JSON.stringify(columnsOrder.filter((item) => item.id !== columnToDelete.id))
            }
        }, {
            onSuccess: () => {
                setColumnToDelete(null)
                invalidateQuery()
            }
        })
    }, [board, columnToDelete])

    const handleColumnToArchiveConfirm = useCallback(() => {
        archiveMutation.mutate({
            datas: columnToArchive.cards.map((item) => ({
                id: item.id,
                dateArchive: moment().format()
            }))
        }, {
            onSuccess: () => {
                invalidateQuery()
                setColumnToArchive(undefined)
            }
        })
    }, [board, columnToArchive])

    const handleMenuClick = useCallback((menu: MenuItemProps, column: KanbanColumnProps) => {
        if (menu.ke === 'DELETE') {
            setColumnToDelete(column.label)
        }
        if (menu.ke === 'MOVE' && ['LEFT', 'RIGHT'].includes(menu.value)) {
            handleMoveClick(column.label, menu.value)
        }

        if (menu.ke === 'ARCHIVE') {
            setColumnToArchive(column)
            
        }
    }, [setColumnToDelete, handleMoveClick])

    const handleDragEnd = useKanbanDragEnd(id);

    return (
        <DragDropContext
            onDragEnd={handleDragEnd}
        >
            <Droppable
                droppableId="board"
                type="COLUMN"
                direction="horizontal"
                ignoreContainerClipping
                isCombineEnabled>
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        // isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}
                    >
                        <div className="flex flex-col w-full max-w-full gap-4 relative pt-2" style={{ height: 'calc(100vh - 140px)' }}>
                            {isFetching && (
                                <div className="absolute top-0 left-0 right-0">
                                    <BarLoader
                                        className="main-spinner-bar-loader shadow-sm"
                                        width="100%"
                                    />
                                </div>
                            )}
                            <div className="grid grid-cols-12 h-full">
                                <div className="col-span-12 flex flex-row gap-4 w-full max-w-full overflow-x-auto pb-4">
                                    <KanbanColumn
                                        columnId="OPENED"
                                        index={0}
                                        boardId={board?.id}
                                        title={OPENED}
                                        cards={openedCards}
                                        isOpened
                                        canCreate={board?.isEditable as any}
                                        onAddClick={handleAddClick}
                                        onCardClick={handleCardClick}
                                    />
                                    {
                                        columns.map((item, index) => (
                                            <KanbanColumn
                                                key={item?.label?.id?.toString()}
                                                columnId={item?.label?.id?.toString()}
                                                {...item}
                                                index={index + 1}
                                                boardId={board?.id}
                                                menuItems={[
                                                    {
                                                        ke: 'ARCHIVE',
                                                        label: ARCHIVE,
                                                        startIcon: 'fa-solid fa-archive',
                                                    },
                                                    {
                                                        divide: true,
                                                    },
                                                    ...columnMenuItems,
                                                ]}
                                                onMenuClick={(menu) => handleMenuClick(menu, item)}
                                                onCardClick={handleCardClick}
                                            />
                                        ))
                                    }

                                    <KanbanColumn
                                        columnId="CLOSED"
                                        index={columns.length + 1}
                                        boardId={board?.id}
                                        title={CLOSED}
                                        cards={closedCards}
                                        menuItems={[
                                            {
                                                ke: 'ARCHIVE',
                                                label: ARCHIVE,
                                                startIcon: 'fa-solid fa-archive',
                                            },
                                        ]}
                                        isClosed
                                        onMenuClick={(menu) => handleMenuClick(
                                            menu,
                                            {
                                                index: columns.length + 1,
                                                title: CLOSED,
                                                isClosed: true,
                                                cards: closedCards
                                            }
                                        )}
                                        onCardClick={handleCardClick}
                                    />
                                    {
                                        board?.isEditable &&
                                        <KanbanAddColumn
                                            columns={board?.columns}
                                            onAddClick={handleAddColumnClick}
                                        />
                                    }
                                </div>
                            </div>
                            <Dialog
                                open={Boolean(columnToDelete)}
                                title={CONFIRMATION}
                                onCancel={() => setColumnToDelete(null)}
                                onClose={() => setColumnToDelete(null)}
                                onConfirm={handleDeleteColumnClick}
                                btnOk={{
                                    label: OK,
                                    isSubmit: mutation.isLoading
                                }}
                                btnCancel={{
                                    label: CANCEL,
                                }}
                            />
                            <GenericSuspense fallback={<MainLogo />}>
                                <Outlet />
                            </GenericSuspense>

                            <Dialog
                                open={columnToArchive !== undefined}
                                title={CONFIRM}
                                message={CONFIRM_MESSAGE}
                                onCancel={() => setColumnToArchive(undefined)}
                                onClose={() => setColumnToArchive(undefined)}
                                onConfirm={handleColumnToArchiveConfirm}
                                btnOk={{
                                    label: OK,
                                    isSubmit: archiveMutation.isLoading
                                }}
                                btnCancel={{
                                    label: CANCEL,
                                }}
                            />
                        </div>
                        {provided.placeholder}
                    </div>
                )}


            </Droppable>
        </DragDropContext>
    )
}

const i18n = [
    "Harea.Vacation.Kanban.Column.Opened.Title",
    "Harea.Vacation.Kanban.Column.Closed.Title",
    'std_confirmation',
    'std_ok',
    'std_cancel',
    'std_params',
    'std_archive',
    'std_confirm',
    "Harea.Vacation.Kanban.Column.Archive.Confirmation.Message",
];
