import { AppRouteProps } from "@mzara/component";
import React from "react";
import { PublicationRoutes } from "./views/publication/PublicationRoutes";
import { CurriculumVitaeRoutes } from "./views/CV/CurriculumVitaeRoutes";
import { PublicationDetailsRoutes } from "./views/publication-details/PublicationDetailsRoutes";
import { CvDetailsRoutes } from "./views/CV-details/CvDetailsRoutes";
import { PublicationNewRoutes } from "./views/publication-new/PublicationNewRoute";
const RecruitingContainer = React.lazy(
    () => import("./containers/RecruitingContainer")
);

export const RecruitingAdminRoutes: Array<AppRouteProps> = [
    {
        path: "recruiting",
        title: "Harea.Admin.LeftSideBar.Menu.Recruiting.Advert.Label",
        element: <RecruitingContainer />,
        children: [
            ...PublicationRoutes,
            ...PublicationDetailsRoutes,
            ...PublicationNewRoutes,
            ...CurriculumVitaeRoutes,
            ...CvDetailsRoutes,
        ],
    },
];
