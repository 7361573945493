import {
    Box,
    Metadata,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";
import { useRecruitingEvaluationDetailsQuery } from "../hooks/useRecruitingEvaluationDetailsQuery";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

const RecruitingEvaluationDetails = () => {
    const { id } = useParams();

    const [EMAIL, ADVERT, APPLICANT] = useTranslations(i18n);
    const tr = useTranslationRoute();

    const { data } = useRecruitingEvaluationDetailsQuery(+id);
    return (
        <Box
            className="h-full"
            title={data?.name}
            tools={
                <Link to={tr(`admin/evaluation/recruiting/${id}`)}>
                    <i className="fa-solid fa-eye"></i>
                </Link>
            }
            description={
                <p className="flex items-center gap-1">
                    <i className="fa-solid fa-clock"></i>
                    <span>{moment(+data?.createdAt).format("DD/MM/YYYY")}</span>
                </p>
            }
        >
            <ul
                key={`recruiting-evaluation-viewer-${id}`}
                className="mt-4 flex flex-col gap-3"
            >
                <li>
                    <Metadata label={EMAIL} value={data?.email} />
                </li>
                <li>
                    <Metadata
                        label={ADVERT}
                        value={data?.recruitingAdvert?.title}
                    />
                </li>
                <li>
                    <Metadata
                        label={APPLICANT}
                        value={data?.curiculumVitae?.name}
                    />
                </li>
            </ul>
        </Box>
    );
};

export default RecruitingEvaluationDetails;

const i18n = [
    "std_email_adress",
    "Generic.de.RECRUTING_ADVERT",
    "std_applicant",
];
