import { Menu } from "@mzara/component";
import { useLeftSideMenuItems } from "../hooks/useLeftSideMenuItems";

interface LeftSideMenuProps {}

const LeftSideMenu = () => {
    const menuItems = useLeftSideMenuItems();

    return (
        <aside className="h-full absolute top-0 left-4 w-max">
            <Menu items={menuItems} />
        </aside>
    );
};

export default LeftSideMenu;
