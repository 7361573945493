import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectAppointmentCreateEventContainer from "./container/ProjectAppointmentCreateEventContainer";

export const ProjectAppointmentCreateEventRoutes: Array<AppRouteProps> = [
    {
        path: "event",
        title: "Harea.Project.Appointment.Create.Event.Label",
        element: <ProjectAppointmentCreateEventContainer />,
    },
];
