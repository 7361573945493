import {
    MenuItemProps,
    useRuntimeEnv,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";
import { useMemo } from "react";
import { useLangMenuItems } from "../../layout/hooks/useLangMenuItems";

export const useAvatarMenuItems = () => {
    const { runtimeEnv } = useRuntimeEnv();
    const [PROFILE, LOGOUT] = useTranslations(i18n);
    const translationRoute = useTranslationRoute();
    const itemsLang = useLangMenuItems();
    const isInAdmin = location.pathname.split("/").includes("admin");

    return useMemo((): Array<MenuItemProps> => {
        return [
            {
                label: PROFILE,
                link: translationRoute(
                    `${isInAdmin ? "admin/" : ""}user/${runtimeEnv?.user
                        .id}/about`
                ),
                startIcon: "fa-solid fa-user",
            },
            {
                divide: true,
            },
            ...itemsLang,
            {
                divide: true,
            },
            {
                label: LOGOUT,
                "data-testid": "avatar-menu-logout",
                link: translationRoute(`logout`),
                startIcon: "fa-solid fa-right-from-bracket",
            },
        ];
    }, []);
};

const i18n = ["std_profil", "std_logout"];
