import { AppRouteProps } from "@mzara/component";
import DiscussionContainer from "./container/DiscussionContainer";

export const DiscussionRoutes: Array<AppRouteProps> = [
    {
        path: "discussion",
        title: '{{Generic_Auth_Cv_details_title_Tab}}',
        element: <DiscussionContainer />,
    },
];
