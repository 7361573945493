import {
    BreadCrumb,
    Button,
    GenericSuspense,
    IconButton,
    useTranslation,
    useTranslationRoute,
} from "@mzara/component";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import FormationList from "../components/FormationList";
import FormationListFilter from "../components/FormationListFilter";
import { useFormationBreadcrumb } from "../hooks/useFormationBreadcrumb";

const FormationContainer = () => {
    const [openFilter, setOpenFilter] = useState(false);

    const t = useTranslation();
    const tr = useTranslationRoute();

    const breadcrumbItems = useFormationBreadcrumb();

    return (
        <>
            <section className="main-content">
                <div className="flex items-center justify-between">
                    <h4 className="font-semibold">
                        {t("Harea.Formation.List.Page.Title")}
                    </h4>

                    <Link to={tr("admin/formation/create")}>
                        <Button
                            className="btn-primary"
                            label={t("std_add")}
                            startIcon="fa-plus"
                        />
                    </Link>
                </div>

                <BreadCrumb items={breadcrumbItems} />

                <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                        <div className="flex gap-2">
                            {/* <Button
                                label={t("std_this_month")}
                                endIcon="fa-solid fa-caret-down"
                                className="!bg-[#D9D9D9] button-rounded-full text-[#3E3A3A] [&>i]:text-white"
                            /> */}

                            <IconButton
                                icon="fa fa-filter"
                                onClick={() => setOpenFilter((v) => !v)}
                            />
                        </div>
                    </div>

                    <FormationList id={FORMATION_LIST_ID} />
                </div>
            </section>

            <FormationListFilter listId={FORMATION_LIST_ID} open={openFilter} />

            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </>
    );
};

export default FormationContainer;

type FormationContainerProps = {};

export const FORMATION_LIST_ID = "formation-list-id";
