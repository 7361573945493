import { AppRouteProps } from "@mzara/component";
import ClientContainer from "./container/ClientContainer";
import { EvaluationRoutes } from "./views/evaluation/EvaluationRoutes";

export const EvaluationClientRoutes: Array<AppRouteProps> = [
    {
        path: "evaluation",
        title: "Client.Evaluation",
        element: <ClientContainer />,
        children: [...EvaluationRoutes],
    },
];
