import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectKanbanDetailsRoutes } from "./views/details/ProjectKanbanDetailsRoutes";
import { ProjectKanbanCreateRoutes } from "./views/create/ProjectKanbanCreateRoutes";
const KanbanContainer = React.lazy(() => import("./container/KanbanContainer"));

export const ProjectKanbanRoutes: Array<AppRouteProps> = [
    {
        path: "kanban",
        title: "Harea.Project.Kanban.Title",
        element: <KanbanContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectKanbanCreateRoutes
        ]
    },
    {
        path: "kanban",
        title: "Harea.Project.Kanban.Title",
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectKanbanDetailsRoutes
        ]
    },
];
