import { ControlListProps, useTranslations } from "@mzara/component";

export const useFormationAddForm = (): ControlListProps => {
    const [TITLE, CATEGORY, PUBLIC, MINIATURE, CANCEL, ADD] =
        useTranslations(i18n);

    return {
        id: "formation-add-form",
        data: {
            forms: [
                { type: "text", name: "title", label: TITLE },
                {
                    type: "de-select",
                    name: "category",
                    label: CATEGORY,
                    tp: "FORMATION_CATEGORY",
                    required: true,
                },
                { type: "toggle", name: "isPublic", label: PUBLIC },
                { type: "image", name: "miniature", label: MINIATURE },
            ],
            back: { label: CANCEL },
            next: { label: ADD },
            // graphQL: { entity: "formation" },
        },
    };
};

const i18n = [
    "std_title",
    "std_category",
    "std_public",
    "Harea.Formation.Add.Form.Miniature.Field.Label",
    "std_cancel",
    "std_add",
];
