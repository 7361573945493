import { AppRouteProps } from "@mzara/component";
import React from "react";

const ParameterContainer= React.lazy(() => import('./containers/ParameterContainer'))

export const ParametersRoutes : Array<AppRouteProps> = [
    { 
        path: 'parameters', 
        title: '{{Generic_Auth_Cv_details_title_Tab}}',
        element: <ParameterContainer />,
    }
]