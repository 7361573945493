import {
    useAjax,
    useGraphqlMutation,
    useMzMutation,
    useTranslation,
} from "@mzara/component";
import { AjaxResponseType } from "@mzara/lib";

export const useSaveAdvertEvaluationMutation = () => {
    const t = useTranslation();
    const ajax = useAjax();
    const { mutate, isLoading, isSuccess } = useMzMutation<AjaxResponseType>(
        (payload: Record<string, any>) =>
            ajax.send({
                type: "POST",
                content_type: "application/json",
                data: payload,
                url: "evaluation/email",
            }),
        {
            transformErrorMessage: (e) => {
                if (e.message === "EMAIL_INVALID") {
                    return t("std_invalid_email_message");
                }
                if (e.message === "EMAIL_NOT_SENT") {
                    return t("std_not_sent_email_message");
                }
                return undefined;
            },
            success_message: t("Harea.Recruiting.Advert.Details.EvaluationSend.Success.Message")
        }
    );
    return { saveAdvertEvaluation: mutate, isLoading, isSuccess };
};

const gql = `
    mutation saveAdvertEvaluation($data: JSONObject) {
        saveEvaluation(data: { data: $data }) {
            id
        }
    }
`;
