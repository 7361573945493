import {
    Avatar,
    Box,
    Chip,
    Metadata,
    useFileUrl,
    useTranslation,
} from "@mzara/component";
import { useFormationDetailsQuery } from "../../../../../../../hooks/useFormationDetailsQuery";
import { useParams } from "react-router-dom";

const AboutContainer = () => {
    const { id } = useParams();

    const getMiniatureById = useFileUrl();
    const t = useTranslation();

    const { data } = useFormationDetailsQuery(+id, true);

    return (
        <Box>
            <div className="flex items-start justify-between mb-5">
                <div className="flex items-start gap-3">
                    <Avatar
                        className="rounded-md"
                        size="lg"
                        src={getMiniatureById(data?.miniature?.id)}
                    />

                    <h3>{data?.title}</h3>
                </div>

                {data?.isPublic && (
                    <Chip className="bg-primary" label={t("std_public")} />
                )}
            </div>

            <Metadata
                label={t("std_categorie")}
                value={data?.category?.value}
            />
        </Box>
    );
};

export default AboutContainer;

type AboutContainerProps = {};
