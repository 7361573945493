import {
    BreadCrumb,
    GenericSuspense,
    Menu,
    useTranslation,
} from "@mzara/component";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useFormationDetailsBreadcrumbItems } from "../hooks/useFormationDetailsBreadcrumbItems";
import { useFormationDetailsMenuItems } from "../hooks/useFormationDetailsMenuItems";
import { useFormationDetailsQuery } from "../../../../../hooks/useFormationDetailsQuery";

const FormationDetailsContainer = () => {
    const { id } = useParams();
    const location = useLocation();

    const t = useTranslation();

    const { data } = useFormationDetailsQuery(+id, true);
    const breadcrumbItems = useFormationDetailsBreadcrumbItems(data?.title);
    const menuItems = useFormationDetailsMenuItems(+id);

    const tab = location.pathname.split("/").at(-1);

    return (
        <section className="main-content">
            <div className="flex items-center justify-between">
                <h4 className="font-semibold">
                    {t("Harea.Formation.Details.Page.Title")}
                </h4>
            </div>

            <BreadCrumb items={breadcrumbItems} />

            <Menu
                items={menuItems}
                type="HORIZONTAL"
                activeFn={(menu) => menu.ke === tab}
                className="menu-tabs"
            />

            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </section>
    );
};

export default FormationDetailsContainer;
