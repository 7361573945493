import { AppRouteProps } from "@mzara/component";
import MainPageContainer from "./container/MainPageContainer";

export const MainPageRoutes: Array<AppRouteProps> = [
    {
        path: "main-page",
        title: "Harea.Formation.Details.MainPage.Title",
        element: <MainPageContainer />,
    },
];
