import { useGraphqlQuery } from "@mzara/component";
import { TRecruitingAdvert } from "@mzara/graphql";
import { PublicationViewerQuery } from "../query/PublicationViewerQuery";

export const usePublicationViewerQuery = (id: number, suspense?: boolean) => {
    return useGraphqlQuery<PublicationViewerQuery, TRecruitingAdvert>(
        new PublicationViewerQuery({ id }),
        {
            suspense: suspense || true,
        }
    );
};
