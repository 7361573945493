import { AppRouteProps } from "@mzara/component";
import RecruitingDetailsContainer from "./container/RecruitingDetailsContainer";
import { DetailsRoutes } from "./views/details/DetailsRoutes";
import { ParametersRoutes } from "./views/paramaters/ParametersRoutes";

export const RecruitingDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "{{EvaluationRecruitingDetailsTitle}}",
        element: <RecruitingDetailsContainer />,
        children: [
            ...DetailsRoutes,
            ...ParametersRoutes
        ],
    },
];
