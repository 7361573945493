import { AppRouteProps } from "@mzara/component";
import React from "react";

const UserAgendaContainer = React.lazy(() => import("./container/UserAgendaContainer"))

export const UserAgendaRoute: Array<AppRouteProps> = [
    {
        title: '{{UserNameDetailsTab}}',
        element: <UserAgendaContainer />,
        path: "agenda"
    }
]