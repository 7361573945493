import { useCallback } from "react"
import { KanbanCards } from "../../../state/KanbanCards"
import { useRecoilState } from "recoil"
import { TAppointment, TListOfValue } from "@mzara/graphql"
import update from 'immutability-helper'
import _ from "lodash"

export const useKanbanCardsMove = (id: string) => {

    const [cards, setCards] = useRecoilState(KanbanCards(id))
    return useCallback(({ dragId, hoverId, dragDroppableId, hoverDroppableId, callback }: Params) => {

        setCards((v: TAppointment[]) => {
            const prevCards = _.cloneDeep(v)

            const dragIndex = prevCards.findIndex((item) => item.id === dragId);
            const hoverIndex = prevCards.findIndex((item) => item.id === hoverId);

            // Treat columns
            const draggedItem = prevCards[dragIndex];
            draggedItem.isClosed = hoverDroppableId === 'CLOSED'
            if (dragDroppableId !== hoverDroppableId) {
                draggedItem.labels = [
                    ...draggedItem.labels
                        .filter((item) => item.id?.toString() !== dragDroppableId),
                ]
            }
            
            if (!['OPENED', 'CLOSED'].includes(hoverDroppableId)) {
                draggedItem.labels.push({ id: parseInt(hoverDroppableId) } as any)
            }

            callback({
                dragId,
                dragDroppableId,
                hoverDroppableId,
                hoverId,
                labels: draggedItem.labels,
                isClosed: draggedItem.isClosed
            })

            if (dragIndex !== -1 && hoverIndex !== -1) {
                return update(prevCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, draggedItem as TAppointment],
                    ],
                })
            }

            return prevCards

        })
    }, [setCards, cards])
}

type Params=  {
    dragId: number, 
    hoverId: number,
    dragDroppableId: string,
    hoverDroppableId: string,
    labels?: Array<TListOfValue>
    isClosed?: boolean
    callback?: (item: Params) => void
}
