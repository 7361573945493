import { Link, Outlet, useParams } from "react-router-dom"
import { Button, List, useListFilterState, useTranslations } from "@mzara/component"
import { KanbanMilestonesQuery } from "../query/KanbanMilestonesQuery"
import { MilestoneQuickFilters } from "../components/MilestoneQuickFilters"
import { useMilestoneListSort } from "../hooks/useMilestoneListSort"
import { TAppointmentMilestone } from "@mzara/graphql"
import { MilestoneRowItem } from "../components/MilestoneRowItem"
import { useEffect } from "react"

export const ProjectKanbanDetailsMilestoneListContainer = () => {

    const [
        TITLE,
        ADD
    ] = useTranslations(i18n)
    const { id } = useParams()
    const sortItems = useMilestoneListSort()
    const { filter, setFilter } = useListFilterState(MILESTONE_LIST_ID);

    useEffect(() => {
        if (
            filter?.data?.board?.id === undefined
        ) {
            setFilter((v: any) => ({
                ...v,
                data: {
                    ...v.data,
                    board: { id: parseInt(id) },
                },
            }));
        }
    }, [filter])

    return (
        <section className="main-content">
            <div className="flex flex-col gap-4">
                <div className="flex justify-between">
                    <h3>{TITLE}</h3>
                    <div className="flex gap-2 items-center">
                        <MilestoneQuickFilters />

                        <Link
                            to={'create'}
                        >
                            <Button
                                className="btn-primary"
                                label={ADD}
                                startIcon="fa-solid fa-plus"
                            />
                        </Link>
                    </div>
                </div>
                <List
                    id={MILESTONE_LIST_ID}
                    sort={{ items: sortItems }}
                    selection={false}
                    pagination={false}
                    GQLRequest={KanbanMilestonesQuery}
                    BoxProps={{
                        className: 'box-transparent !bg-transparent shadow-none flex justify-between flex-wrap'
                    }}
                    defaultFilter={{
                        dateArchive_isNull: true,
                        dateClosed_isNull: true
                    }}
                    onRenderRow={(row: TAppointmentMilestone) => (
                        <MilestoneRowItem
                            {...row}
                        />
                    )}
                />
                <Outlet />
            </div>
        </section>
    )
}

export const MILESTONE_LIST_ID = 'Milestone.List'

const i18n = [
    'Harea.Project.Kanban.Details.Milestone',
    'std_add'
]
