import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectKanbanDetailsGanttContainer from "./container/ProjectKanbanDetailsMilestoneContainer";
import { ProjectKanbanCardDetailsRoutes } from "../../../details/ProjectKanbanCardDetailsRoutes";
import { ProjectKanbanMilestoneAboutRoutes } from "./views/about/ProjectKanbanMilestoneAboutRoutes";
import { ProjectKanbanDetailsBoardRoutes } from "../../../board/ProjectKanbanDetailsBoardRoutes";
import { ProjectKanbanMilestoneParamsRoutes } from "./views/params/ProjectKanbanMilestoneParamsRoutes";
import { ProjectKanbanDetailsGanttRoutes } from "../../../gantt/ProjectKanbanDetailsGanttRoutes";


export const ProjectKanbanDetailsMilestoneDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":milestoneId",
        title: "{{AppointmentBoardMilestoneTitle}}",
        element: <ProjectKanbanDetailsGanttContainer />,
        children: [
            ...ProjectKanbanDetailsBoardRoutes,
            ...ProjectKanbanMilestoneAboutRoutes,
            ...ProjectKanbanMilestoneParamsRoutes,
            ...ProjectKanbanDetailsGanttRoutes
        ]
    },
];
