import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectAppointmentCreateVacationContainer from "./container/ProjectAppointmentCreateVacationContainer";

export const ProjectAppointmentCreateVacationRoutes: Array<AppRouteProps> = [
    {
        path: "vacation",
        title: "Harea.Project.Appointment.Create.Vacation.Label",
        element: <ProjectAppointmentCreateVacationContainer />
    },
];
