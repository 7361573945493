import { GenericSuspense, FallbackProps } from "@mzara/component"
import { PublicStaticPage } from "components/PublicStaticPage"
import React, { useCallback } from "react"
import { useParams } from "react-router-dom"

export const PublicStaticPageContainer = (props: Props) => {

    const { permalink } = useParams()

    const fallbackError = useCallback((params: FallbackProps) => {

        return React.cloneElement(props.errorComponent!, params)
    }, [props])

    return (
        <GenericSuspense fallbackError={props.errorComponent ? fallbackError : undefined}>
            <PublicStaticPage permalink={permalink} />
        </GenericSuspense>
    )
}

type Props = {
    errorComponent?: React.ReactElement
}

