import { Thread } from "@mzara/thread";
import { useParams } from "react-router-dom";
import { useAdvertDetailsQuery } from "../../../../../../../hooks/useAdvertDetailsQuery";

const DiscussionContainer = () => {
    const { id } = useParams();

    const { data } = useAdvertDetailsQuery({ id: +id, suspense: true });
    return (
        <div data-testid="recruiting-advert-details-tab-discussion">
            <Thread
                id={data?.thread?.id}
                commentEnabled={!data?.thread?.disableComment}
                isEnabled={!data?.disableThread}
                className="p-0"
            />
        </div>
    );
};

export default DiscussionContainer;
