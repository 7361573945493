import { BreadCrumb, IconButton, useTranslation } from "@mzara/component";
import { useBreadCrumbItems } from "../hooks/useBreadCrumbItems";
import { useState } from "react";
import FormationList from "../components/FormationList";
import LeftSideMenu from "../components/LeftSideMenu";

interface FormationContainerProps {}

const FormationContainer = () => {
    const [openFilter, setOpenFilter] = useState(false);

    const t = useTranslation();

    const breadCrumbItems = useBreadCrumbItems();

    return (
        <div className="relative h-full py-5">
            <LeftSideMenu />

            <div className="mx-auto max-w-2xl w-full h-full">
                <div className="flex flex-col gap-5 flex-1">
                    <h4 className="font-semibold">
                        {t("Harea.Client.Formation.List.Page.Title")}
                    </h4>

                    <div className="flex">
                        <BreadCrumb items={breadCrumbItems} />
                    </div>

                    <div className="ml-auto">
                        <IconButton
                            icon={"fa-solid fa-filter"}
                            onClick={() => setOpenFilter(!openFilter)}
                            className="text-[#6D6D6D]"
                        />
                    </div>

                    <FormationList />
                </div>
            </div>
        </div>
    );
};

export default FormationContainer;
