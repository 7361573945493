import { AppRouteProps } from "@mzara/component";
import React from "react";
const ProjectDayOffViewsSummaryContainer = React.lazy(
    () => import("./container/ProjectDayOffViewsSummaryContainer")
);

export const ProjectDayOffViewsSummaryRoutes: Array<AppRouteProps> = [
    {
        path: "summary",
        title: "Harea.Vacation.DayOff.Summary",
        element: <ProjectDayOffViewsSummaryContainer />,
        roles: ["APPOINTMENT"],
    },
];
