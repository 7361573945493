import React from "react";
import { Outlet } from "react-router-dom";
import { ClientNavBar as NavBar } from "./ClientNavBar";
import { GenericSuspense } from "@mzara/component";

const ClientLayout = () => {
    return (
        <div className="client-layout min-h-screen bg-body-background">
            <NavBar />

            <main>
                <GenericSuspense>
                    <Outlet />
                </GenericSuspense>
            </main>
        </div>
    );
};

export default ClientLayout;
