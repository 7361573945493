import { useAjax, useMzMutation, useTranslation } from "@mzara/component";
import { AjaxResponseType } from "@mzara/lib";

export const useStartEvaluationMutation = () => {
    const t = useTranslation();
    const ajax = useAjax();

    return useMzMutation<AjaxResponseType>(
        (payload: Record<string, any>) =>
            ajax.send({
                type: "PUT",
                content_type: "application/json",
                url: "evaluation/begin",
                data: payload,
            }),
        {
            transformErrorMessage: (error) => {
                if (error.xhr === 403) {
                    return t(
                        "Harea.Evaluation.Forbidden.Use.Form.Error.Message"
                    );
                }

                if (error.xhr === 401) {
                    return t(
                        "Harea.Evaluation.Unauthorized.InProgress.Form.Error.Message"
                    );
                }

                return undefined;
            },
        }
    );
};
