import { AppRouteProps } from "@mzara/component";
import React from "react";

const PayslipPastMonthContainer = React.lazy(() => import("./container/PayslipPastMonthContainer"));
export const PayslipPastMonthRoute = [
    {
        path: "past-month",
        element: <PayslipPastMonthContainer />,
        roles: ["PAYING_SLIP"]

    },
] as Array<AppRouteProps>;
