import { MenuItemProps, useTranslation } from "@mzara/component";
import { useEvaluationStateLabel } from "hooks/useEvaluationStateLabel";
import { useMemo } from "react";

export const useStateFilterMenuItems = (): MenuItemProps[] => {
    const t = useTranslation();
    const getEvaluationStateLabel = useEvaluationStateLabel();

    return useMemo(
        () => [
            { id: 0, ke: "all", label: t("std_all") },
            ...states.map((state, i) => ({
                id: i + 1,
                ke: state?.toLowerCase(),
                label: getEvaluationStateLabel(state),
            })),
        ],
        [states]
    );
};

const states = ["NEW", "IN_PROGRESS", "FINISHED"];
