import { ControlList } from "@mzara/component"
import { useParams } from "react-router-dom"
import { useAppointmentBoardDangerZoneForm } from "../../params/hooks/useAppointmentBoardDangerZoneForm"

export const ProjectKanbanDetailsDangerContainer = () => {

    const { id } = useParams()
    const control =  useAppointmentBoardDangerZoneForm(parseInt(id));

    return (
        <ControlList
            {...control}
        />
    )
}
