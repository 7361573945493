import { useUrlParamsValueToString } from "@mzara/component"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

export const useProjectAppointmentNavigateBack = () => {
    const urlParamsToString = useUrlParamsValueToString()
    const navigate = useNavigate()

    return useCallback(() => {
        navigate(`../..?${urlParamsToString}`);

    }, [urlParamsToString])
}
