import { ControlList } from "@mzara/component"
import { useKanbanCardDateForm } from "../hooks/useKanbanCardDateForm"

export const ProjectCardDateControl = (props: Props) => {

    const control = useKanbanCardDateForm(props.boardId)

    return (
        <ControlList
            {...control}
            defaultValue={{ id: props.id }}
            onSubmited={props.onSubmited}
            onCancel={props.onCancel}
            />
    )
}

type Props = {
    id: number
    boardId: number
    onCancel?: () => void
    onSubmited?: () => void
}
