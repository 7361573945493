import { AppRouteProps } from "@mzara/component";
import React from "react";
import { DetailContractAboutRoute } from "./views/about/DetailContractAboutRoute";


const DetailContractContainer = React.lazy(
    () => import("./container/DetailContractContainer")
);

export const DetailContractRoutes: Array<AppRouteProps> = [
    {
        path: "contract/:id",
        title: "{{ContractUserNameFull}}",
        element: <DetailContractContainer />,
        children: [...DetailContractAboutRoute],
        roles: ["CONTRACT"],
    },
];
