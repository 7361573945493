import { useGraphqlMutation } from "@mzara/component";

export const useRecruitingAdvertPublishMutation = () => {
    return useGraphqlMutation(gql);
};

const gql = `
    mutation recruitingAdvertPublishMutation($data: JSONObject) {
        saveRecruitingAdvert(data: { data: $data }) {
            id
        }
    }
`;
