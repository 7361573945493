import { useMemo } from "react"
import { useKanbanCards } from "../../../hooks/useKanbanCards"
import { useKanbanColumnsOrder } from "./useKanbanColumnsOrder"

export const useKanbanClosedCards = (id: string) => {
    const cards = useKanbanCards(id)
    const columnsOrder = useKanbanColumnsOrder(id)

    return useMemo(() => {
        return cards?.filter((item) => {
            return item.isClosed
        }) ?? []
    }, [cards, columnsOrder])
}
