import {
    Box,
    Chip,
    ControlSkeleton,
    DateSpan,
    DonutChart,
    GenericSuspense,
    MDEditor,
    Metadata,
    useTranslations,
} from "@mzara/component";
import { Link, useParams } from "react-router-dom";
import { useKanbanMilestoneQuery } from "../../../hooks/useKanbanMilestoneQuery";
import { MilestoneState } from "../../../../list/components/MilestoneState";

const ProjectKanbanMilestoneAboutContainer = () => {

    const [
        CREATOR,
        STATUS,
        CREATED_AT,
        DATE_END,
        LABELS,
        DESCRIPTION,
        PROGRESS,
        TICKET_OPENED,
        TICKET_CLOSED,
        ALL_TICKETS
    ] = useTranslations(i18n)
    const { milestoneId } = useParams()
    const { data } = useKanbanMilestoneQuery(parseInt(milestoneId), true)

    return (
        <section className="main-content">
            <Box className="flex flex-col gap-6 relative">
                <div className="w-full h-[3px] absolute top-0 left-0 right-0" style={{ backgroundColor: data.color }}></div>
                <div className="relative">
                    <h3>{data.title}</h3>
                    <div className="w-20 h-20 absolute top-0 right-0">
                        <DonutChart
                            label={PROGRESS}
                            value={data.progress as any}
                        />
                    </div>
                </div>
                <div className="flex justify-between flex-wrap-reverse">
                    <div className="flex gap-4 flex-wrap">
                        <Metadata
                            label={CREATOR}
                            value={(
                                <Link to={`user/${data?.userCreator.id}`}>
                                    <Chip
                                        userId={data?.userCreator?.id}
                                        label={data?.userCreator?.fullName}
                                    />
                                </Link>
                            )}
                        />
                        <Metadata
                            label={STATUS}
                            value={(
                                <MilestoneState {...data} />
                            )}
                        />
                        {
                            data.labels?.length > 0 &&
                            <Metadata
                                label={LABELS}
                                className="flex-col items-start"
                                value={(
                                    <div className="flex gap-2 flex-wrap">
                                        {
                                            data.labels?.map((item, index) => (
                                                <Chip
                                                    key={index}
                                                    className="bg-white px-2 py-0 text-xs"
                                                    label={item.value}
                                                    color={item.color}
                                                />
                                            ))
                                        }
                                    </div>
                                )}
                            />
                        }
                    </div>

                </div>


                <div className="flex gap-4">
                    {
                        data.createdAt &&
                        <Metadata
                            icon="fa-clock"
                            value={(
                                <DateSpan value={data.createdAt} />
                            )}
                            title={CREATED_AT}
                        />
                    }
                    {
                        data.dateBegin &&
                        <Chip
                            startIcon="fa-clock"
                            className="bg-default"
                            label={
                                <DateSpan value={data.dateBegin} format="DD-MM-YYYY" />
                            }
                            title={DATE_END}
                        />
                    }
                    {
                        data.dateEnd &&
                        <Chip
                            startIcon="fa-clock"
                            className="bg-danger text-white"
                            label={
                                <DateSpan value={data.dateEnd} format="DD-MM-YYYY" />
                            }
                            title={DATE_END}
                        />
                    }
                </div>

                <div className="flex justify-between">
                    <Metadata
                        label={TICKET_OPENED}
                        value={
                            <Chip
                                className="bg-default px-2 py-0 text-xs"
                                label={data.openTicketsCount as any}
                            />
                        }
                    />
                    <Metadata
                        label={TICKET_CLOSED}
                        value={
                            <Chip
                                className="bg-default px-2 py-0 text-xs"
                                label={data.closedTicketsCount as any}
                            />
                        }
                    />
                    <Metadata
                        label={ALL_TICKETS}
                        value={
                            <div>
                                <Chip
                                    className="bg-default px-2 py-0 text-xs"
                                    label={data.ticketsCount as any}
                                />
                            </div>
                        }
                    />
                </div>

                <div className="flex flex-col gap-2">
                    <h3>{DESCRIPTION}</h3>

                    <GenericSuspense fallback={<ControlSkeleton />}>
                        <MDEditor readonly value={data.description ?? ''} />
                    </GenericSuspense>
                </div>
            </Box>
        </section>
    );
}

const i18n = [
    'std_creator',
    'std_status',
    'std_created_at',
    'std_date_end',
    'std_labels',
    'std_description',
    'std_progress',
    'Harea.Project.Kanban.Details.Milestone.Tickets.Opened.Number',
    'Harea.Project.Kanban.Details.Milestone.Tickets.Closed.Number',
    'Harea.Project.Kanban.Details.Milestone.Tickets.All.Number',
]

export default ProjectKanbanMilestoneAboutContainer
