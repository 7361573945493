import { AvatarGroup, Box, Chip, useFileUrl } from "@mzara/component"
import { TAppointment } from "@mzara/graphql"
import _ from "lodash"
import moment from "moment"
import { useMemo } from "react"

export const KanbanCard = ({ index, ...props }: KanbanCardsProps) => {

    const fileUrl = useFileUrl()

    const getDateLabel = (date?: string, hour?: string) => {
        return `${date ? `${moment(date).format('DD/MM')} ` : ''}${hour ?? ''}`
    }

    const users = useMemo(() => (
        props?.invitations?.map((item) => ({
            id: item.user.id,
            pictureId: item.user.id,
            title: item.user.fullName,
        })) ?? []
    ), [props])

    return (
        <div>
            <Box
                className={` p-0 relative cursor-pointer !rounded-md shadow-md`}
                onClick={() => props.onClick?.(_.omit(props, ['column']))}
            >
                {
                    props.cover?.id &&
                    <div
                        className="h-40 bg-cover bg-no-repeat bg-center"
                        style={{ backgroundImage: props.cover ? `url('${fileUrl(props.cover?.id)}')` : null }}>

                    </div>
                }
                <div className="flex flex-col gap-2 p-4">

                    <p title={props.title} className="line-clamp-2">
                        <span className="text-gray">#{props.num}</span> <span> • </span> <b>{props.title}</b>
                    </p>
                    {
                        !_.isEmpty(props.labels) &&
                        <div className="flex gap-2 flex-wrap">
                            {
                                props.labels?.map((item, index) => (
                                    <Chip
                                        key={index}
                                        className="bg-white px-2 py-0 text-xs"
                                        label={item.value}
                                        color={item.color}
                                    />
                                ))
                            }
                        </div>
                    }
                    {
                        (!_.isEmpty(props.invitations) || props.milestone) &&
                        <div className="flex gap-1 overflow-hidden">
                            <AvatarGroup
                                size="xs"
                                itemLimit={3}
                                avatarItems={users}
                                disableTooltip={props.disableAvatarTooltip}
                            />

                            {
                                props.milestone &&
                                <Chip
                                    className="px-4 py-0 text-xs"
                                    startIcon="fa-solid fa-bullseye"
                                    label={props.milestone?.title}
                                    color={props.milestone?.color}
                                />
                            }
                        </div>
                    }
                    {
                        (props.dateBegin || props.dateEnd || props.files?.length > 0) &&
                        <div className="flex gap-1 overflow-hidden justify-end">
                            {
                                (props.dateBegin || props.hourBegin) &&
                                <Chip className="bg-white px-2 py-0 text-xs" label={getDateLabel(props.dateBegin, props.hourBegin)} />
                            }
                            {
                                props.files?.length > 0 &&
                                <Chip
                                    className="px-4 py-0 text-xs"
                                    startIcon="fa-solid fa-paperclip"
                                    label={props.files.length.toString()} />
                            }
                        </div>
                    }

                </div>
            </Box>
        </div>

    )
}

export type KanbanCardsProps = Partial<TAppointment> & {
    index?: number
    disableAvatarTooltip?: boolean
    onClick?: (e: Partial<TAppointment>) => void
}
