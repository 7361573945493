import { GenericSuspense } from "@mzara/component";
import { Outlet } from "react-router-dom";

const ClientContainer = () => {
    return (
        <GenericSuspense>
            <Outlet />
        </GenericSuspense>
    );
};

export default ClientContainer;
