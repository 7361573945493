import { ChipMenu, MenuItemProps, useListFilterState, useTranslation } from "@mzara/component";
import { usePlanningListTypeMenuItems } from "../hooks/usePlanningListTypeMenuItems";
import { useCallback } from "react";

export const PlanningListFilter = (props: Props) => {

    const { filter, setFilter } = useListFilterState(props.listId, false);
    const t = useTranslation()
    const typeMenuItems = usePlanningListTypeMenuItems();

    const handleStateFilterClick = useCallback((menu: MenuItemProps) => {
        setFilter((v: any) => ({
            ...v,
            data: {
                ...v.data,
                type_among: menu.value,
            },
        }));
    }, [setFilter])

    return (
        <div className="flex items-center justify-end gap-2">

            <ChipMenu
                items={typeMenuItems}
                defaultLabel={t("std_all")}
                value={filter?.data?.type_among}
                onClick={handleStateFilterClick}
            />
        </div>
    );
}

type Props = {
    listId: string
}
