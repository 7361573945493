import { useGraphqlQuery } from "@mzara/component";
import { TProductLicense } from "@mzara/graphql";
import { LicenseRolesQuery } from "../query/LicenseRolesQuery";

export const useLicenseRolesQuery = (licenceKe: string, suspense?: boolean) => {
    return useGraphqlQuery<LicenseRolesQuery, TProductLicense>(
        new LicenseRolesQuery({ data: { ke: licenceKe } }),
        { suspense: suspense || false }
    );
};
