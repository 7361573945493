import { AppRouteProps } from "@mzara/component";
import PreviewContainer from "./container/PreviewContainer";

export const PreviewRoutes: Array<AppRouteProps> = [
    {
        path: "preview",
        title: "Harea.Formation.Details.Preview.Title",
        element: <PreviewContainer />,
    },
];
