import { AppRouteProps } from "@mzara/component";
import { ProjectKanbanDetailsInvitationContainer } from "./containers/ProjectKanbanDetailsInvitationContainer";
import { ProjectKanbanInvitationEditRoute } from "./views/edit/ProjectKanbanInvitationEditRoute";

export const ProjectKanbanDetailsInvitationRoute: Array<AppRouteProps> = [
    {
        path: 'invitation',
        element: <ProjectKanbanDetailsInvitationContainer />,
        children: [
            ...ProjectKanbanInvitationEditRoute
        ]
    }
]