import { BreadCrumb, Button, useTranslation } from "@mzara/component";
import FormationDetails from "components/FormationDetails";
import { useFormationDetailsQuery } from "hooks/useFormationDetailsQuery";
import { useParams } from "react-router-dom";
import LeftSideMenu from "../../formation/components/LeftSideMenu";
import ChaptersRightSideBar from "../components/ChaptersRightSideBar";
import { useBreadCrumbItems } from "../hooks/useBreadCrumbItems";

const FormationDetailsContainer = () => {
    const { id } = useParams();

    const t = useTranslation();
    const { data } = useFormationDetailsQuery(+id, true);
    const breadCrumbItems = useBreadCrumbItems(data?.title);

    return (
        <div className="relative h-screen py-5">
            <LeftSideMenu />

            <div className="mx-auto max-w-2xl w-full h-full">
                <div className="flex flex-col gap-5 flex-1">
                    <h4 className="font-semibold">
                        {t("Harea.Client.Formation.List.Page.Title")}
                    </h4>

                    <div className="flex">
                        <BreadCrumb items={breadCrumbItems} />
                    </div>

                    <FormationDetails data={data} />

                    <div className="flex justify-between">
                        <Button
                            className="bg-white"
                            startIcon="fa-angle-left"
                            label="Précédent"
                        />

                        <Button
                            className="bg-white"
                            endIcon="fa-angle-right"
                            label="Suivant"
                        />
                    </div>
                </div>
            </div>

            <ChaptersRightSideBar formationId={data?.id} />
        </div>
    );
};

export default FormationDetailsContainer;

type FormationDetailsContainerProps = {};
