import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectKanbanDetailsDetailRoutes } from "./views/details/ProjectKanbanDetailsDetailRoutes";
import { ProjectKanbanDetailsHistoryRoutes } from "./views/history/ProjectKanbanDetailsHistoryRoutes";

const ProjectKanbanDetailsAppointmentContainer = React.lazy(() => import("./container/ProjectKanbanDetailsAppointmentContainer"));

export const ProjectKanbanCardDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":cardNum",
        title: "{{kanbanCardTitle}}",
        element: <ProjectKanbanDetailsAppointmentContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectKanbanDetailsDetailRoutes,
            ...ProjectKanbanDetailsHistoryRoutes
        ]
    },
];
