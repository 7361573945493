import { MenuItemProps, useTranslation } from "@mzara/component";
import { useMemo } from "react";

export const usePlanningListTypeMenuItems = () => {
    const t = useTranslation();

    return useMemo((): Array<MenuItemProps> => {
        return [
            {
                value: ["CONGE", "PLANNING", "ABSENCE"],
                label: t("std_all"),
            },
            {
                value: ['CONGE'],
                label: t("Harea.Vacation.VacationList.Title"),
            },
            {
                value: ['PLANNING'],
                label: t("Harea.Calendar.Planning"),
            },
        ];
    }, []);
};
