import { ControlListProps, useTranslation } from "@mzara/component";
import AdvertPreview from "../components/AdvertPreview";

export const useRecruitingAdvertPreviewForm = (): ControlListProps => {
    const t = useTranslation();

    return {
        id: "recruiting-advert-preview-form",
        data: {
            title: t("std_overview"),
            forms: [{ type: "hidden", name: "id" }, { type: "preview" }],
            hideNext: true,
            hideBack: true,
            fields: {
                preview: AdvertPreview,
            },
        },
    };
};
