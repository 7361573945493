import { useTranslationRoute, useTranslations } from "@mzara/component";
import { useMemo } from "react";

export const useLeftSideBarMenuAdminManagement = () => {

    const [
        ADMIN_MANAGEMENT,
        PAYSLIP,
        SALARY_CHART,
        CONTRACTS,
        ABSCENSE,
        SOCIALS,
        LEAVING,
        CONTRACT_TEMPLATE,
        PORTFOLIO
    ] = useTranslations(i18n)
    const translationRoute = useTranslationRoute();

    return useMemo(() => ({
        ke: "general",
        label: ADMIN_MANAGEMENT,
        className: "menu-item-category",
        items: [
            {
                label: "Planning",
                startIcon: "fa-solid fa-calendar-days",
                link: translationRoute("admin/project/planning"),
                roles: ["APPOINTMENT"],
            },
            {
                label: PAYSLIP,
                startIcon: "fa-solid fa-clipboard-list",
                link: translationRoute("admin/payslips"),
                roles: ["PAYING_SLIP"],
            },
            {
                label: SALARY_CHART,
                startIcon: "fa-solid fa-circle-dollar-to-slot",
                link: translationRoute('admin/contract/salary-charts'),
                roles: ["CONTRACT"]
            },
            {
                label: CONTRACTS,
                startIcon: "fa-solid fa-person",
                link: translationRoute("admin/contract"),
                roles: ["CONTRACT"],
            },
            {
                label: PORTFOLIO,
                startIcon: "fa-solid fa-briefcase",
                link: translationRoute("admin/portfolio"),
                roles: ["PORTFOLIO"],
            },
            {
                label: ABSCENSE,
                startIcon: "fa-solid fa-person-skating",
                link: translationRoute("admin/project/dayoff"),
                roles: ["CONTRACT"],
            },
            {
                label: SOCIALS,
                startIcon: "fa-solid fa-file-circle-exclamation",
                link: translationRoute("admin/payslips/social-statement"),
                roles: ["PAYING_SLIP"],
            },
            {
                label: LEAVING,
                startIcon: "fa-solid fa-hand-holding-droplet",
                link: translationRoute("admin/contract/departure"),
                roles: ["CONTRACT"],
            },
            {
                label: CONTRACT_TEMPLATE,
                startIcon: "fa-solid fa-file-contract",
                link: translationRoute("admin/contract/template"),
                roles: ["CONTRACT_TEMPLATE"],
            },
        ],
    }), [])
}

const i18n = [
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Payslip.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.SalaryChart.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Contracts.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Abscense.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Socials.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Leaving.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Contracts.Templates.Label',
    'Harea.Admin.LeftSideBar.Menu.AdminManagement.Portfolio.Label'
]
