import { GraphQLQueryClass } from "@mzara/component";
import { TAppointment, TAppointmentsResponse } from "@mzara/graphql";
export class MissingListQuery
    implements GraphQLQueryClass<TAppointmentsResponse, TResponse>
{
    public queryKey: string = "App.bo.appointment";
    public gql: string = `
        query MissingListQuery($data: JSONObject, $pageSize: Float, $page: Float, $sort: [TFilterSort!], $key: String) {
            appointments(
                filter: { 
                    data: $data, 
                    page: $page, 
                    pageSize: $pageSize,
                    sort:$sort,
                    key: $key
                }
            ) {
                total
                data {
                    id
                    createdAt
                    dateBegin
                    dateEnd
                    type
                    user {
                        id
                        fullName
                    }
                    userCreator {
                        id
                        fullName
                    }
                }
            }
        }
    `;
    variables?: any;
    constructor(variables?: any) {
        this.variables = variables;
    }
    mapFn(data: TResponse): TAppointmentsResponse {
        return {
            total: data.appointments.total,
            data: data.appointments.data,
        };
    }
}

export type TResponse = {
    appointments:{
        total:number,
        data:Array<TAppointment>
    }
}