import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectKanbanDetailsBoardContainer } from "./container/ProjectKanbanDetailsBoardContainer";
import { ProjectKanbanDetailsViewerRoutes } from "../viewer/ProjectKanbanDetailsViewerRoutes";
import { ProjectKanbanDetailsCreateRoutes } from "../create/ProjectKanbanDetailsCreateRoutes";
import { ProjectKanbanCardDetailsRoutes } from "../details/ProjectKanbanCardDetailsRoutes";


export const ProjectKanbanDetailsBoardRoutes: Array<AppRouteProps> = [
    {
        path: "board",
        title: "Harea.Project.Kanban.Details.Board",
        element: <ProjectKanbanDetailsBoardContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectKanbanDetailsViewerRoutes,
            ...ProjectKanbanDetailsCreateRoutes,
            ...ProjectKanbanCardDetailsRoutes,
        ]
    },
];
