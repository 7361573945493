import { GraphQLQueryClass } from "@mzara/component";
import { TFormationsResponse } from "@mzara/graphql";

export class FormationListQuery
    implements GraphQLQueryClass<TFormationsResponse, TReponse>
{
    queryKey: string = "Harea.Formation.List.Query";
    gql: string = `
        query formationListQuery(
            $page: Float
            $pageSize: Float
            $sort: [TFilterSort!]
            $data: JSONObject
        ) {
            formations(
                filter: { page: $page, pageSize: $pageSize, sort: $sort, data: $data }
            ) {
                total
                data {
                    id
                    title
                    miniature {
                        id
                        name
                    }
                    isPublic
                    createdAt
                }
            }
        }
    `;
    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TReponse): TFormationsResponse {
        return data.formations;
    }
}

export type TReponse = {
    formations: TFormationsResponse;
};
