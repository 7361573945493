import { AppRouteProps } from "@mzara/component";
import { PublicStaticPage } from "components/PublicStaticPage";
import React from "react";


export const PagePublicStaticRoutes: Array<AppRouteProps> = [
    {
        path: ':permalink',
        title: "{{StaticPageTitle}}",
        element: <PublicStaticPage/>
    }
]