import { TAppointment } from "@mzara/graphql"
import _ from "lodash"
import { useCallback } from "react"


export const useKanbanColumnsRowsSort = () => {

    

    return useCallback((rows: TAppointment[]) => {
        return rows.sort((a, b) => a.relativePosition - b.relativePosition)
    }, [])
}
