import { Avatar, DateSpan, Dropdown, IconButton, Menu, MenuItemProps, useTranslation, useTranslationRoute, useTranslations, useUserProfilePicture } from "@mzara/component"
import { TAppointmentInvitation } from "@mzara/graphql"
import { useCallback, useMemo, useState } from "react"
import { Link } from "react-router-dom"

export const MindMapInvitationItem = (props: KanbanInvitationItemProps) => {
    const [
        ADDED_AT,
        EDIT,
        DELETE
    ] = useTranslations(i18n)

    const t = useTranslation()
    const tr = useTranslationRoute()
    const profilePictureUrl = useUserProfilePicture()
    const [anchor, setAnchor] = useState<HTMLButtonElement>()
    const menuItems = useMemo((): Array<MenuItemProps> => {
        return [
            {
                label: EDIT,
                startIcon: 'fa-solid fa-pen',
                value: 'EDIT'
            },
            {
                divide: true
            },
            {
                label: DELETE,
                startIcon: 'fa-solid fa-trash',
                value: 'DELETE'
            }
        ]
    }, [])

    const handleMenuClick = useCallback((menu: MenuItemProps) => {
        if (menu.value === 'EDIT') {
            props.onEditClick?.()
        }
        if (menu.value === 'DELETE') {
            props.onDeleteClick?.()
        }
    }, [props])

    const userLink = useMemo(() => tr(`admin/user/${props.user?.id}/about`), [props])


    return (
        <div className="flex gap-2 relative w-full">
            <Link to={userLink}>
                <Avatar
                    size="md"
                    src={profilePictureUrl(props.user?.id)}
                    fullName={props.user?.fullName}
                />
            </Link>
            <div>
                <Link to={userLink}>
                    <h5 className="text-bold line-clamp-2">{props.user.fullName || props.email}</h5>
                </Link>
                <p><label>{ADDED_AT}</label> : <DateSpan value={props.createdAt} /> • <label>{t(`Harea.Project.Kanban.Invitation.Actions.${props.actions}.Label`)}</label></p>
            </div>
            {
                props.isEditable &&
                <>
                    <IconButton
                        className="ml-auto mr-2"
                        icon="fa-solid fa-ellipsis-vertical"
                        onClick={(e) => setAnchor(e.currentTarget)}
                    />
                    <Dropdown anchorEl={anchor} onClose={() => setAnchor(undefined)}>
                        <Menu
                            onClick={handleMenuClick}
                            items={menuItems}
                        />
                    </Dropdown>
                </>
            }
        </div>
    )
}

const i18n = [
    'std_added_at',
    'std_edit',
    'std_delete'
]

type KanbanInvitationItemProps = Partial<TAppointmentInvitation> & {
    isEditable?: boolean;
    onDeleteClick?: () => void
    onEditClick?: () => void
}
