import { ControlList, useRuntimeEnv, useUrlParamsValue } from "@mzara/component";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useAppointmentMissingControlForm } from "../hooks/useAppointmentMissingControlForm";
import { useProjectAppointmentCreateDefaultValue } from "views/create/hooks/useProjectAppointmentCreateDefaultValue";
import { useProjectAppointmentNavigateBack } from "views/create/hooks/useProjectAppointmentNavigateBack";
import { useAppointmentsInvalidateQuery } from "hooks/useAppointmentsInvalidateQuery";

const ProjectAppointmentCreateMissingContainer = () => {
    const navigate = useNavigate();
    const { runtimeEnv } = useRuntimeEnv();
    const control = useAppointmentMissingControlForm();
    const defaultValues = useProjectAppointmentCreateDefaultValue()
    const navigateBack = useProjectAppointmentNavigateBack()
    const invalidateAppointmentsQuery = useAppointmentsInvalidateQuery()

    const handleSubmited = () => {
        invalidateAppointmentsQuery()
        navigateBack()
    };

    return (
        <ControlList
            {...control}
            defaultValue={{
                ...defaultValues,
                type: "ABSENCE",
            }}
            onSubmited={handleSubmited}
            onCancel={() => navigate(-1)}
            onBeforeSaving={(value) => {
                const hourBegin = value?.hourBegin?.split(":")
                const hourEnd = value?.hourEnd?.split(":")
                const dateBegin = value.dateBegin ? moment(value.dateBegin).set({ hour: hourBegin?.[0], minute: hourBegin?.[1] }).format() : null
                const dateEnd = moment(value.dateEnd ? value.dateEnd : value.dateBegin).set({ hour: hourEnd?.[0], minute: hourEnd?.[1] }).format()
                return ({
                    ...value,
                    dateBegin,
                    dateEnd,
                    user: {
                        id: runtimeEnv?.user?.id,
                    }
                })
            }}
        />
    );
};

export default ProjectAppointmentCreateMissingContainer;

const i18n = [
    "Harea.Project.DayOff.Create.Notification.Message",
    "Harea.Project.DayOff.Create.Dialog.Title",
];
