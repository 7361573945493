import { ControlListProps, useTranslations } from "@mzara/component";
import { useAppointmentBoardInvitationsQuery } from "../../../hooks/useAppointmentBoardInvitationsQuery";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

export const useProjectKanbanInvitationEditForm = (): ControlListProps => {
    const [
        USER,
        ACTION,
        ACTIONS_READ,
        ACTIONS_EDIT,
        ACTIONS_COMMENT,
        SAVE,
        CANCEL
    ] = useTranslations(i18n);
    const {id} = useParams()
    const { data } = useAppointmentBoardInvitationsQuery(
        parseInt(id),
        true
    )
    const invitationsIds = useMemo(() => {
        let ids = data.invitations?.map(invitation => invitation.user?.id) ?? []

        if (data.userCreator) {
            ids.push(data.userCreator.id)
        }
        
        return ids
    }, [data])

    return {
        data: {
            forms: [
                {
                    type: "select",
                    label: USER,
                    name: "user",
                    required: true,
                    GQLProps: {
                        gql: `
                            query($data: JSONObject) {
                                users (filter: { data: $data, sort: [{ sort: "ASC", value: "fullName" }] }) {
                                    data{
                                        id fullName profilePicture { id }
                                    }
                                }
                            }
                        `,
                        rootProp: "users.data",
                        labelProp: "fullName",
                        valueProp: "id",
                        userIdProp: "id",
                        variables: {
                            data: {
                                id_notAmong: invitationsIds,
                            },
                        },
                    },
                    valueObject: true,
                    valueProperty: "id",
                },
                {
                    type: "select",
                    name: "actions",
                    label: ACTION,
                    options: [
                        { label: ACTIONS_READ, value: "READ" },
                        { label: ACTIONS_EDIT, value: "EDIT" },
                        { label: ACTIONS_COMMENT, value: "COMMENT" },
                    ],
                    required: true,
                },
                {
                    type: "hidden",
                    name: "id",
                },
            ],
            value: {},
            next: {
                label: SAVE,
                className: "btn-primary",
            },
            back: {
                label: CANCEL,
            },
            graphQL: {
                entity: "appointmentInvitation",
            },
            fields: {},
        },
        id: "vacation-add-kanban-card-form",
    };
}
const i18n = [
    'Harea.Project.Kanban.Invitation.User.Label',
    'Harea.Project.Kanban.Invitation.Actions.Label',
    'Harea.Project.Kanban.Invitation.Actions.READ.Label',
    'Harea.Project.Kanban.Invitation.Actions.EDIT.Label',
    'Harea.Project.Kanban.Invitation.Actions.COMMENT.Label',
    "std_save",
    "std_cancel",
];