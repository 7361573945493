import {
    Button,
    IconButton,
    Menu,
    useCdnAssets,
    useRuntimeEnv,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";
import { Link } from "react-router-dom";
import { AvatarMenu } from "../menu/AvatarMenu";
import { useRecoilState } from "recoil";
import { calendarOpenState } from "./AdminNavBar";
import { NotificationMenu } from "./NotificationMenu";
import { useClientMenuItems } from "./hooks/useClientMenuItems";
import './css/navbar.css'
import { OrganisationMenu } from "components/menu/OrganisationMenu";

export const ClientNavBar = ({
    searchBarDisabled = true,
}: {
    searchBarDisabled?: boolean;
}) => {
    const [
        LOGIN
    ] = useTranslations(i18n)
    const [openCalendar, setOpenCalendar] = useRecoilState(calendarOpenState)
    const tr = useTranslationRoute();
    const cdn = useCdnAssets();
    const { runtimeEnv } = useRuntimeEnv();
    const items = useClientMenuItems()

    return (
        <>
            <header
                className={`sticky top-0 flex z-50 justify-between items-center bg-white px-4 shadow-md`}
            >
                <div>
                    <Link to={tr("")}>
                        <img src={"/assets/img/logo.png"} alt="Logo Harea" className={`h-10`
                        } />
                    </Link>
                </div>
                <nav className="max-w-2xl w-full">
                    <Menu
                        className="client-navbar"
                        items={items}
                        type="HORIZONTAL"
                    />
                </nav>
                <nav>
                    <ul className="flex items-center gap-4 sm:gap-6">
                        <li>
                            <IconButton icon={"fa-search"} className={"text-lg"} />
                        </li>

                        {
                            !runtimeEnv.authenticated &&
                            <li className="ml-2">
                                <Link to={tr('auth/login')}>
                                    <Button
                                        label={LOGIN}
                                        className="btn-primary"
                                        startIcon="fa-sign-in"
                                    />
                                </Link>
                            </li>
                        }
                        {
                            runtimeEnv.authenticated &&
                            <>
                                <li onClick={() => setOpenCalendar(!openCalendar)} className="max-sm:hidden">
                                    <IconButton icon={"fa-calendar-days"} className={"text-lg"}
                                    />
                                </li>
                                <li>
                                    <NotificationMenu />
                                </li>
                                <li>
                                    <OrganisationMenu />
                                </li>
                                <li className="ml-2">
                                    <AvatarMenu />
                                </li>
                            </>
                        }
                    </ul>
                </nav>
            </header>
        </>
    );
};

const i18n = [
    'std_login'
]
