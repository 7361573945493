import { useAjax, useMzMutation } from "@mzara/component";

export const useUserOrganisationMutation = () => {
    const ajax = useAjax();
    return useMzMutation((payload: Record<string, any>) => {
        return ajax.send({
            type: "POST",
            content_type: "application/json",
            url: "user/organisation/change",
            data: payload,
        });
    });
};
