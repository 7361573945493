import {
    Button,
    ControlList,
    Dialog,
    GenericSuspense,
    MenuItemProps,
    useGraphqlMutation,
    useTranslation,
    useTranslations,
} from "@mzara/component";
import { WizardSequentialDesigner } from "@mzara/wizard";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Thread } from "@mzara/thread";
import { useAddChapterForm } from "../hooks/useAddChapterForm";
import { useSaveFormationChapterMutation } from "../hooks/useSaveFormationChapterMutation";
import { useFormationChapterDetailsQuery } from "../hooks/useFormationChapterDetailsQuery";

const ChaptersContainer = () => {
    const { id } = useParams();
    const [openAddChapterDialog, setOpenAddChapterDialog] = useState(false);

    const t = useTranslation();
    const [NEW_QUESTION_LABEL, EMPTY_MESSAGE] = useTranslations(i18n);
    const createMutation = useGraphqlMutation(FORMATION_CHAPTER_MUTATION);

    const addChapterForm = useAddChapterForm();
    const { mutate: saveFormationChapter } = useSaveFormationChapterMutation();
    const { data } = useFormationChapterDetailsQuery(+id);

    const handleAddClick = useCallback(async () => {
        const result = await createMutation.mutateAsync({
            data: {
                value: JSON.stringify({
                    data: {
                        title: NEW_QUESTION_LABEL,
                        forms: [],
                    },
                }),
            },
        });
        return result.saveWizardNode.id;
    }, []);

    const showFn = useCallback((menu: MenuItemProps, index: number) => {
        const items = ["title", "paragraph", "video", "image-legend"];

        return items.includes(menu.value);
    }, []);

    const handleOnClick = () => {
        setOpenAddChapterDialog(true);
    };

    const handleSubmit = (value: Record<string, any>) => {
        saveFormationChapter(
            { data: { ...value, formation: { id: +id } } },
            {
                onSuccess: () => setOpenAddChapterDialog(false),
            }
        );
    };

    return (
        <>
            <div className="flex flex-col gap-5">
                <div className="flex justify-end">
                    <Button
                        label={t("std_add")}
                        startIcon="fa-solid fa-plus"
                        className={`bg-primary rounded-full`}
                        onClick={() => handleOnClick()}
                    />
                </div>

                <div data-testid="formation-details-tab-chapters">
                    <WizardSequentialDesigner
                        id={parseInt(id)}
                        wizardNodeType="FORMATION"
                        controlListSelectMenuShowFn={showFn}
                        generatedName={process.env.NODE_ENV !== "test"}
                        emptyMessage={EMPTY_MESSAGE}
                        onAddClick={handleAddClick}
                    />
                </div>

                <Thread
                    id={data?.thread?.id}
                    isEnabled
                    commentEnabled={data?.thread?.disableComment}
                />
            </div>

            <Dialog
                open={openAddChapterDialog}
                title={"Ajout d'une chapitre"}
                onClose={() => setOpenAddChapterDialog(false)}
                onCancel={() => setOpenAddChapterDialog(false)}
            >
                <GenericSuspense>
                    <ControlList
                        {...addChapterForm}
                        onSubmit={handleSubmit}
                        onCancel={() => setOpenAddChapterDialog(false)}
                    />
                </GenericSuspense>
            </Dialog>
        </>
    );
};

export default ChaptersContainer;

export const FORMATION_CHAPTER_MUTATION = `
    mutation ($data: JSONObject) {
        saveFormationChapter (data: { data: $data }){ id }
    }
`;

const i18n = [
    "Harea.Formation.Details.MainPage.Node.NewQuestion.Label",
    "Harea.Formation.Details.MainPage.Empty.Message",
];
