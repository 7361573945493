import { GraphQLQueryClass } from "@mzara/component";
import { TRecruitingAdvertsResponse } from "@mzara/graphql";

export class RecruitingAdvertList
    implements GraphQLQueryClass<TRecruitingAdvertsResponse, TResponse>
{
    queryKey: string = "Evaluation.Recruiting.Advert.List.Query";

    gql: string = `
        query($data: JSONObject) {
            recruitingAdverts(filter: { data: $data }) {
                total
                data {
                    id
                    title
                    isPublished
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TRecruitingAdvertsResponse {
        return data.recruitingAdverts;
    }
}

type TResponse = {
    recruitingAdverts: TRecruitingAdvertsResponse;
};
