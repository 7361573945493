import { List } from "@mzara/component";
import { TFormation } from "@mzara/graphql";
import FormationItem from "./FormationItem";
import { FormationListQuery } from "queries/FormationListQuery";
import { useFormationListSortBar } from "../hooks/useFormationListSortBar";

const FormationList = ({ id }: FormationListProps) => {
    const sortItems = useFormationListSortBar();

    return (
        <List
            search={false}
            selection={false}
            id={id}
            sort={{ items: sortItems }}
            GQLRequest={FormationListQuery}
            defaultFilter={{
                pageSize: 10,
                page: 0,
                sort: [{ sort: "DESC", value: "updatedAt" }],
            }}
            onRenderRow={(row: TFormation) => {
                return (
                    <FormationItem
                        key={row.id}
                        id={row.id}
                        title={row.title}
                        miniature={row.miniature}
                        isPublic={row.isPublic}
                        createdAt={row.createdAt}
                    />
                );
            }}
        />
    );
};

export default FormationList;

type FormationListProps = { id: string };
