import { GenericSuspense } from "@mzara/component";
import { Outlet } from "react-router-dom";

interface FormationClientContainerProps {}

const FormationClientContainer = () => {
    return (
        <GenericSuspense>
            <Outlet />
        </GenericSuspense>
    );
};

export default FormationClientContainer;
