import { AppRouteProps } from "@mzara/component";
import React from "react";
import { LoginRoutes } from "./view/login/LoginRoutes";
import { ForgotRoutes } from "./view/forgot/ForgotRoutes";
import { SignupRoutes } from "./view/signup/SignupRoutes";

const UserAuthContainer = React.lazy(
    () => import("./container/UserAuthContainer")
);
export const UserAuthRoutesTest: Array<AppRouteProps> = [
    {
        title: "Auth.Title",
        path: "auth",
        element: <UserAuthContainer />,
        children: [...LoginRoutes, ...ForgotRoutes, ...SignupRoutes],
    },
];
