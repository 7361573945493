import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd"
import { KanbanCard } from "./KanbanCard"
import { TAppointment } from "@mzara/graphql"

export const KanbanCardList = (props: KanbanCardListProps) => {

    return (
        <Droppable
            droppableId={props.columnId}
            type={props.columnType}

        >
            {(
                dropProvided: DroppableProvided,
                dropSnapshot: DroppableStateSnapshot,
            ) => (
                <div
                    className="flex-1 flex flex-col gap-4 overflow-y-auto mt-2 pr-4"
                    ref={dropProvided.innerRef}>
                    {
                        props.cards?.map((item, index) => {
                            return (
                                // @ts-ignore
                                <Draggable key={item.id} draggableId={item.id?.toString()} data={item} index={index}>
                                    {(
                                        dragProvided: DraggableProvided,
                                        dragSnapshot: DraggableStateSnapshot,
                                    ) => (
                                        <div
                                            ref={dragProvided.innerRef}
                                            {...dragProvided.draggableProps}
                                            {...dragProvided.dragHandleProps}>
                                            <KanbanCard
                                                key={item.id}
                                                {...item}
                                                index={index}
                                                onClick={props.onCardClick}
                                            />
    
                                        </div>
                                    )}
                                </Draggable>
                            )
                        })
                    }

                    {dropProvided.placeholder}
                </div>

            )}
        </Droppable>
    )
}

export type KanbanCardListProps = {
    columnId?: 'OPENED' | 'CLOSED' | string
    columnType?: 'CARDS'
    cards?: Array<TAppointment>
    onCardClick?: (e: TAppointment) => void
}
