import { AppRouteProps } from "@mzara/component";
import CurriculumVitaeViewerContainer from "./container/CurriculumVitaeViewerContainer";
import { DiscussionRoutes } from "views/admin/views/CV-details/views/discussion/DiscussionRoutes";
import { DetailsRoutes } from "views/admin/views/CV-details/views/details/DetailsRoutes";
import { EvaluationsRoutes } from "views/admin/views/CV-details/views/evaluations/EvaluationsRoutes";

export const CurriculumVitaeViewerRoutes: Array<AppRouteProps> = [
    {
        path: ":id/viewer",
        title: "Harea.Recruiting.CurriculumVitae.Viewer.title",
        element: <CurriculumVitaeViewerContainer />,
        children: [...DetailsRoutes, ...EvaluationsRoutes, ...DiscussionRoutes],
    },
];
