
import { MenuItemProps, useTranslations } from "@mzara/component"
import { useAppointmentQuery } from "hooks/useAppointmentQuery";
import { useMemo } from "react"

export const useAppointmentDetailsMenu = (id: number) => {

    const [
        DELETE,
    ] = useTranslations(i18n)
    const { data } = useAppointmentQuery(id, true);

    return useMemo((): Array<MenuItemProps> => ([
        data.isDeletable ? {
            ke: 'delete',
            label: DELETE,
        } : null,
    ]), [DELETE, data])
}

const i18n = [
    "std_delete",
    'std_close',
    'std_open',
    'std_unarchive'
]

