import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectKanbanMilestoneCreateContainer from "./container/ProjectKanbanMilestoneCreateContainer";

export const ProjectKanbanMilestoneCreateRoutes: Array<AppRouteProps> = [
    {
        path: "create",
        title: "Harea.Project.Kanban.Details.Milestone.Create.Title",
        element: <ProjectKanbanMilestoneCreateContainer />,
        roles: ["APPOINTMENT"],
    },
];
