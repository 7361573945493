import { GraphQLQueryClass } from "@mzara/component";
import { TEvaluationsResponse } from "@mzara/graphql";

export class RecruitingEvaluationListQuery
    implements GraphQLQueryClass<TEvaluationsResponse, TResponse>
{
    queryKey: string = "Harea.Evaluation.Recruiting.List.Query";
    gql: string = `
        query recruitingEvaluationListQuery(
            $page: Float
            $pageSize: Float
            $sort: [TFilterSort!]
            $data: JSONObject
        ) {
            evaluations(filter: { page: $page, pageSize: $pageSize, sort: $sort, data: $data }) {
                total
                data {
                    id
                    name
                    email
                    subject
                    result
                    state
                    dateEnd
                    curiculumVitae {
                        id
                        name
                    }
                    recruitingAdvert {
                        id
                        title
                    }
                    wizard {
                        id
                        title
                        type
                    }
                    createdAt
                    updatedAt
                }
            }
        }
    `;
    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TEvaluationsResponse {
        return data.evaluations;
    }
}

export type TResponse = {
    evaluations: TEvaluationsResponse;
};
