import moment from "moment";
import { useCallback } from "react";
import { v4 as uuid } from 'uuid'

export const useProjectAppointmentEventBeforeSave = () => {

    return useCallback((value: Record<string, any>) => {
        const hourBegin = value?.hourBegin?.split(":")
        const hourEnd = value?.hourEnd?.split(":")
        const dateBegin = value.dateBegin ? moment(value.dateBegin).set({ hour: hourBegin?.[0], minute: hourBegin?.[1] }).format() : null
        const dateEnd = moment(value.dateEnd ? value.dateEnd : value.dateBegin).set({ hour: hourEnd?.[0], minute: hourEnd?.[1] }).format()
        let invitations: any = []
        if (value?.invitations) {
            invitations = value.invitations?.map((item) => {
                const invitation: any = {
                    id: null,
                    ke: uuid()
                }
                if (typeof item === 'string') {
                    invitation.email = item
                }
                if (typeof item === 'number') {
                    invitation.user = { id: item }
                }

                return invitation
            })
        }

        return {
            thread: { id: null },
            ...value,
            notificationTime: JSON.stringify(value.notificationTime ?? []),
            dateBegin,
            dateEnd,
            repetitionDateStart: dateBegin,
            invitations,
            userCreator: undefined,
            user: undefined
        }
    }, [])
}
