import { AppRouteProps } from "@mzara/component";
import RecruitingContainer from "./container/RecruitingContainer";
import { ViewerRoutes } from "./views/viewer/ViewerRoutes";
import { CreateRoutes } from "./views/create/CreateRoutes";
import { RecruitingDetailsRoutes } from "./views/details/RecruitingDetailsRoutes";
import { TemplateRoutes } from "../template/TemplateRoutes";

export const RecruitingRoutes: Array<AppRouteProps> = [
    {
        path: "",
        title: "Harea.Evaluation.Recruiting.List.Page.Title",
        element: <RecruitingContainer />,
        children: [
            ...ViewerRoutes, 
            ...CreateRoutes,
        ],
    },
    ...RecruitingDetailsRoutes,
];
