import { AppRouteProps } from '@mzara/component'
import React from 'react'

const UserOrganisationLicenseContainer = React.lazy(
    () => import("./license/container/UserOrganisationLicenseContainer")
);
const UserOrganisationDetailsAbout = React.lazy(
    () => import("./about/containers/UserOrganisationDetailsAbout")
);
const UserOrganisationDetailsParameters = React.lazy(
    () => import("./parameters/containers/UserOrganisationDetailsParameters")
);
const UserOrganisationGroupsList = React.lazy(
    () => import("./groups/containers/UserOrganisationGroupsList")
);
const UserOrganisationInvitationList = React.lazy(
    () => import("./users/containers/UserOrganisationInvitationList")
);

export const UserOrganisationViewRoutes: Array<AppRouteProps> = [
    {
        path: "about",
        title: "Generic.UserOrganisation.About.title",
        element: <UserOrganisationDetailsAbout />,
    },
    {
        path: "groups",
        title: "Generic.UserOrganisation.Groups.title",
        element: <UserOrganisationGroupsList />,
    },
    {
        path: "licenses",
        title: "Generic.UserOrganisation.Licenses.title",
        element: <UserOrganisationLicenseContainer />,
    },
    {
        path: "users",
        title: "Generic.UserOrganisation.Users.title",
        element: <UserOrganisationInvitationList />,
    },
    {
        path: "parameters",
        title: "Generic.UserOrganisation.Parameters.title",
        element: <UserOrganisationDetailsParameters />,
    },
];
