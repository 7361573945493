import {
    MenuItemProps,
    useTranslationRoute,
    useTranslations,
    useUrlParamsValueToString,
} from "@mzara/component";
import { useParams } from "react-router-dom";

export const useCurriculumVitaeViewerMenuItems = (): Array<MenuItemProps> => {
    const { id } = useParams();

    const tr = useTranslationRoute();
    const [ABOUT, EVALUATION, DISCUSSION] = useTranslations(i18n);
    const urlParamsToString = useUrlParamsValueToString();

    return [
        {
            ke: "details",
            label: ABOUT,
            link: tr(
                `admin/recruiting/cv/${id}/viewer/details?${urlParamsToString}`
            ),
            "data-testid": `recruiting-cv-details-menu-about`,
            className: "text-clip whitespace-nowrap",
        },
        {
            ke: "evaluations",
            label: EVALUATION,
            link: tr(
                `admin/recruiting/cv/${id}/viewer/evaluations?${urlParamsToString}`
            ),
            "data-testid": `recruiting-cv-details-menu-evaluations`,
            className: "text-clip",
        },
        {
            ke: "discussion",
            label: DISCUSSION,
            link: tr(
                `admin/recruiting/cv/${id}/viewer/discussion?${urlParamsToString}`
            ),
            "data-testid": `recruiting-cv-details-menu-discussion`,
            className: "text-clip",
        },
    ];
};

const i18n = ["std_about", "std_assessment", "std_discussion"];
