import { useGraphqlQuery } from "@mzara/component";
import { TFormation } from "@mzara/graphql";
import { FormationDetailsQuery } from "../queries/FormationDetailsQuery";

export const useFormationDetailsQuery = (
    formationId: number,
    suspense?: boolean
) => {
    return useGraphqlQuery<FormationDetailsQuery, TFormation>(
        new FormationDetailsQuery({ id: formationId }),
        {
            suspense: suspense || false,
        }
    );
};
