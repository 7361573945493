import { useAjax, useMzMutation, useTranslation } from "@mzara/component";
import { AjaxResponseType } from "@mzara/lib";

export const useCreateAndSendEvaluation = () => {
    const ajax = useAjax();
    const t = useTranslation();

    return useMzMutation<AjaxResponseType>(
        (payload: Record<string, any>) =>
            ajax.send({
                type: "POST",
                content_type: "application/json",
                data: payload,
                url: "evaluation/email",
            }),
        {
            transformErrorMessage: (error) => {
                if (error.message === "EMAIL_INVALID") {
                    return t("std_invalid_email_message");
                }

                if (error.message === "EMAIL_NOT_SENT") {
                    return t("std_not_sent_email_message");
                }

                return undefined;
            },
        }
    );
};
