import { Dropdown, IconButton, Menu, MenuItemProps, useDisplayAlert, useTranslation } from "@mzara/component"
import moment from "moment"
import { useCallback, useState } from "react"
import { useQueryClient } from "react-query"
import { useAppointmentGraphqlMutation } from "views/kanban/hooks/useAppointmentGraphqlMutation"
import { useDeleteAppointmentMutation } from "../hooks/useKanbanCardDelete"
import { useKanbanCardDetailsMenu } from "../hooks/useKanbanDetailsAppointmentMenu"

export const KanbanCardDetailsMenu = (props: Props) => {

    const [anchor, setAnchor] = useState<HTMLButtonElement>()
    const items = useKanbanCardDetailsMenu(props.boardId, props.num)
    const deleteMutation = useDeleteAppointmentMutation()
    const mutation = useAppointmentGraphqlMutation()
    const t = useTranslation()
    const displayAlert = useDisplayAlert()
    const queryClient = useQueryClient()

    const handleMenuClick = useCallback(async (menu: MenuItemProps) => {
        if (menu.ke === 'copy-link') {
            try {
                await navigator.clipboard.writeText(location.href);
                displayAlert({
                    text: t('std_link_copied'),
                    type: 'success'
                })
            } catch (err) {
                console.error("Failed to copy text: ", err);
            }
        }

        if (menu.ke === 'open') {
            mutation.mutate({
                data: {
                    id: props.id,
                    isClosed: false
                }
            }, {
                onSuccess: () => props.onUpdated?.()
            })
        }

        if (menu.ke === 'close') {
            mutation.mutate({
                data: {
                    id: props.id,
                    isClosed: true
                }
            }, {
                onSuccess: () => props.onUpdated?.()
            })
        }

        if (menu.ke === 'archive') {
            mutation.mutate({
                data: {
                    id: props.id,
                    dateArchive: moment().format()
                }
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries(
                        "Harea.AppointmentBoardQuery.Query"
                    )
                    queryClient.invalidateQueries(
                        "App.Harea.Kanban.Board.Detail.Archived.List.Query"
                    )
                    props.onUpdated?.()
                }
            })
        }

        if (menu.ke === 'unarchive') {
            mutation.mutate({
                data: {
                    id: props.id,
                    dateArchive: ''
                }
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries(
                        "Harea.AppointmentBoardQuery.Query"
                    )
                    queryClient.invalidateQueries(
                        "App.Harea.Kanban.Board.Detail.Archived.List.Query"
                    )
                    props.onUpdated?.()
                }
            })
        }

        if (menu.ke === 'delete') {
            deleteMutation.mutate({
                ids: [props.id]
            }, {
                onSuccess: () => props.onDeleted?.()
            })
        }
    }, [])

    return (
        <>
            <IconButton
                className="ml-auto mr-2"
                icon="fa-solid fa-ellipsis"
                onClick={(e) => setAnchor(e.currentTarget)}
            />
            <Dropdown anchorEl={anchor} onClose={() => setAnchor(undefined)}>
                <Menu
                    onClick={handleMenuClick}
                    items={items}
                />
            </Dropdown>
        </>
    )
}

type Props = {
    id: number
    boardId: number,
    num: number
    onUpdated?: () => void 
    onDeleted?: () => void
}
