import { GenericSuspense, List, useUrlParamsValueToString } from "@mzara/component";
import { KanbanCard } from "../../../components/KanbanCard";
import { KanbanArchivedListQuery } from "../queries/KanbanArchivedListQuery";
import { TAppointment } from "@mzara/graphql";
import { Link, Outlet } from "react-router-dom";
import { useCallback } from "react";

export const ProjectKanbanDetailsArchivedContainer = () => {
    const urlParamsValueString = useUrlParamsValueToString()

    const renderRow = useCallback(
        (row: TAppointment) => (
            <Link className="w-full" to={`${row.num}?${urlParamsValueString}`}>
                <KanbanCard {...row} />
            </Link>
        ),
        [urlParamsValueString]
    );

    return (
        <>
            <List
                BoxProps={{
                    className: "shadow-none px-0",
                }}
                selection={false}
                GQLRequest={KanbanArchivedListQuery}
                defaultFilter={{
                    key: "",
                    page: 0,
                    pageSize: 10,
                }}
                onRenderRow={renderRow}
                isUrlParamsState={false}
            />
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </>
    );
};
