import {
    Box,
    Button,
    ControlList,
    Dialog,
    useListGraphqlQuery,
    useTranslation,
} from "@mzara/component";
import _ from "lodash";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import EvaluationList from "../../../../../components/EvaluationList";
import { useAddEvaluationForm } from "../hooks/useAddEvaluationForm";
import { useAdvertEvaluationsQuery } from "../hooks/useAdvertEvaluationsQuery";
import { useSaveAdvertEvaluationMutation } from "../hooks/useSaveAdvertEvaluationMuation";
import { useEvalutationListId } from "views/admin/hooks/useEvaluationListId";
import { useRecruitingAdvertsDetailsQuery } from "../../../hooks/useRecruitingAdvertsDetailsQuery";

const EvaluationContainer = () => {
    const { id } = useParams();
    const [openAddEvaluationForm, setOpenAddEvaluationForm] = useState(false);
    const location = useLocation();

    const t = useTranslation();
    const { data } = useRecruitingAdvertsDetailsQuery(parseInt(id));

    const addEValuationForm = useAddEvaluationForm(parseInt(id));
    const { saveAdvertEvaluation, isLoading: isSaveAdvertEvaluationLoading } = useSaveAdvertEvaluationMutation();
    const listId = useEvalutationListId({ id: EVALUATION_LIST_ID, advertId: Number(id) })
    const { invalidateQuery } = useListGraphqlQuery(listId)

    const handleSubmit = (val: Record<string, any>) => {
        // console.log(val);
        saveAdvertEvaluation(
            {
                recruitingAdvertId: parseInt(id),
                name: val.name,
                email: val.email,
                subject: val.subject,
                message: val.message,
                curiculumVitaeId: val.curiculumVitae?.id,
                wizardId: val.wizard?.id,
            },
            {
                onSuccess: () => {
                    invalidateQuery(),
                        setOpenAddEvaluationForm(false)
                }
            }
        );
    };

    const isInViewer = location.pathname.includes("viewer");
    return (
        <>
            <Box
                title={t("Harea.Recruiting.Details.Evaluation.Label")}
                className="p-0 !bg-transparent shadow-none"
                tools={(
                    <>
                        {
                            data?.isEditable &&
                            <Button
                                className={`btn-primary rounded-full${isInViewer ? " btn-sm" : ""
                                    }`}
                                startIcon="fa-plus"
                                label={t("std_add")}
                                onClick={() => setOpenAddEvaluationForm(true)}
                            />
                        }
                    </>
                )}
                data-testid="recruiting-advert-details-tab-evaluations">
                <div className="mt-4"></div>
                <EvaluationList
                    id={EVALUATION_LIST_ID}
                    advertId={Number(id)}
                    selection={false}
                />
            </Box>
            <Dialog
                title={t(
                    "Harea.Advert.Details.Evaluation.AddEvaluation.Dialog.Title"
                )}
                open={openAddEvaluationForm}
                onClose={() => setOpenAddEvaluationForm(false)}
            >
                <ControlList
                    {...addEValuationForm}
                    isSubmit={isSaveAdvertEvaluationLoading}
                    onSubmit={handleSubmit}
                    onCancel={() => setOpenAddEvaluationForm(false)}
                />
            </Dialog>
        </>
    );
};

export default EvaluationContainer;

export const EVALUATION_LIST_ID = "evaluation-list-id";
