import { AppRouteProps } from "@mzara/component";
import MindMapInvitationEditContainer from "./container/MindMapInvitationEditContainer";

export const MindMapInvitationEditRoute: Array<AppRouteProps> = [
    {
        path: 'create',
        element: <MindMapInvitationEditContainer />
    },{
        path: 'edit/:invitationId',
        element: <MindMapInvitationEditContainer />
    },
]
