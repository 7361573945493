import { ChipDateRangePicker, useListFilterState, useTranslations } from "@mzara/component";
import { useCallback, useMemo } from "react";

export const MissingListFilter = (props: Props) => {
    const [
        DATERANGE
    ] = useTranslations(i18n);

    const { filter, setFilter } = useListFilterState(props.listId);

    const dateRange = useMemo((): [string, string] | undefined => {
        if (filter?.data?.dateBegin_supOrEquals) {
            return [filter?.data?.dateBegin_supOrEquals, filter?.data?.dateEnd_infOrEquals]
        }

        return undefined
    }, [filter])

    const handleDateRangeChange = useCallback((value: [string, string]) => {

        setFilter((v: any) => ({
            ...v, 
            data: { 
                ...v.data, 
                dateBegin_supOrEquals: value?.[0],
                dateEnd_infOrEquals: value?.[1] ?? value?.[0],
            }
        }))
    }, [filter])

    return (
        <div className="flex items-center justify-end gap-2">
            <ChipDateRangePicker
                defaultLabel={DATERANGE}
                value={dateRange}
                onChange={handleDateRangeChange}
            />

        </div>
    );
}


const i18n = [
    'std_daterange'
];

type Props = {
    listId: string
}
