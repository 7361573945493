import {
    ControlList,
    Dialog,
    GenericSuspense,
    useListGraphqlQuery,
    useTranslation,
    useTranslationRoute,
    useUrlParamsEncode,
    useUrlParamsValue,
} from "@mzara/component";
import { FORMATION_LIST_ID } from "../../../container/FormationContainer";
import { useNavigate } from "react-router-dom";
import { useFormationAddForm } from "../hooks/useFormationAddForm";
import { useSaveFormationMutation } from "../hooks/useSaveFormationMutation";

const CreateContainer = () => {
    const navigate = useNavigate();

    const t = useTranslation();
    const tr = useTranslationRoute();
    const { invalidateQuery } = useListGraphqlQuery(FORMATION_LIST_ID);
    const urlParams = useUrlParamsValue();
    const encodeUrl = useUrlParamsEncode();

    const addControl = useFormationAddForm();
    const { mutate: saveFormation } = useSaveFormationMutation();

    const closeDialog = () => {
        invalidateQuery();
        navigate(tr(`admin/formation?${encodeUrl(urlParams)}`));
    };

    const handleSubmit = (value: Record<string, any>) => {
        saveFormation(
            { data: { ...value, thread: { id: null } } },
            { onSuccess: () => closeDialog() }
        );
    };

    return (
        <Dialog
            open
            title={t("Harea.Formation.Add.Dialog.Title")}
            onClose={closeDialog}
        >
            <GenericSuspense>
                <ControlList
                    {...addControl}
                    onSubmit={handleSubmit}
                    onCancel={closeDialog}
                />
            </GenericSuspense>
        </Dialog>
    );
};

export default CreateContainer;

type CreateContainerProps = {};
