import { ControlListProps, useAjax, useMzQuery } from "@mzara/component";
import { EvaluationResultTimeStamp } from "@mzara/graphql";
import { AjaxResponseType } from "@mzara/lib";

export const useGetStepFormControlQuery = (
    evaluationKey: string,
    stepIndex: number,
    stepsCount: number
) => {
    const ajax = useAjax();

    const { data, invalidateQuery } = useMzQuery<AjaxResponseType>(
        ["evaluation", "step", evaluationKey, stepIndex],
        () =>
            ajax.send({
                type: "GET",
                url: `evaluation/step/${evaluationKey}/${stepIndex}`,
            }),
        { enabled: stepIndex <= stepsCount - 1 }
    );

    return {
        data: data?.response
            ? JSON.parse(data?.response as string) as StepFormData
            : undefined,
        invalidateQuery,
    };
};

export type StepFormData = {
    form: ControlListProps
    value: Record<string, any>
    timeStamps: EvaluationResultTimeStamp[]
}
