import { MenuItemProps, useNotificationCount, useTranslationRoute, useTranslations } from "@mzara/component";
import { useMemo } from "react";

export const useLeftSideBarMenuGeneral = () => {

    const [
        GENERAL,
        CALENDAR,
        KANBAN,
        MIND_MAP,
        NOTIFICATIONS
    ] = useTranslations(i18n)
    const translationRoute = useTranslationRoute();
    const notificationCount = useNotificationCount()

    return useMemo((): MenuItemProps => ({
        ke: "general",
        label: GENERAL,
        className: "menu-item-category",
        items: [
            {
                label: CALENDAR,
                link: translationRoute("admin/project/calendar"),
                startIcon: "fa-solid fa-calendar-days",
            },
            {
                label: KANBAN,
                link: translationRoute("admin/project/kanban"),
                startIcon: "fa-solid fa-bars-progress",
            },
            {
                label: MIND_MAP,
                link: translationRoute("admin/mind-map"),
                startIcon: "fa-solid fa-sitemap",
            },
            {
                label: NOTIFICATIONS,
                link: translationRoute("admin/notification"),
                startIcon: "fa-solid fa-bell",
                badge: notificationCount > 0 ? notificationCount : undefined
            },
        ],
    }), [notificationCount])
}

const i18n = [
    'Harea.Admin.LeftSideBar.Menu.General.Label',
    'Harea.Admin.LeftSideBar.Menu.General.Calendar.Label',
    'Harea.Admin.LeftSideBar.Menu.General.Kanban.Label',
    'Harea.Admin.LeftSideBar.Menu.General.MindMap.Label',
    'Harea.Admin.LeftSideBar.Menu.General.Notifications.Label',
]
