import { AppRouteProps } from "@mzara/component";
import React from "react";
const ProjectDayOffViewsCalendarContainer = React.lazy(
    () => import("./container/ProjectDayOffViewsCalendarContainer")
);

export const ProjectDayOffViewsCalendarRoutes: Array<AppRouteProps> = [
    {
        path: "calendar",
        title: "Harea.Vacation.DayOff.Calendar",
        element: <ProjectDayOffViewsCalendarContainer />,
    },
];
