import {
    ControlList,
    Dialog,
    useListGraphqlQuery,
    useTranslations,
    useUrlParamsEncode,
    useUrlParamsState
} from "@mzara/component";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MILESTONE_LIST_ID } from "../../list/container/ProjectKanbanDetailsMilestoneListContainer";
import { useAppointmentBoardMilestoneForm } from "../hooks/useAppointmentBoardMilestoneForm";

const ProjectKanbanMilestoneCreateContainer = () => {
    const [TITLE] = useTranslations(i18n);
    const navigate = useNavigate()
    const { id } = useParams()
    const { invalidateQuery } = useListGraphqlQuery(MILESTONE_LIST_ID)
    const control = useAppointmentBoardMilestoneForm()
    const { urlParams, setUrlParams } = useUrlParamsState()
    const encodeUrlParams = useUrlParamsEncode()

    const handleClose = useCallback((to?: string) => {
        if (to) {
            return navigate(to)
        }
        navigate("..")
    }, [])

    const handleSubmited = useCallback((value: { id: number }) => {
        invalidateQuery()
        setUrlParams('sort[]', [{sort: 'DESC', value: 'updatedAt'}])
        handleClose(`../${value.id}?${encodeUrlParams(urlParams)}`)
    }, [])

    return (
        <Dialog open title={TITLE} onClose={handleClose}>
            <ControlList
                {...control}
                defaultValue={{ board: { id: parseInt(id) } }}
                onCancel={() => handleClose()}
                onSubmited={handleSubmited}
            />
        </Dialog>
    );
}

const i18n = ["Harea.Project.Kanban.Details.Milestone.Create.Title"]

export default ProjectKanbanMilestoneCreateContainer
