import { AppRouteProps } from "@mzara/component";
import React from "react";

const ContractDepartureDetailsParameterContainer = React.lazy(() => import("./container/ContractDepartureDetailsParameterContainer"));
export const ContractDepartureDetailsParameterRoute: Array<AppRouteProps> = [
    {
        path: "parameter",
        element: <ContractDepartureDetailsParameterContainer />,
        title: "Harea.Contract.Departure.Details.Parameter.Title",
    },
];
