import { ControlListProps, useTranslations, useUserProfilePicture } from "@mzara/component";
import moment from "moment";
import { useCallback } from "react";

export const useKanbanCardDateForm = (id: number): ControlListProps => {
    const [
        DATE_BEGIN,
        HOUR_BEGIN,
        DATE_END,
        HOUR_END,
        LINKED_TICKETS,
        PROGRESSION,
        SAVE,
        CANCEL,
        SHOW_MORE
    ] = useTranslations(i18n);

    const handleBeforeSave = useCallback((value: Record<string, any>) => {
        const hourBegin = value?.hourBegin?.split(":")
        const hourEnd = value?.hourEnd?.split(":")
        const dateBegin = value.dateBegin ? moment(value.dateBegin).set({ hour: hourBegin?.[0], minute: hourBegin?.[1] }).format() : null
        const dateEnd = moment(value.dateEnd ? value.dateEnd : value.dateBegin).set({ hour: hourEnd?.[0], minute: hourEnd?.[1] }).format() 

        return {
            ...value,
            dateBegin,
            dateEnd,
        }
    }, [])

    return {
        data: {
            forms: [
                {
                    type: "date-picker",
                    name: "dateBegin",
                    required: true,
                    label: DATE_BEGIN,
                },
                {
                    type: "hour",
                    name: "hourBegin",
                    required: true,
                    interval: 60,
                    label: HOUR_BEGIN,
                },
                {
                    type: "date-picker",
                    name: "dateEnd",
                    required: true,
                    label: DATE_END,
                },
                {
                    type: "hour",
                    name: "hourEnd",
                    required: true,
                    interval: 60,
                    label: HOUR_END,
                },
                {
                    type: "numeric",
                    name: "progression",
                    min: 0,
                    max: 100,
                    label: PROGRESSION,
                },
                {
                    type: "select",
                    name: "link",
                    label: LINKED_TICKETS,
                    valueObject: true,
                    valueProperty: 'id',
                    GQLProps: {
                        gql: `
                            query($id: Float, $key: String) {
                                appointments (filter: { data: { title_ilike: $key, board: { id: $id }, dateArchive_isNull: true } }) {
                                    data { id title num }
                                }
                            }
                        `,
                        mapFn: (response: Record<string, any>) => response?.appointments.data?.map((item) => ({
                            label: `#${item.num} • ${item.title}`,
                            value: item.id
                        })),
                        variables: {
                            id: id,
                        },
                    },
                },
                {
                    type: "hidden",
                    name: "id",
                },
            ],
            value: {},
            next: {
                label: SAVE,
                className: "btn-primary",
            },
            back: {
                label: CANCEL,
            },
            graphQL: {
                entity: "appointment",
            },
            fields: {}
        },
        onBeforeSaving: handleBeforeSave,
        id: "vacation-add-kanban-card-form",
    };
}
const i18n = [
    'Harea.Vacation.Kanban.DateBegin.Label',
    'Harea.Vacation.Kanban.HourBegin.Label',
    'Harea.Vacation.Kanban.DateEnd.Label',
    'Harea.Vacation.Kanban.HourEnd.Label',
    'Harea.Vacation.Kanban.LinkedTickes.Label',
    'Harea.Vacation.Kanban.Progression.Label',
    "std_save",
    "std_cancel",
    "std_show_more"
];

