import { AppRouteProps } from "@mzara/component";
import React from "react";

const AdvertParametersContainer = React.lazy(
    () => import("./containers/AdvertParametersContainer")
);
export const AdvertParametersRoutes: Array<AppRouteProps> = [
    {
        path: "parameters",
        title: "std_params",
        element: <AdvertParametersContainer />,
    },
];
