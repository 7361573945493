import { useUrlParamsValueToString } from "@mzara/component"
import { TAppointment } from "@mzara/graphql"
import { useMemo } from "react"

export const useAppointmentDetailsEditLink = (type: TAppointment['type']) => {

    const urlParamsToString = useUrlParamsValueToString()

    return useMemo(() => {
        if (type === 'CONGE') {
            return `edit/vacation?${urlParamsToString}`
        }
        if (type === 'ABSENCE') {
            return `edit/missing?${urlParamsToString}`
        }
        if (type === 'PLANNING') {
            return `edit/planning?${urlParamsToString}`
        }
        if (type === 'KANBAN') {
            return `edit?${urlParamsToString}`
        }
        return `edit/event?${urlParamsToString}`
    }, [urlParamsToString])
}
