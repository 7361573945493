import { GraphQLQueryClass } from "@mzara/component";
import { TRecruitingCuriculumVitaesResponse } from "@mzara/graphql";

export class RecruitingCVList
    implements GraphQLQueryClass<TRecruitingCuriculumVitaesResponse, TResponse>
{
    queryKey: string = "Evaluation.Recruiting.CV.List.Query";

    gql: string = `
        query($data: JSONObject) {
            recruitingCuriculumVitaes(filter: { data: $data }) {
                total
                data {
                    id
                    name
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TRecruitingCuriculumVitaesResponse {
        return data.recruitingCuriculumVitaes;
    }
}

type TResponse = {
    recruitingCuriculumVitaes: TRecruitingCuriculumVitaesResponse;
};
