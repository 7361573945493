import { TAppointment } from "@mzara/graphql"
import { useCallback } from "react"
import { useKanbanColumns } from "./useKanbanColumns"
import { useKanbanOpenedCards } from "./useKanbanOpenedCards"
import { useKanbanClosedCards } from "./useKanbanClosedCards"

export const useKanbanCardsGetByIndex = (id: string) => {

    const columns = useKanbanColumns(id)
    const openedCards = useKanbanOpenedCards(id)
    const closedCards = useKanbanClosedCards(id)

    return useCallback((index: number, columnId?: string) => {
        if (columnId === 'OPENED') {
            return openedCards[index]
        }

        if (columnId === 'CLOSED') {
            return closedCards[index]
        }

        return columns.find((item) => item.label.id === parseInt(columnId))?.cards[index]
    }, [columns, openedCards, closedCards])
}
