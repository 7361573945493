import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ContractDepartureDetailsAboutRoute } from "./views/about/ContractDepartureDetailsAboutRoute";
import { ContractDepartureDetailsParameterRoute } from "./views/parameter/ContractDepartureDetailsParameterRoute";

const ContractDepartureDetailsContainer = React.lazy(() => import("./container/ContractDepartureDetailsContainer"));

export const ContractDepartureDetailsRoute: Array<AppRouteProps> = [
    {
        path: "contract/departure/:id",
        element: <ContractDepartureDetailsContainer />,
        title: "{{ContractDepartureUserNameFull}}",
        children: [...ContractDepartureDetailsAboutRoute, ...ContractDepartureDetailsParameterRoute],
        roles: ["CONTRACT"]

    },
];
