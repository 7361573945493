import {
    MenuItemProps,
} from "@mzara/component";
import { useLeftSideBarMenuGeneral } from "./useLeftSideBarMenuGeneral";
import { useLeftSideBarMenuRecruiting } from "./useLeftSideBarMenuRecruiting";
import { useLeftSideBarMenuAdminManagement } from "./useLeftSideBarMenuAdminManagement";
import { useLeftSideBarMenuPerformanceManagement } from "./useLeftSideBarMenuPerformanceManagement";
import { useLeftSideBarMenuParameters } from "./useLeftSideBarMenuParameters";

export const useLeftSideBarMenu = (): Array<MenuItemProps> => {

    const generalMenu = useLeftSideBarMenuGeneral()
    const recruitingMenu = useLeftSideBarMenuRecruiting()
    const adminManagementMenu = useLeftSideBarMenuAdminManagement()
    const performanceManagementMenu = useLeftSideBarMenuPerformanceManagement()
    const parametersMenu = useLeftSideBarMenuParameters()

    return [
        generalMenu,
        recruitingMenu,
        adminManagementMenu,
        performanceManagementMenu,
        parametersMenu
    ];
};

