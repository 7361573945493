import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectDayOffCreateRoutes } from "./views/create/ProjectDayOffCreateRoutes";
import { ProjectCalendarCardDetailsRoutes } from "views/calendar/views/details/ProjectCalendarCardDetailsRoutes";
const ProjectDayOffViewsDemandContainer = React.lazy(
    () => import("./container/ProjectDayOffViewsDemandContainer")
);

export const ProjectDayOffViewsDemandRoutes: Array<AppRouteProps> = [
    {
        path: "demand",
        title: "Harea.Project.DayOff.Management.Vacation.Demands.Label",
        element: <ProjectDayOffViewsDemandContainer />,
        children: [
            ...ProjectCalendarCardDetailsRoutes,
            ...ProjectDayOffCreateRoutes
        ]
    },
];
