import { AppRouteProps } from "@mzara/component";
import React from "react";
import ProjectDayOffCreateContainer from "./container/ProjectEventEditContainer";
import { ProjectAppointmentCreateEventRoutes } from "views/create/views/event/ProjectAppointmentCreateEventRoutes";
import { ProjectAppointmentCreateVacationRoutes } from "views/create/views/vacation/ProjectAppointmentCreateVacationRoutes";
import { ProjectAppointmentCreatePlanningRoutes } from "views/create/views/planning/ProjectAppointmentCreatePlanningContainer";

export const ProjectEventEditRoutes: Array<AppRouteProps> = [
    {
        path: "edit",
        element: <ProjectDayOffCreateContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectAppointmentCreateEventRoutes,
            ...ProjectAppointmentCreateVacationRoutes,
            ...ProjectAppointmentCreatePlanningRoutes
        ]
    },
];
