import { ControlListProps, useTranslations } from "@mzara/component";

export const useWorkHourControl = (): ControlListProps => {
    const [
        WORK_START_TIME,
        WORK_END_TIME,
        BREAK_START_TIME,
        BREAK_END_TIME,
        SAVE
    ] = useTranslations(i18n);

    return {
        data: {
            forms: [
                {
                    type: "hidden",
                    name: "id"
                },
                {
                    type: "hour",
                    name: "workHourBegin",
                    label: WORK_START_TIME,
                    interval: 60
                },
                {
                    type: "hour",
                    name: "workHourEnd",
                    label: WORK_END_TIME,
                    interval: 60
                },
                {
                    type: "hour",
                    name: "workRestHourBegin",
                    label: BREAK_START_TIME,
                    interval: 60
                },
                {
                    type: "hour",
                    name: "workRestHourEnd",
                    label: BREAK_END_TIME,
                    interval: 60
                },
            ],
            next: {
                label: SAVE
            },
            graphQL: {
                entity: "userOrganisation"
            }
        },
    };
};

const i18n = [
    "Harea.Planning.WorkHour.Form.StartTime.Label",
    "Harea.Planning.WorkHour.Form.EndTime.Label",
    "Harea.Planning.WorkHour.Form.BreakStartTime.Label",
    "Harea.Planning.WorkHour.Form.BreakEndTime.Label",
    "std_save"
];
