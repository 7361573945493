import { useDebounce } from "@mzara/component"
import { FlowNodeProperty, useFlowGetNodeSelected, useFlowNodeSelected, useFlowNodes, useFlowSetNodeSelected } from "@mzara/flow"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

export const MindMapNodeContainer = () => {

    const { nodeId } = useParams()
    const [isInitialized, setIsInitialized] = useState(false)
    const nodes = useFlowNodes()
    const setSelectedNode = useFlowSetNodeSelected()
    const getSelectedNode = useFlowGetNodeSelected()
    const navigate = useNavigate()
    const location = useLocation()
    const debouce = useDebounce()

    useEffect(() => {
        if (nodes.length === 0) {
            return;
        }

        const selectedNode = getSelectedNode()
        if (!isInitialized && selectedNode.length === 0 && nodeId !== '-1') {
            setSelectedNode(nodeId);
            setIsInitialized(true)
            return;
        }

        if (selectedNode[0] !== nodeId) {
            debouce(() => {
                navigate(location.pathname.split('/').slice(0, -1).join('/') + `/${selectedNode[0] ?? -1}`)
            }, 250)
        }
    }, [nodes])

    return (
        <FlowNodeProperty />
    )
}
