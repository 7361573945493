import { ControlList, useFormData, useRuntimeEnv, useUrlParamsValue } from "@mzara/component";
import { useNavigate, useParams } from "react-router-dom";
import moment, { Moment } from "moment";
import { useAppointmentEventControlForm } from "../hooks/useAppointmentEventControlForm";
import { useCallback, useMemo, useState } from "react";
import { EventEditConfirmDialog } from "components/EventEditConfirmDialog";
import { useAppointmentOwnerQuery } from "views/create/views/event/hooks/useAppointmentOwnerQuery";
import { useProjectAppointmentCreateDefaultValue } from "views/create/hooks/useProjectAppointmentCreateDefaultValue";
import { useProjectAppointmentNavigateBack } from "views/create/hooks/useProjectAppointmentNavigateBack";
import { useAppointmentsInvalidateQuery } from "hooks/useAppointmentsInvalidateQuery";
import { useProjectAppointmentEventBeforeSave } from "views/create/hooks/useProjectAppointmentEventBeforeSave";

const ProjectAppointmentCreateEventContainer = () => {

    const { id } = useParams()
    const { data } = useAppointmentOwnerQuery(parseInt(id), true);
    const control = useAppointmentEventControlForm(id ? parseInt(id) : undefined);
    const [openConfirmEdit, setOpenConfirmEdit] = useState<boolean>(false);
    const { formData } = useFormData({ id: "vacation-add-appointment-form", formData: {} })
    const invalidateAppointmentsQuery = useAppointmentsInvalidateQuery()
    const navigateBack = useProjectAppointmentNavigateBack()

    const handleSubmited = () => {
        invalidateAppointmentsQuery()
        navigateBack();
    };

    const _handleBeforeSave = useProjectAppointmentEventBeforeSave()

    const handleBeforeSave = useCallback((value: Record<string, any>) => {
        if (id && data.repetition) {
            setOpenConfirmEdit(true)
            return false;
        }
        return _handleBeforeSave(value)
    }, [id, data, _handleBeforeSave])

    const defaultValues = useProjectAppointmentCreateDefaultValue()

    return (
        <>
            <ControlList
                {...control}
                id="vacation-add-appointment-form"
                defaultValue={defaultValues}
                onSubmited={handleSubmited}
                onCancel={() => handleSubmited()}
                onBeforeSaving={handleBeforeSave}
            />

            <EventEditConfirmDialog
                open={openConfirmEdit}
                data={_handleBeforeSave(formData) as any}
                onClose={() => setOpenConfirmEdit(false)}
                onEdited={() => {
                    setOpenConfirmEdit(false)
                }}
            />
        </>
    );
};

export default ProjectAppointmentCreateEventContainer;

