import { useGraphqlQuery } from "@mzara/component";
import { TFormationChapter } from "@mzara/graphql";
import { FormationChapterDetailsQuery } from "../queries/FormationChapterDetailsQuery";

export const useFormationChapterDetailsQuery = (
    formationId: number,
    suspense?: boolean
) => {
    return useGraphqlQuery<FormationChapterDetailsQuery, TFormationChapter>(
        new FormationChapterDetailsQuery({
            data: { formation: { id: formationId } },
        }),
        {
            suspense: suspense || false,
        }
    );
};
