import { useEffect, useMemo, useRef } from "react"
import { useProjectGantt } from "../hooks/useProjectGantt"
import { TAppointment } from "@mzara/graphql"
import { useAppointmentToGanttTasks } from "../hooks/useAppointmentToGanttTasks"
import '../css/gantt.css'
import moment from "moment"
import { Droppable, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd"
import { Alert, useDebounce, useTranslationLang, useTranslations } from "@mzara/component"
import Gantt from "frappe-gantt"
import { KanbanCard } from "../../../components/KanbanCard"
import ReactDOMServer from 'react-dom/server';
import { ProjectGanttViews } from "./ProjectGanttViews"

export const ProjectGantt = (props: Props) => {

    const [
        MESSAGE
    ] = useTranslations(i18n)

    const { lang } = useTranslationLang();
    const ref = useRef<HTMLDivElement>()
    const ganttRef = useRef<Gantt>()
    const defaultTask = useMemo<Gantt.Task>(() => ({
        start: moment().format(),
        end: moment().format(),
        dependencies: '',
        id: '0',
        name: '',
        progress: 0,
    }), [])
    const getTasks = useAppointmentToGanttTasks()
    const initGantt = useProjectGantt({
        tasks: [defaultTask],
        options: {
            on_click: function (task) {
                props.onClick?.(parseInt(task.id))
            },
            custom_popup_html: function (task) {
                // the task object will contain the updated
                // dates and progress value
                const card = (
                    <div className="w-80">
                        <KanbanCard
                            {...(task as any).appointment}
                            disableAvatarTooltip
                            index={parseInt(task.id)}
                        />
                    </div>
                )

                return ReactDOMServer.renderToString(card);
            },
            on_date_change: function (task, start, end) {
                props.onChange?.({
                    id: parseInt(task.id),
                    dateBegin: moment(start).format(),
                    hourBegin: moment(start).format('HH:mm'),
                    dateEnd: moment(end).format(),
                    hourEnd: moment(end).format('HH:mm'),
                })
            },
            on_progress_change: function (task, progress) {
                props.onChange?.({
                    id: parseInt(task.id),
                    progression: progress
                })
            },
            view_mode: props.view as Gantt.viewMode,
            language: lang,

        }
    })
    const debounce = useDebounce()

    useEffect(() => {
        debounce(() => {
            const tasks = getTasks(props?.appointments ?? [])
            ganttRef.current.refresh(tasks.length > 0 ? tasks : [defaultTask])

        }, 250)
    }, [props.appointments])

    useEffect(() => {
        if (props.view) {
            ganttRef.current?.change_view_mode(props.view as Gantt.viewMode)
        }
    }, [props.view])

    useEffect(() => {
        ganttRef.current = initGantt(ref.current)
    }, [])

    return (
        <Droppable
            droppableId="GANTT"
            type={'CARDS'}>
            {(
                dropProvided: DroppableProvided,
                dropSnapshot: DroppableStateSnapshot,
            ) => (
                <div
                    className="relative h-full w-full flex flex-col gap-4"
                    ref={dropProvided.innerRef}>
                    {
                        dropSnapshot.isDraggingOver &&
                        <div className="absolute top-[-10px] left-0 right-0 flex justify-center z-10 animate__animated animate__fadeInUp animate__faster">
                            <Alert type="primary" text={MESSAGE} />
                        </div>
                    }
                    <ProjectGanttViews value={props.view} onChange={props.onViewChange} />
                    <div className="gantt-wrapper" ref={ref}></div>
                    {dropProvided.placeholder}
                </div>
            )}
        </Droppable>
    )
}


const i18n = [
    'Harea.Project.Kanban.Details.Gantt.Drop.Ticket.Message'
]

type Props = {
    appointments?: Array<TAppointment>
    view?: string
    onClick?: (id: number) => void
    onChange?: (value: Partial<TAppointment>) => void
    onViewChange?: (value: string) => void
}
