import { AppointmentsQuery } from "queries/AppointmentsQuery";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

export const useAppointmentsInvalidateQuery = () => {

    const queryClient = useQueryClient()

    return useCallback(() => {
        const obj = new AppointmentsQuery()
        queryClient.invalidateQueries(obj.queryKey)
    }, [queryClient])
};
