import {
    Button,
    CurrentBreadCrumb,
    GenericSuspense,
    IconButton,
    useRuntimeEnvRolesIncludes,
    useTranslation,
    useTranslationRoute,
    useUrlParamsEncode,
    useUrlParamsValue,
} from "@mzara/component";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import RecruitingEvaluationList from "../components/RecruitingEvaluationList";
import RecruitingEvaluationListFilter from "../components/RecruitingEvaluationListFilter";
import ListFilterDropdownButtonGroups from "../components/ListFilterDropdownButtonGroups";

const RecruitingContainer = () => {
    const [openFilter, setOpenFilter] = useState(false);

    const t = useTranslation();
    const tr = useTranslationRoute();
    const urlParams = useUrlParamsValue();
    const encodeUrl = useUrlParamsEncode();
    const roleIncludes = useRuntimeEnvRolesIncludes()

    return (
        <>
            <section className="main-content">
                <div className="flex items-center justify-between">
                    <h4 className="font-semibold">
                        {t("Harea.Evaluation.Recruiting.List.Page.Title")}
                    </h4>

                    <div className="flex gap-2">
                        {
                            roleIncludes('EVALUATION_TEMPLATE') &&
                            <Link
                                data-testid="evaluation-recruiting-add-button"
                                to={tr(`admin/evaluation/template`)}
                            >
                                <Button
                                    className="btn-default"
                                    label={t("std_model")}
                                    startIcon="fa-solid fa-file"
                                />
                            </Link>
                        }

                        <Link
                            data-testid="evaluation-recruiting-add-button"
                            to={tr(`admin/evaluation/create?${encodeUrl(urlParams)}`)}
                        >
                            <Button
                                className="btn-primary"
                                label={t("std_add")}
                                startIcon="fa-solid fa-plus"
                            />
                        </Link>
                    </div>
                </div>
                <CurrentBreadCrumb />

                <div className="flex flex-col gap-2">
                    <div className="flex w-full justify-end">
                        <div className="flex gap-2">
                            <ListFilterDropdownButtonGroups
                                listId={RECRUITING_EVALUATION_LIST_ID}
                            />

                            <IconButton
                                icon="fa fa-filter"
                                onClick={() => setOpenFilter((v) => !v)}
                            />
                        </div>
                    </div>

                    <RecruitingEvaluationList
                        id={RECRUITING_EVALUATION_LIST_ID}
                    />
                </div>
            </section>

            <RecruitingEvaluationListFilter
                listId={RECRUITING_EVALUATION_LIST_ID}
                open={openFilter}
            />

            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </>
    );
};

export default RecruitingContainer;

export const RECRUITING_EVALUATION_LIST_ID = "recruiting-evaluation-list-id";
