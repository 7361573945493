import { AppRouteProps } from "@mzara/component";
import React from "react";
import { SocialStatementDetailRoute } from "./views/detail/SocialStatementDetailRoute";

const SocialStatementContainer = React.lazy(() => import("./container/SocialStatementContainer"));
export const SocialStatementRoute = [
    {
        path: "social-statement",
        title: "Harea.Payslip.Menu.Label.Social.Statement",
        element: <SocialStatementContainer />,
        roles: ["PAYING_SLIP_SOCIALS"],
        children: [
            ...SocialStatementDetailRoute
        ]
    },
] as Array<AppRouteProps>;
