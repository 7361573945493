import { AppRouteProps } from "@mzara/component";
import { MindMapViewerContainer } from "./container/MindMapViewerContainer";
import { MindMapDetailsDangerRoutes } from "../danger/MindMapDetailsDangerRoutes";
import { MindMapDetailsEditRoutes } from "../edit/MindMapDetailsEditRoutes";
import { MindMapDetailsNodeRoutes } from "../node/MindMapDetailsInvitationRoutes";
import { MindMapDetailsInvitationRoutes } from "../invitation/MindMapDetailsInvitationRoutes";

export const MindMapDetailsViewerRoutes: Array<AppRouteProps> = [
    {
        path: 'viewer',
        element: <MindMapViewerContainer />,
        children: [
            ...MindMapDetailsDangerRoutes,
            ...MindMapDetailsEditRoutes,
            ...MindMapDetailsInvitationRoutes,
            ...MindMapDetailsNodeRoutes,
        ]
    }
]