import { TAppointment } from "@mzara/graphql"
import Gantt from "frappe-gantt"
import moment from "moment"
import { useCallback, useMemo } from "react"

export const useAppointmentToGanttTasks = () => {

    return useCallback((appointments?: Array<Partial<TAppointment>>) => {
        return appointments
            ?.filter((item) => item.dateBegin && item.dateEnd)
            ?.map<Gantt.Task>((item) => {
                return {
                    start: moment(item.dateBegin).format('YYYY-MM-DD HH:mm'),
                    end: moment(item.dateEnd).format('YYYY-MM-DD HH:mm'),
                    dependencies: item.link?.id.toString(),
                    id: item.id.toString(),
                    name: `#${item.num} • ${item.title}`,
                    progress: item.progression ?? 0,
                    appointment: item
                }
            })
    }, [])
}
