import {
    IconButton,
    Metadata,
    useFileUrl,
    useTranslation,
    useTranslationRoute,
} from "@mzara/component";
import { TFile } from "@mzara/graphql";
import moment from "moment";
import { Link } from "react-router-dom";

const FormationItem = ({
    id,
    title,
    isPublic,
    miniature,
    createdAt,
}: FormationItemProps) => {
    const t = useTranslation();
    const tr = useTranslationRoute();
    const getMiniatureById = useFileUrl();

    return (
        <article className="w-full flex items-center justify-between px-2 pb-4 border-default border-b">
            <section className="flex items-start gap-5">
                <div className="object-cover object-center rounded-full w-12 h-12 overflow-hidden">
                    <Link to={tr(`admin/formation/${id}/about`)}>
                        <img
                            src={getMiniatureById(miniature?.id)}
                            alt={miniature?.name}
                        />
                    </Link>
                </div>

                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                        <h3 className="font-bold text-base">
                            <Link to={tr(`admin/formation/${id}/about`)}>
                                {title}
                            </Link>
                        </h3>

                        {isPublic && (
                            <span className="badge bg-primary text-black">
                                {t("std_public")}
                            </span>
                        )}
                    </div>

                    <Metadata
                        icon="fa-clock"
                        value={
                            <span className="text-xs">
                                {moment(+createdAt).format("DD/MM/YYYY")}
                            </span>
                        }
                    />
                </div>
            </section>

            <Link
                data-testid={`formation-row-${id}-link`}
                to={tr(`admin/formation/${id}/about`)}
            >
                <IconButton icon="fa-solid fa-angle-right" />
            </Link>
        </article>
    );
};

export default FormationItem;

type FormationItemProps = {
    id?: number;
    title?: string;
    isPublic?: boolean;
    state?: string;
    createdAt?: string;
    miniature?: TFile;
};
