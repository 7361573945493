import { useParams } from "react-router-dom";
import Welcome from "../components/Welcome";
import { useCallback } from "react";
import EvaluationSteps from "../components/EvaluationSteps";
import Congratulation from "../components/Congratulation";
import { useGetEvaluationQuery } from "../hooks/useGetEvaluationQuery";
import { GenericSuspense, MainLogo } from "@mzara/component";
import { useEndEvaluationMutation } from "../hooks/useEndEvaluationMutation";

const EvaluationContainer = () => {
    const { ke } = useParams();

    const { data, invalidateQuery } = useGetEvaluationQuery(ke);
    const mutation = useEndEvaluationMutation();

    const finishEvaluation = useCallback(() => {
        mutation.mutate({ ke }, { onSuccess: () => invalidateQuery()});
    }, []);

    const handleStartConfirmed = useCallback(() => {
        invalidateQuery();
    }, [])

    return (
        <div className="h-full max-w-3xl m-auto pt-8 flex justify-center">
            <GenericSuspense>
                {data?.state === "NEW" && (
                    <Welcome
                        ke={ke}
                        totalStep={data?.totalStep}
                        totalTime={data?.timeTotal}
                        onStartConfirmed={handleStartConfirmed}
                    />
                )}
                {data?.state === "IN_PROGRESS" && !mutation.isLoading && (
                    <EvaluationSteps
                        evaluationKey={ke}
                        stepMenu={data?.menu}
                        index={data?.stepIndex ?? 0}
                        onStepFinish={() => finishEvaluation()}
                    />
                )}
                {
                    data?.state === "IN_PROGRESS" && mutation.isLoading &&
                    <div className="w-full h-80 relative">
                        <MainLogo className="absolute " />
                    </div>
                }
                {data?.state === "FINISHED" && <Congratulation />}
            </GenericSuspense>
        </div>
    );
};

export default EvaluationContainer;
