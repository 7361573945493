
import { GenericSuspense, LeftSidebar, useRuntimeEnv, useTranslation } from "@mzara/component";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {

    const { runtimeEnv } = useRuntimeEnv()
    const t = useTranslation()

    return (
        <div className="auth-layout">
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </div>
    )
}

export default AuthLayout;
