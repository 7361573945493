import { AppRouteProps } from "@mzara/component";
import React from "react";

export const PayslipDetailsContainer = React.lazy(() => import("./container/PayslipDetailsContainer"));
export const PayslipDetailsRoute = [
    {
        path: "payslips/:id/details",
        title: '{{UserFicheNameFull}}',
        element: <PayslipDetailsContainer />,
        roles: ["PAYING_SLIP"]
    },
] as Array<AppRouteProps>;
