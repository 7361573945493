import { GraphQLQueryClass } from "@mzara/component";
import { TAppointmentBoard, TAppointmentBoardsResponse } from "@mzara/graphql";

export class AppointmentBoardInvitationsQuery implements GraphQLQueryClass<TAppointmentBoard, TResponse> {
    queryKey: string = "Harea.AppointmentBoardInvitationsQuery.Query";

    gql: string = `
        query AppointmentBoardInvitationsQuery($id: Float) {
            appointmentBoard(filter: { id: $id }) {
                isEditable
                createdAt
                userCreator {
                    id fullName
                }
                invitations {
                    id ke email createdAt isRevocked isValidated actions
                    user { id fullName}
                }
            }
        }
    `;

    variables?: any;

    constructor(variables?: any) {
        this.variables = variables;
    }

    mapFn(data: TResponse): TAppointmentBoard {
        return data.appointmentBoard;
    }
}

type TResponse = {
    appointmentBoard: TAppointmentBoard;
};
