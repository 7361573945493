import {
    MenuItemProps,
    useTranslationRoute,
    useTranslations,
} from "@mzara/component";
import { useMemo } from "react";

export const useDataMenu = () => {
    const translateRoute = useTranslationRoute();
    const [DETAILS, EVALUATION] = useTranslations(i18n);

    return useMemo(
        (): Array<MenuItemProps> => [
            {
                ke: "export",
                label: EVALUATION,
                link: translateRoute(`admin/data/export`),
            },
            {
                ke: "import",
                label: DETAILS,
                link: translateRoute(`admin/data/import`),
            },
        ],
        []
    );
};

const i18n = [
    'Harea.Data.Import.Title',
    'Harea.Data.Export.Title',
];
