import { useGraphqlQuery } from "@mzara/component";
import { TRecruitingCuriculumVitaesResponse } from "@mzara/graphql";
import { RecruitingCVList } from "../queries/RecruitingCVList";

export const useRecruitingCVListQuery = (suspense?: boolean) => {
    return useGraphqlQuery<
        RecruitingCVList,
        TRecruitingCuriculumVitaesResponse
    >(new RecruitingCVList(), {
        suspense: suspense || false,
    });
};
