import { useMemo } from "react";
import { useFormationEditMetadataForm } from "./useFormationEditMetadataForm";
import { useFormationDangerZoneForm } from "./useFormationDangerZoneForm";

export const useFormationDetailsSteps = (formationId: number) => {
    const metadataForm = useFormationEditMetadataForm();
    const dangerZoneForm = useFormationDangerZoneForm(formationId);

    return useMemo(() => [metadataForm, dangerZoneForm], [formationId]);
};
